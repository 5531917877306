import React from 'react';
import AnimateDelay from '../page-enter-leave/onmount-animate-delay.component';
import './intropage.styles.scss';


const IntroPage = () => {

	return (
    <div className="IntroPage__Wrapper">

    	<div className="ahwd__wrapper">
	    	<div className="ah__wrapper">
		    	<span className="letter">a</span>
					<span className="letter">l</span>
					<span className="letter">e</span>
					<span className="letter">x</span>
		    	<span className="letter">h</span>
					<span className="letter">o</span>
					<span className="letter">n</span>
					<span className="letter">g</span>
	    	</div>
	    	<div className="wd__wrapper">
		    	<span className="letter">w</span>
		    	<span className="letter">e</span>
		    	<span className="letter">b</span>
		    	<span className="letter"> </span>
		    	<span className="letter">d</span>
		    	<span className="letter">e</span>
		    	<span className="letter">v</span>
		    	<span className="letter">e</span>
		    	<span className="letter">l</span>
		    	<span className="letter">o</span>
		    	<span className="letter">p</span>
		    	<span className="letter">e</span>
		    	<span className="letter">r</span>
	    	</div>    		
    	</div>

    </div>
	)
};

export default IntroPage;











