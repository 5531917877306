import { all, call } from 'redux-saga/effects';
// import { fetchCollectionsStart } from './shop/shop.sagas';

import { shopSagas } from './shop/shop.sagas';
import { userSagas } from './user/user.sagas';
import { cartSagas } from './cart/cart.sagas';
import { stillsSagas } from './stills/stills.sagas';
import { contactSagas } from './contact/contact.sagas';

export default function* rootSaga() {
  yield all([
  	// call(fetchCollectionsStart),
  	call(shopSagas), 
  	call(userSagas), 
  	call(cartSagas),
  	call(stillsSagas),
  	call(contactSagas)
  ]);
}
