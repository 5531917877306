const StillsActionTypes = {
  UPDATE_STILLS: 'UPDATE_STILLS',

  FETCH_STILLS_START: 'FETCH_STILLS_START',
  FETCH_STILLS_SUCCESS: 'FETCH_STILLS_SUCCESS',
  FETCH_STILLS_FAILURE: 'FETCH_STILLS_FAILURE',

  FETCH_COTTONWOOD_START: 'FETCH_COTTONWOOD_START',
  FETCH_COTTONWOOD_SUCCESS: 'FETCH_COTTONWOOD_SUCCESS',
  FETCH_COTTONWOOD_FAILURE: 'FETCH_COTTONWOOD_FAILURE',

  SET_VISIBILITY_FILTER: 'SET_VISIBILITY_FILTER',
  SET_VISIBILITY_FILTER_SUCCESS: 'SET_VISIBILITY_FILTER_SUCCESS',
  SET_VISIBILITY_FILTER_FAILURE: 'SET_VISIBILITY_FILTER_FAILURE',
};

export default StillsActionTypes;
