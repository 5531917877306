import React, { Component } from 'react';
import AnimateRight from '../../page-enter-leave/onmount-animate-right.component';
import PageDetail from './page-detail.component';
import { PageDetailWrapper } from './page-details.styles';
import './page-details.styles.scss';


class StillsPageDetails extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
    }
    this.handleClick = this.handleClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
  };

  closeModal = () => {
    this.setState({ 
      active: !this.state.active 
    })
  };

  handleClick(){
    this.setState({ 
      active: !this.state.active 
    })
  }

  render() {
    return (
      <div className="stillsPageDetails__wrapper">
        <AnimateRight
          durationOnMount={ 1000 } 
          delayOnMount={ 3000 }
        >
          <h2 onClick={ this.handleClick }>PAGE DETAILS</h2>
        </AnimateRight>


        <PageDetailWrapper
          active={ this.state.active }
        >

          <div className="pageDetails">

            {/*  CLOSE MODAL  */}
            <div 
              className="closeModal" 
              onClick={ this.closeModal }
            > CLOSE </div>
            
            <h4>Page in progress :</h4>

            <h4>Page Built With :</h4>
            <ul>
              <li>Javascript, Redux, Redux-Saga</li>
              <li>Filter done through Redux / Redux Saga middleware</li>
              <li>Images path and related data from Firebase Database</li>
            </ul>

            <h4>Plans For This Page :</h4>
            <ul>
              <li>Add & Load images from Firebase Storage,</li>
              <li>Remove Fullscreen Img Modal and,</li>
              <li>
                Add Interactive Storytelling for my own images with location maps.<br/>
                <a href="https://tympanus.net/Development/StorytellingMap/" target="_blank">
                  Example : Interactive Storytelling
                </a>
              </li>
            </ul>
          </div>


        </PageDetailWrapper>

        {
          // <PageDetailModal
          //   active={ this.state.active }
          //   handleClick={ this.handleClick }
          // ></PageDetailModal>
        }

      </div>
    );
  }

}

export default StillsPageDetails;
