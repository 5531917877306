import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';  //  *_storage

import userReducer from './user/user.reducer';
import cartReducer from './cart/cart.reducer';
import directoryReducer from './directory/directory.reducer';
import shopReducer from './shop/shop.reducer';
import stillsReducer from './stills/stills.reducer';
import contactReducer from './contact/contact.reducer';


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart']
};

const rootReducer = combineReducers({
  user: userReducer,
  cart: cartReducer,
  directory: directoryReducer,
  shop: shopReducer,
  stills: stillsReducer,
  contact: contactReducer,
});

//  export out the modified version of the rootReducer w/ the persistence capabilities
export default persistReducer(persistConfig, rootReducer);




