import { createSelector } from 'reselect';

/*	I N P U T  -  S E L E C T O R  -->		*/
	// - rootReducer is all whote reducers rootReducer, everything, we're only getting a piece of it.
	var selectContact = function selectContact( rootReducer ) {
		console.log('rootReducer ', rootReducer)
	  return rootReducer.contact;
	};

/*		JS
	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.selectContact = void 0;
	var selectContact = createSelector([selectContact], function (get_stillsReducer) {
	  return get_stillsReducer.stillsIS;
	});
	exports.selectContact = selectContact; 					

	// 	O U T P U T  -  S E L E C T O R
	//	1st arg : a collection, array of input-selectors
	// 	2nd param : is a function that will return the value we want out of the selector 		*/
// 	Select 'stills > stills_documents' :
export const select_contactDocument = createSelector(
	[ selectContact ],

	(function (selectContact) {
	  return selectContact;
	})
	// selectContact => selectContact.stills_documents
);




