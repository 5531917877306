import React from 'react';
import Modal from 'react-modal';



const ContactFormModal = ( props ) => (
  <Modal 
    className="ContactFormModal__Wrapper"
    isOpen={ !!props.modalOpen }
    ariaHideApp={ false }
    contentLabel="Selected Option"
  >
    Message Sent

    <button 
      className="closeModal"
      onClick={ props.handleClear }
    > close </button>
  </Modal>
);

export default ContactFormModal;
