import React, { useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Masonry from 'react-masonry-css';
import { visibleStillsDocuments } from '../../../redux/stills/stills.selectors';
import { fetchStillsStart } from '../../../redux/stills/stills.actions';
// import Page from './page.component';
import './hover-scroll.styles.scss';
// import redux_stillsData from '../../redux/stills/stills.data';


const MasonryGridComponent = ({ 
  fetchStillsStart, 
  visibleStillsDocuments, 
  breakpointCols,
  callBack_fromChild
}) => {
  useEffect(() => {
    // console.log('this', this)
    
    // ADD_DATA_TO_DB  -  comment OUT to load data to firebase :
    fetchStillsStart();

      // hit an API
      // const stillsRef = firestore.collection( 'stillsCollection' );
      // console.log('stillsRef', stillsRef)

      // stillsRef.get().then(snapshot => {
      //   const stillsMap = convertStillsSnapshotToMap(snapshot);
      //   console.log('stillsMap', stillsMap)

      //   // return stillsMap


      //   const stillsDocuments = stillsMap.map( still => {
      //     // console.log('still ', Object.assign({}, still))
      //     return Object.assign({}, still)
      //   })

        //  Convert this array of arrays into object
        // console.log('stillsDocuments2', Object.assign({}, stillsDocuments))

        // TRY MAPPING THIS
        // setItem({
        //   ...currentState,
        //   currentStillsState: stillsDocuments
        // })
  },[
    // ADD_DATA_TO_DB  -  comment OUT to load data to firebase :
    fetchStillsStart,
    // visibleStillsDocuments
  ])


  //  ---------------------------------------  L I G H T B O X
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    sourceIndex: 0,
    arrayOfImgPaths: []
  });

  function setLightboxImgIndex( sourceIndex ) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      sourceIndex: sourceIndex,
      // arrayOfImgPaths: arrayOfImgPaths
    });

    let togglerValue = !lightboxController.toggler;
    callBack_fromChild( 
      sourceIndex, 
      togglerValue,
      // arrayOfImgPaths
    );
  }


  //  ---------------------------------------  GET # OF COLUMNS
  var columnsPerStill = Math.floor( visibleStillsDocuments.length / 3 ) + 1;   // 34.333333333333336
  var numOfColumns;

  // if (window.innerWidth < 960) {
  //   numOfColumns = 3;
  // } else {
  //   numOfColumns = { 10000: columnsPerStill };
  // }

  // window.innerWidth < 1080 ? 
  //   numOfColumns = 3 : 
  //   numOfColumns = { 10000: columnsPerStill };


  if ( window.innerWidth <= 800 ) {
    numOfColumns = 2;
  } 
  else if ( window.innerWidth <= 1080 ) {
    numOfColumns = 3;
  }
  else {
    numOfColumns = { 10000: columnsPerStill };
  }


  {/*   DEFAULT STUFF
    const columnBreakPoints = {
      default: 4,
      1280: 34,
      1100: 3,
      700: 2,
      500: 1
    };    

    let array = [
      { 1280: 34, 1100: 3, 700: 2, 500: 1 },
      { id: 'hyu', email: 'a@a.com', password: 123 },
      { id: 'rft', email: 'b@b.com', password: 456 },
      { id: 'ght', email: 'c@c.com', password: 789 },
      { id: 'kui', email: 'd@d.com', password: 679 }
    ]
    let filtered = array.filter(row => row.email === 'a@a.com');
    console.log(filtered);
  */}


  return (
    <div className="masonryGridComponent">
      <Masonry
        // breakpointCols={ columnBreakPoints }
        breakpointCols={ numOfColumns }
        className="masonry_grid"
        columnClassName="masonry_grid_column"
      >
        {
          visibleStillsDocuments && visibleStillsDocuments.map(function( item, index ) {
            var imgPath = item.css;
            var partOfImgPath = imgPath.substr( 4, imgPath.indexOf(')')).slice( 0, -1 );

            return <div 
              key={ item.id }
              className="still"
              onClick={ () => setLightboxImgIndex( index ) }
              style={{ background: `url(` + partOfImgPath + `)` }}
            >
              <h5>{ item.location }</h5>
              <span></span>
            </div>
          })
        }
      </Masonry>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  visibleStillsDocuments: visibleStillsDocuments,
});

const mapDispatchToProps = dispatch => ({
  fetchStillsStart: () => dispatch( fetchStillsStart() ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)( MasonryGridComponent );




