import React, { Component, useState, useCallback } from 'react';
import FormInput from './form-input.component';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { select_contactDocument } from '../../../redux/contact/contact.selectors';
import { createProjectStart } from '../../../redux/contact/contact.actions';
import ContactFormModal from './contact-form-modal.component';

import './contact-form.styles.scss';



// 75. Sign In Component - 6:28 mark shows whole form
const ContactForm = ({ createProjectStart }) => {
  const [passState, updateState] = useState({
    name: '',
    email: '',
    message: '',
    modalOpen: undefined
  });

  const { name, email, message, modalOpen } = passState;

  const handleClear = () => {
    updateState({ 
      modalOpen: undefined 
    })
  };

  const handleSubmit = useCallback( e => {
    e.preventDefault();

    const { email, name, message } = e.target;
    createProjectStart({ 
      email: email.value, 
      name: name.value,
      message: message.value
    });
    updateState({ modalOpen: true });
    e.target.reset()
  }, []);


  return (
    <div className="contactForm__component">
      <form id="contactForm" onSubmit={ handleSubmit }>

        <div className="name">
          <label>Name :</label>
          <input id="name"
            name="name"
            type="text"
            // value={ name }
            // onChange={ handleChange }
            // label="name"
          />
        </div>

        <div className="email">
          <label>Email :</label>
          <input id="email"
            name="email" 
            type="text" 
            // value={ email }
            // onChange={ handleChange }
            // label="email"
          />
        </div>

        <div className="message">
          <label>Message :</label>
          <textarea id="message"
            name="message" 
            rows="5" 
            // value={ message }
            // onChange={ handleChange }
            // label="message"
          ></textarea>
        </div>

        <div className="submitBtn hvr-sweep-to-right">
          <button 
            type="submit"
            value="Submit Form"
          >Submit</button>
        </div>
      </form>

      <ContactFormModal 
        handleClear={ handleClear }
        modalOpen={ modalOpen }
      />
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  // select_contactDocument: select_contactDocument,
});

const mapDispatchToProps = dispatch => ({
  // signUpStart: userCredentials => dispatch(signUpStart(userCredentials))
  createProjectStart: passState => dispatch( createProjectStart( passState ) ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)( ContactForm );





