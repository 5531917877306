import React, { Component } from 'react';
import { ReactComponent as EmailMe } from '../../assets/letter.svg';
import AnimateRight from '../../pages/page-enter-leave/onmount-animate-right.component';
import './fixed-emailme-btn.styles.scss';


const FixedEmailMeBtn = () => {

  return (
    <div className="fixedEmailMeBtn__wrapper">
      <AnimateRight
        durationOnMount={ 1000 } 
        delayOnMount={ 3000 }
      >
        <a href="mailto:alexhwebdev@gmail.com">
          <EmailMe className="EmailMe" />
        </a>
      </AnimateRight>    
    </div>
  );
}

export default FixedEmailMeBtn;
