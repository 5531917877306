import React, { Component } from 'react';
import * as Animated from 'animated/lib/targets/react-dom';
import AnimateLeft from '../../page-enter-leave/onmount-animate-left.component';
import AnimateDelay from '../../page-enter-leave/onmount-animate-delay.component';


const SkillsSection = ({ skillsItemsArray }) => {
  // let skillsItemsArray = this.props.skillsItemsArray;

  const skillsList = Object.entries(skillsItemsArray).map(([key, value]) => {
    return (
      <div 
        className="skillsList"
        key={ key } 
      > 
        <div>{ `${key}` }</div>
        <div>{ `${value}` }</div>
      </div>
    )
  })



  return (
    <div className="skillsComponent__wrapper">
      <AnimateLeft 
        durationOnMount={ 600 }
        delayOnMount={ 3300 }
      >
        <h2>Technologies and Languages I've worked with :</h2>
      </AnimateLeft>
      <ul>
        <AnimateLeft 
          durationOnMount={ 600 } 
          delayOnMount={ 3500 }
        >
          <li>
            <h4>Languages</h4> 
            <p>HTML / CSS / JavaScript / TypeScript / PHP / SQL</p>
          </li>  
        </AnimateLeft>
        <AnimateLeft 
          durationOnMount={ 600 } 
          delayOnMount={ 3700 }
        >
          <li>
            <h4>Frameworks & Environments</h4>
            <p>
              React / GatsbyJS / NextJS / AngularJS / Ember / Express / SailsJS / Node
            </p>
          </li>
        </AnimateLeft>
        <AnimateLeft 
          durationOnMount={ 600 } 
          delayOnMount={ 3900 }
        >
          <li>
            <h4>Libraries & <span style={{whiteSpace: 'nowrap'}}>Build Tools</span></h4>
            <p>
              GraphQL / Redux / Redux-Saga / React Router / Webpack / jQuery / GreenSock / React-Spring / React-Slick / React Animated / Sass / Less / Styled-Components / Framer Motion / Gulp / Grunt / Git / Jenkins
            </p>
          </li>
        </AnimateLeft>
{/*
        <AnimateLeft 
          durationOnMount={ 600 } 
          delayOnMount={ 4100 }
        >
          <li>
            <h4>Build Tools</h4>
            <p>
              Sass / Less / Styled-Components / Framer Motion / Gulp / Grunt / Git / Webpack
            </p>
          </li>
        </AnimateLeft>
*/}
        <AnimateLeft 
          durationOnMount={ 600 } 
          delayOnMount={ 4300 }
        >
          <li>
            <h4>Platforms & Softwares</h4>
            <p>
              Netlify / Vercel / Shopify / Elementor / JIRA - Bitbucket / Github / Firebase / MongoDB / MySQL Workbench / Heroku
            </p>
          </li>
        </AnimateLeft>
        <AnimateLeft 
          durationOnMount={ 600 } 
          delayOnMount={ 4500 }
        >
          <li>
            <h4>CMS</h4> 
            <p>Contentful / Strapi / Wordpress / Sanity</p>
          </li>
        </AnimateLeft>
        <AnimateLeft 
          durationOnMount={ 600 } 
          delayOnMount={ 4700 }
        >
          <li>
            <h4>Other Tools</h4> 
            <p>
              Docker / Figma / Photoshop / Illustrator / Salesforce / Adobe Target / Trello / Asana / FullStory
            </p>
          </li>
        </AnimateLeft>
        <AnimateLeft 
          durationOnMount={ 600 } 
          delayOnMount={ 4900 }
        >
          <li>
            <h4>Other Skills</h4> 
            <p>Ruby - Ruby on Rails / Python / Visual Basic</p>
          </li>
        </AnimateLeft>
        {/*
        <AnimateLeft 
          durationOnMount={ 600 } 
          delayOnMount={ 5100 }
        >
          <li><h4>In Progress</h4> <p>TypeScript / Jest / Cypress</p></li>
        </AnimateLeft>
        */}
      </ul>
    </div>
  );

}

export default SkillsSection;




