import React from 'react';
import './hamburger.styles.scss';


const HamburgerComponent = ({ color, hamburgerOpen, onClick }) => {
  const backgroundSwitch = hamburgerOpen ? '#a3734c' : '#a3734c';
  const styles = {
    HamburgerBtn__line: {
      background: color,
    },
    HamburgerBtn__lineTop: {
      background: backgroundSwitch,
      transform: hamburgerOpen ? 'translate(2px, 5px) rotate(-45deg)' : 'none'
    },
    HamburgerBtn__lineMiddle: {
      background: backgroundSwitch,
      opacity: hamburgerOpen ? 0 : 1,
      transform: hamburgerOpen ? 'translateX(-16px)' : 'none'
    },
    HamburgerBtn__lineBottom: {
      background: backgroundSwitch,
      width: hamburgerOpen ? '70%' : '50%',
      transform: hamburgerOpen ? 'translate(2px, -7px) rotate(45deg)' : 'none'
    },
  }
  return (
    <div 
      className="HamburgerBtn__container"
      onClick={ onClick ? onClick : null }
    >
      <div className="HamburgerBtn__line HamburgerBtn__lineTop" 
        style={{ ...styles.HamburgerBtn__line, ...styles.HamburgerBtn__lineTop }}
      ></div>
      <div className="HamburgerBtn__line HamburgerBtn__lineMiddle" 
        style={{ ...styles.HamburgerBtn__line, ...styles.HamburgerBtn__lineMiddle }}
      ></div>
      <div className="HamburgerBtn__line HamburgerBtn__lineBottom" 
        style={{ ...styles.HamburgerBtn__line, ...styles.HamburgerBtn__lineBottom }}
      ></div>
      <h6>
        {
          hamburgerOpen ? 
          <span className="open">CLOSE</span> : 
          <span className="closed">MENU</span>
        }
      </h6>
    </div>
  )
}

export default HamburgerComponent;




