import { createSelector } from 'reselect';

// ---------------------------------------- INPUT SELECTOR
//  - Gets the whole reducer state, one layer DB usually.
// 
// var selectCart = function selectCart( state ) {
//   return state.cart;
// }
const selectCart = state => state.cart;

//  - We need to just get a piece of the cart state from reducer, using 
//    the INPUT SELECTOR:
  /*
  cart:
    cartItems: (3) [{…}, {…}, {…}]
    hidden: true
    __proto__: Object
  */
export const selectCartItems = createSelector(
  // console.log('selectCartItems')
  //  createSelector() :
	// 	1st arg is collection so an array of input selectors.
	// 	2nd arg is function that will return the value we want out of the selector.
	// 	Bc we use 'createSelector' to make this select cart item selector, its now a 'memoization selector'.
  [ selectCart ],
  cart => cart.cartItems
);

export const selectCartHidden = createSelector(
  [selectCart],
  cart => cart.hidden
);



// ----------------------------------------	OUTPUT SELECTOR
export const selectCartItemsCount = createSelector(
  [selectCartItems],

  // 	cartItems here is passed to 'cart-icon.component.jsx' cartItems
  cartItems =>
    cartItems.reduce(
      (accumalatedQuantity, cartItem) =>
        accumalatedQuantity + cartItem.quantity,
      0
    )
);

export const selectCartTotal = createSelector(
  [selectCartItems],
  cartItems =>
    cartItems.reduce(
      (accumalatedQuantity, cartItem) =>
        accumalatedQuantity + cartItem.quantity * cartItem.price,
      0
    )
);



/* ---------------------------------------------------- N O T E S

'Selectors' :

  2 TYPES OF SELECTORS :
  1. 'input selector'
    - doesnt use 'createSelector'.
    - its a func that gets the whole state and just 
      returns a slice of it, one layer DB usually

  2. 'output selector '
    - that does use 'input selectors' and 'createSelector'
      to build something


  'createSelector' :
    - determines iff the value returned by an input-selector has changed 
      between calls using reference equality (===). Inputs to selectors 
      created w/ createSelector should be immutable.

  'createStructuredSelector' :
    - takes an object whose properties are input-selectors and 
      returns a structured selector. 
    - The structured selector returns an object w/ the same keys 
      as the inputSelectors argument, but w/ the selectors replaced 
      w/ their values.



*/









