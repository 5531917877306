// @flow  :  https://flow.org/blog/2015/02/18/Import-Types/
// https://stackoverflow.com/questions/50441093/is-there-a-point-to-doing-import-type-rather-than-import-with-flow
import { connect } from 'react-redux';
import { setVisibilityFilterStart } from '../../../redux/stills/stills.actions';
import Link from './link-component';
import type { State, Dispatch } from '../types';
// import type { VisibilityFilter } from '../types/visibilityFilter';
import { VisibilityFilter } from '../../../redux/stills/visibilityFilter';

type OwnProps = {
  filter: VisibilityFilter
};

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  return {
    active: state.visibilityFilter === ownProps.filter
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  // console.log('ownProps ', ownProps)
  /*
    ownProps  
      {filter: "SHOW_COTTONWOOD", children: "Cottonwood"}
        filter: "SHOW_COTTONWOOD"
        children:    "Cottonwood"
        __proto__: Object
  */
  return {
    onClick: () => {
      console.log('ownProps ', ownProps)
      console.log('ownProps.filter ', ownProps.filter)
      dispatch(setVisibilityFilterStart(ownProps.filter));
    }
  };
};

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(Link);
