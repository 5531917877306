import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import AboutPage from './pages/aboutpage/aboutpage.component';
import WorksPage from './pages/workspage/workspage.component';
// import StillsPage from './pages/stills/stills.component';
// import StillsPge from './pages/stills/hover-scroll/hover-scroll.component';
import HomePage from './pages/homepage/homepage.component';
import ShopPage from './pages/shop/shop.component';
import SignInAndSignUpPage from './pages/sign-in-and-sign-up/sign-in-and-sign-up.component';
import CheckoutPage from './pages/checkout/checkout.component';

// import Header from './components/header/header.component';
import { selectCurrentUser } from './redux/user/user.selectors';
import { checkUserSession } from './redux/user/user.actions';

import './App.css';
import Hamburger from './components/hamburger/hamburger.component';
import NavMenuBtns from './components/hamburger/nav-menu-btns.component';
// import MenuItem from './components/hamburger/menu-item.component';
// import MenuComponent from './components/hamburger/menu.component';

import AppRouter from './routers/AppRouter';


/*    S T I L L S    A D D E D   T O   F I R E B A S E 
//  - 157 & 158. Moving Our Shop Data To Firebase 2  
//  - COMMENT OUT after loading the 'data.js' we want in Firebase

  //  ADD_DATA_TO_DB  -  UNCOMMENT to load data to firebase :
  //  STILLS DATA :
  import { select_stillsDocument } from './redux/stills/stills.selectors';
  import { addStillsCollection } from './firebase/firebase.utils';

  //  ABOUT DATA :
  import { select_aboutDocument } from './redux/about/about.selectors';
  import { addAboutCollection } from './firebase/firebase.utils';
*/
  // //  CONTACT DATA :
  // import { select_contactDocument } from './redux/contact/contact.selectors';
  // import { addContactFormInput } from './firebase/firebase.utils';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { BrowserRouter, Router, Route, Switch, Link, NavLink, withRouter, Redirect } from 'react-router-dom';

const App = ({ 
  checkUserSession,
  currentUser, 
  location, 
  stillsCollectionArray,
  // aboutCollectionArray,
  contactCollectionArray,
}) => {
  //  useState( current state value, function to update )
  // const [ menuOpen, funcToUpdate ] = useState(null);

  useEffect(() => {
    checkUserSession();
  }, [ checkUserSession ]);


/*    S T I L L S    A D D E D   T O   F I R E B A S E
    - 157 & 158. Moving Our Shop Data To Firebase 2  
    - COMMENT OUT after loading the 'data.js' we want in Firebase
    
    E X A M PL E :
    addStillsCollection(
      1st arg : 'key name to pass in', 
      3nd arg : array to pass in
    )   

    //  ADD_DATA_TO_DB  -  UNCOMMENT to load data to firebase :
    //  ADD STILLS DATA TO FIREBASE  :
    addStillsCollection(
      'stillsCollection', 
      stillsCollectionArray.map( 
        ({ ...allProps }) => ({ ...allProps })
      )
    )

    //  ADD ABOUT DATA TO FIREBASE  :
    addAboutCollection(
      'aboutCollection', 
      aboutCollectionArray.map( 
        ({ ...allProps }) => ({ ...allProps })
      )
    )
*/
    // //  ADD CONTACT DATA TO FIREBASE  :
    // addContactFormInput(
    //   'contactCollection', 
    //   contactCollectionArray && contactCollectionArray.map( 
    //     ({ ...allProps }) => ({ ...allProps })
    //   )

    //   // contactCollectionArray && contactCollectionArray.map( 
    //   //   ({ ...allProps }) => ({ ...allProps })
    //   // )
    // )


  /*  NOT USING
    const menu = [ 'Home', 'About', 'Works', 'Stills', 'Contact' ]
    const menuItems = menu.map( ( val, index ) => {
      return (
        <MenuItem 
          key={ index } 
          delay={ `${index * 0.1}s` }
          open={ menuOpen }
          // onClick={ () => { this.handle__LinkClick(); }}
        >
          { val }
        </MenuItem>
      )
    });
  */


  return (
    <div>
      <AppRouter></AppRouter>
    </div>
  );
};

/*  E X P L A N A T I O N  -->
  Even though we have 1 selected property ( currentUser: user.currentUser )
  in our 'mapStateToProps', we should still use 'createStructuredSelector'
  bc in the future if we need to pull in more, itll be easier. In the end,
  it is less code rewrite.    */
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  // collectionsArray: selectCollectionsForPreview    // COMMENT OUT after loading 'shop.data.js' - 157. Moving Our Shop Data To Firebase 2
  
/*    S T I L L S    A D D E D   T O   F I R E B A S E
  //  - 157 & 158. Moving Our Shop Data To Firebase 2  
  //  - COMMENT OUT after loading the 'data.js' we want in Firebase

  //  ADD_DATA_TO_DB  -  UNCOMMENT to load data to firebase :
  //  STILLS DATA :
  stillsCollectionArray: select_stillsDocument

  //  ABOUT DATA :
  aboutCollectionArray: select_aboutDocument
*/
  //  CONTACT DATA :
  // contactCollectionArray: select_contactDocument,

});

const mapDispatchToProps = dispatch => ({
  checkUserSession: () => dispatch(checkUserSession())
});

export default withRouter( 
  connect( 
    mapStateToProps, 
    mapDispatchToProps
  )(App)
);









