import React, { Component } from 'react';
import { Parallax } from 'react-spring/renderprops-addons';

import ABOUT_DATA from './about.data.js';
import Company from './company/company.component';

// import Page from '../page.component';
import LoaderFillUp from '../../components/page-loading-fillup/loader-fillup.component';
import AnimateDelay from '../page-enter-leave/onmount-animate-delay.component';
import AnimateUp from '../page-enter-leave/onmount-animate-up.component';

import BkgdPageTitle from '../../components/bkgd-page-title/bkgd-page-title.component';

//  F I X E D   B T N S
import BackToTop from '../../components/back-to-top/back-to-top.component';
import FixedEmailMeBtn from '../../components/fixed-emailme-btn/fixed-emailme-btn.component';
// import GlobalHomeBtn from '../../components/global-home-btn/global-home-btn.component';

//  S V G s
import { ReactComponent as ArrowShortPrev } from '../../assets/arrowshort_prev.svg';
import { ReactComponent as ArrowShortNext } from '../../assets/arrowshort_next.svg';

import './workspage.styles.scss';
import './workspage-mount-unmount.styles.scss';





class WorksPage extends Component {
  constructor( props ) {
    super(props)
    this.state = {
      pageTitle: 'Works',
      currentSlide: 0,
      aboutData: ABOUT_DATA
    }

    this.myRef = React.createRef()

    //  R E F   W O R K S   S L I D E R
    this.triggerNextSlide = this.triggerNextSlide.bind(this);
    this.handle_nextProject = this.handle_nextProject.bind(this);
    this.handle_prevProject = this.handle_prevProject.bind(this);
  }
  scrollToCurrentSlide = to => this.refs.worksPage_parallaxTag__ref.scrollTo(to);


  triggerNextSlide  = () => {
    let currentSlideState = this.state.currentSlide;
    setTimeout(() => {
      this.scrollToCurrentSlide( currentSlideState )
    }, 100)
  }


  //  T R I G G E R   P R E V   B T N
  handle_prevProject = () => {
    let currentSlide = this.state.currentSlide - 1;   // 1

    /*    E X P L A N A T I O N   :
      - This is not needed here bc the above code changes the state of 
        'currentSlide' and thus triggers componentDidUpdate         */
    if ( currentSlide === -1 ) {
      this.scrollToCurrentSlide( 5 )
      // this.triggerNextSlide();
      this.setState({ currentSlide: 5 })
    }
    else {
      //  If you want a function to be executed after the state change occurs, pass it in as a callback.
      this.setState({ currentSlide: currentSlide }, function () {
        this.triggerNextSlide();
      });
    }
  };


  //  T R I G G E R   N E X T   B T N
  handle_nextProject = () => {
    let currentSlide = this.state.currentSlide + 1    // 1

    if ( currentSlide === 6 ) {
      //  C U R R E N T   S L I D E   -   T R I G G E R
      this.scrollToCurrentSlide( 0 )
      // this.triggerNextSlide();
      this.setState({ currentSlide: 0 })
    }
    else {
      //  If you want a function to be executed after the state change occurs, pass it in as a callback.
      this.setState({ currentSlide: currentSlide }, function () {
        // console.log( 'TEST ELSE: ', this.state.currentSlide );
        this.triggerNextSlide();
      });
    }
  };


  componentDidMount() {
    // this.myRef.current.scrollTo(0, 0);
    document.getElementById('root').scrollTop = 0;
  }

  render() {
    const { aboutData } = this.state;
    const worksData = aboutData[3].companies;

    return (
      <div ref={this.myRef}>
        <div className="WorksPage__container">
          <AnimateDelay
            durationOnMount={ 600 } 
            // delayOnMount={ 2500 }
            delayOnMount={ 2800 }
          >

            {/*  WORKS WORKS WORKS  */}
            <BkgdPageTitle
              title={ this.state.pageTitle }
              delay={ 4000 }
            ></BkgdPageTitle>

            {/*  PREV / NEXT ARROWS  */}
            <div className="prevBtn" 
              onClick={ this.handle_prevProject }>
              <ArrowShortPrev></ArrowShortPrev>
            </div>
            <div className="nextBtn" 
              onClick={ this.handle_nextProject }>
              <ArrowShortNext></ArrowShortNext>
            </div>

            <Parallax 
              className="Parallax__tag" 
              ref="worksPage_parallaxTag__ref"
              pages={ 6 }
              horizontal 
              scrolling={ false }
              easing={ 'ease-in-out' }
              config={{
                tension: 125,
                friction: 50,
                // velocity: -100

                // 0.43, -0.01, 1, 0.66
                // http://stakes.github.io/framerplayground/
              }}
            >
              {
                worksData.map( ({ id, ...allProps }) => (
                  <Company 
                    key={ id } { ...allProps }
                    offset={ id - 1 } 
                    gradient="pink"
                  />
                ))
              }
            </Parallax>          
          </AnimateDelay>
        </div>

        {/*  FIXED EMAIL ME  /  BACK TO TOP  */}
        <FixedEmailMeBtn></FixedEmailMeBtn>
        <BackToTop />
      </div>
    )
  }
}

export default WorksPage;




