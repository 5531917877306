import React, { Component, useState, useEffect } from 'react'
import { render } from 'react-dom'
import { useTransition, animated, config } from 'react-spring'
// import ABOUT_DATA from '../about.data.js';


class ImgFadeSliderComponent extends Component {
  constructor( props ) {
    super(props)
    this.state = {
      arrayOfImgsLength: [],
      currentPosition: this.props.slidePosition,
    }
    this.nextSlide = this.nextSlide.bind(this);
  };

  prevSlide = ( countToSubtract ) => {
    let slidePosition = this.state.currentPosition + countToSubtract;
    let numberOfImgs = this.state.arrayOfImgsLength;

    if ( slidePosition === -1 ) {
      this.setState({
        currentPosition: numberOfImgs - 1
      })
    }
    else {
      //  If you want a function to be executed after the state change occurs, pass it in as a callback.
      this.setState({ currentPosition: slidePosition }, function () {
        // this.trigger_AmountToScroll( slidePosition );
      });
    }
  };

  nextSlide = ( countToAdd ) => {
    let slidePosition = this.state.currentPosition + countToAdd;
    let numberOfImgs = this.state.arrayOfImgsLength;

    if ( slidePosition >= numberOfImgs ) {
      this.setState({
        currentPosition: 0
      })
    }
    else {
      //  If you want a function to be executed after the state change occurs, pass it in as a callback.
      this.setState({ currentPosition: slidePosition }, function () {
        // this.trigger_AmountToScroll( slidePosition );
      });
    }
  };

  componentDidMount() {
    this.setState({
      arrayOfImgsLength: this.props.arrayOfImgs.length
    })
  }


  render() {
    let getAllImageUrls = this.props.arrayOfImgs;
    let getCurrentPosition = this.props.slidePosition;

    //  ----------------------------------------  T H E   S L I D E R
    const FadeSlider = ({ 
      // getAllImageUrls, 
      // getCurrentPosition 
    }) => {

      const [index, set_slidePosition] = useState( 0 )

      /*  PURE JS
        var transitions = useTransition(ABOUT_DATA[index], function (item) {
          return item.id;
        }, {
          from: { opacity: 0 },
          enter: { opacity: 1 },
          leave: { opacity: 0 },
          config: config.molasses
        });     */
      const transitions = useTransition( getAllImageUrls[index], item => item, {
        from: { 
          opacity: 0, 
          transition: '0.1s'
        },
        enter: { 
          opacity: 1, 
          transition: '0.1s'
        },
        leave: { 
          opacity: 0, 
          transition: '0.1s'
        },
        config: config.molasses,
      })

      // - 'useEffect' : function that gets called, when component mounts / updates and then re-renders
      useEffect(
        () => {
          set_slidePosition( getCurrentPosition  )
        }
      )

      //  ORIGINAL CODE ------------------------ PURE JS
      /*  - SOLUTION FOR FIRST FADE SLIDER IN ABOUT PAGE
        (function () {
          return void setInterval(function () {
            return set_slidePosition(function (state) {
              console.log('(state + 1) % 4; ', (state + 1) % numberOfImgs)
              return (state + 1) % numberOfImgs;
            });
          }, 1000);
        }), []    *//*

        //  ---------------------------------- DESTRUCTURED
        () => void setInterval(
          () => set_slidePosition( state => (state + 1) % 4), 10000
        ), []
      */

      return transitions.map(({ item, props, key }) => (
        <animated.div
          key={key}
          className="imgFadeSlider aboutIntroSlider"
          style={{ ...props, backgroundImage: `url(` +  `${item}` + `)` }}
        />
      ))
    }

    return (
      <FadeSlider></FadeSlider>
    )
  }
}

export default ImgFadeSliderComponent;




/*  -----------------------------------------  O R I G I N A L
  import { render } from 'react-dom'
  import React, { useState, useEffect } from 'react'
  import { useTransition, animated, config } from 'react-spring'
  // import './about-intro-slider.styles.scss';
  import ABOUT_DATA from './about.data.js';


  const AboutIntroSlider = () => {
    // console.log(
    //   'ABOUT_DATA ', 
    //   ABOUT_DATA
    //   .map( ( item ) => {
    //     return item.css
    //   })
    // )

    const [index, set] = useState(0)
    const transitions = useTransition(ABOUT_DATA[index], item => item.id, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: config.molasses,
    })

    useEffect(() => void setInterval(() => set(state => (state + 1) % 4), 5000), [])

    return transitions.map(({ item, props, key }) => (
      <animated.div
        key={key}
        className="aboutIntroSlider"
        style={{ ...props, backgroundImage: `${item.css}` }}
      />
    ))
  }

  export default AboutIntroSlider;
*/


