// @flow  :  https://flow.org/blog/2015/02/18/Import-Types/

import React, { type Node } from 'react';


// export type Props = {
//   active: boolean,
//   children?: Node,
//   onClick: () => void
// };

const Link = ({ active, children, onClick }: Props) => {
  if (active) {
    return <span>{children}</span>;
  }

  const backToStart = () => {
    const imgsContainer = document.querySelector(".imgs_container");
    imgsContainer.scroll({
      top: 0,
      left: 0,  // Controls Speed
      // behavior: 'smooth'
    })
  }

  return (
    <a // eslint-disable-line jsx-a11y/anchor-is-valid
      href="#"
      onClick={(event: Event) => {
        event.preventDefault();
        onClick();
        backToStart();
      }}
    >
      {children}
    </a>
  );
};

export default Link;
