import React from 'react';
import { Link } from 'react-router-dom';
import AnimateUp from '../../page-enter-leave/onmount-animate-up.component';


const divStyle = {
  // color: 'white',
  // position: 'relative',
  // zIndex: 1,
};

const WorksEduContent = ({ 
  getSchoolAndCompany,
  slidePosition,
}) => {
  let slideToShow = getSchoolAndCompany[slidePosition];

  const schoolAndCompany = getSchoolAndCompany.map( ( val, index ) => {
    return (
      <div key={ index }>

        <div className="schoolOrCompany">
          <AnimateUp
            durationOnMount={ 500 }
            delayOnMount={ 500 }
          >
            <h1>{ val.school ? val.school : val.companyName }</h1>
          </AnimateUp>
          <AnimateUp
            durationOnMount={ 500 }
            delayOnMount={ 700 }
          >
            <h3>{ val.location }</h3>
            <span></span>
          </AnimateUp>
        </div>

        <div className="focus">
          <AnimateUp
            durationOnMount={ 500 }
            delayOnMount={ 900 }
          >
            <h4>{ val.concentration ? val.concentration : val.role }</h4>
          </AnimateUp>
        </div>

        <div className="schoolOrCompanyIntro">
          <AnimateUp
            durationOnMount={ 500 }
            delayOnMount={ 1100 }
          >
            <p>{ val.schoolIntro ? val.schoolIntro : val.companyDesc }</p>
          </AnimateUp>
        </div>

        <div className="goToSite">
          <AnimateUp
            durationOnMount={ 500 }
            delayOnMount={ 1100 }
          >
            <a href={ val.link } target="_blank">
              <p className="hvr-sweep-to-right">View More</p>
            </a>
          </AnimateUp>
        </div>
      </div>
    )
  });

  return (
    <div 
      className="worksEduContent"
      style={ divStyle }
    >
      { schoolAndCompany[slidePosition] }
    </div>
  )
}

export default WorksEduContent;




