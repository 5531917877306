import React from 'react';
// import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

function Page({ children, color, location: { state }}) {
  const pageComponentClass = classNames({
    page: true,
    'page--isLoading': state && state.isLoading,
  })

  return (
    <section 
      className={ pageComponentClass }
      style={{ color }}
    >
      <div className="page__inner">
        {children}
      </div>
    </section>
  );
}

// Page.propTypes = {
//   children: PropTypes.node.isRequired,
//   color: PropTypes.string,
//   // background: PropTypes.string,
// };

// Page.defaultProps = {
//   color: 'red',
//   // background: '#666666',
// };

export default withRouter(Page);