import React from 'react';

// import './custom-buttom.styles.scss';
import { CustomButtonContainer } from './custom-button.styles';

const CustomButton = ({ children, ...props }) => (
  <CustomButtonContainer {...props}>{children}</CustomButtonContainer>
);

{/*   Before 150. styled-components In Our App 3 - https://www.udemy.com/complete-react-developer-zero-to-mastery/learn/lecture/15186270#content
const CustomButton = ({ children, isGoogleSignIn, inverted, ...otherProps }) => (
  <button 
    className={
      `
      ${inverted ? 'inverted' : ''}
      ${isGoogleSignIn ? 'google-sign-in' : ''} 
      custom-button
      `
    }
    {...otherProps}
  >
    {children}
  </button>
);
*/}

export default CustomButton;
