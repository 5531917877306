import React, { Component } from 'react';
import AnimateRight from '../../page-enter-leave/onmount-animate-right.component';
import './page-details.styles.scss';


class PageDetail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showDetails: this.props.active,
    }
    this.closeModal = this.closeModal.bind(this);
  };

  closeModal = () => {
    this.setState({ 
      // showDetails: !this.state.showDetails
      showDetails: true
    })
  };

  render() {
    return (
      <div className="pageDetails"
        showDetails={ this.state.showDetails }
      >

        {/*  CLOSE MODAL  */}
        <div 
          className="closeModal" 
          onClick={ this.closeModal }
        > CLOSE </div>
        
        <h4>Page in progress :</h4>

        <h4>Page Built With :</h4>
        <ul>
          <li>+ Filter done through Redux / Redux Saga middleware</li>
          <li>+ Images and related data from Firebase Storage</li>
          <li>+ Javascript</li>
        </ul>

        <h4>Plans :</h4>
        <ul>
          <li>+ Remove Lightbox Modal and</li>
          <li>
            + Add Interactive Storytelling for my own trips and stills with custom location maps<br/>
            <a href="https://tympanus.net/Development/StorytellingMap/" target="_blank">
              Example - Interactive Storytelling
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default PageDetail;





