const ContactActionTypes = {
  CREATE_PROJECT: 'CREATE_PROJECT',
  CREATE_PROJECT_START: 'CREATE_PROJECT_START',
  CREATE_PROJECT_SUCCESS: 'CREATE_PROJECT_SUCCESS',
  CREATE_PROJECT_FAILURE: 'CREATE_PROJECT_FAILURE',
};

export default ContactActionTypes;


