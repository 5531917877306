import { createSelector } from 'reselect';


/*	I N P U T  -  S E L E C T O R  -->		*/
	// - rootReducer is all whote reducers rootReducer, everything, we're only getting a piece of it.
	var selectStills = function selectStills( rootReducer ) {
	  return rootReducer.stills;
	};		
// 	Select 'stills' from 'rootReducer' being passed in :
// export const selectStills = rootReducer => rootReducer.stills;



/*		JS
	Object.defineProperty(exports, "__esModule", {
	  value: true
	});
	exports.selectStills = void 0;
	var selectStills = createSelector([selectStills], function (get_stillsReducer) {
	  return get_stillsReducer.stillsIS;
	});
	exports.selectStills = selectStills; 					

	// 	O U T P U T  -  S E L E C T O R
	//	1st arg : a collection, array of input-selectors
	// 	2nd param : is a function that will return the value we want out of the selector 		*/
// 	Select 'stills > stills_documents' :
export const select_stillsDocuments = createSelector(
	[ selectStills ],
	(function (selectStills) {
	  return selectStills.stills_documents;
	})
	// selectStills => selectStills.stills_documents
);

// 	* * * * *  :  TO ADD DATA TO LIVE DB
/*		ADD_DATA_TO_DB  -  UNCOMMENT to load data to firebase :

	export const select_stillsDocument = createSelector(
	  [ select_stillsDocuments ],
			// E X P L A N A T I O N  -->  
			// - if 'stillsDocuments' is a null object, return somethings
			// 	different, so :

			// 	- if 'stillsDocuments' exists, then call that object,
			// 	- if false, return empty object. 		
		
			(function (stillsDocuments) {
				console.log('select_stillsDocument ', stillsDocuments)

			  return stillsDocuments ? Object.keys(stillsDocuments).map(function (key) {
			  	// console.log('131 select_stillsDocument ', stillsDocuments[key])
			    return stillsDocuments[key];
			  }) : [];
			})	
	  // stillsDocuments => stillsDocuments ? Object.keys(stillsDocuments).map( key => stillsDocuments[key] ) : []
	);
*/





// 	------------------------------------  F I L T E R
export const select_visibleState = createSelector(
	[ selectStills ],
	(function (selectStills) {
	  return selectStills.visibleState;
	})	
	// selectStills => selectStills.stills_documents
);


export const visibleStillsDocuments = createSelector(
  [ 
  	select_visibleState, 
  	select_stillsDocuments, 
  	// select_stillsDocument
  ],
	(visibleState, stills_documents) => {
    switch (visibleState) {
      //  case 'FETCH_STILLS_SUCCESS':
			  // return stills_documents ? Object.keys(stills_documents).map(function (key) {
			  //   return stills_documents[key];
			  // }) : [];

      case 'SHOW_ALL':
        return stills_documents.filter( function( doc ) {
        	return doc
        })

      case 'SHOW_YOSEMITE':
        return stills_documents.filter( function( doc ) {
        	return doc.location === 'yosemite'
        })

      case 'SHOW_COTTONWOOD':
        return stills_documents.filter( function( doc ) {
        	return doc.location === 'cottonwood'
        })

      case 'SHOW_BIGSUR':
        return stills_documents.filter( function( doc ) {
        	return doc.location === 'big_sur'
        })

      case 'SHOW_SEQUOIA':
        return stills_documents.filter( function( doc ) {
        	return doc.location === 'sequoia'
        })

      case 'SHOW_UTAH':
        return stills_documents.filter( function( doc ) {
        	return doc.location === 'utah'
        })

      default:
			  return stills_documents ? Object.keys(stills_documents).map(function (key) {
			    return stills_documents[key];
			  }) : [];
    }
	}
);

export const numberOfStillsPerLocation = createSelector(
  [ visibleStillsDocuments ],
	( stills_documents ) => {
		// console.log('numberOfStillsPerLocation -----> stills_documents ', stills_documents.length);
		return stills_documents.length;
	}
);

export const numberOfColumns = createSelector(
  [ numberOfStillsPerLocation ],
	( number_of_stills ) => {
		// console.log(
		// 	'numberOfColumns -----> number_of_stills ',
		// 	Math.floor( number_of_stills / 3 ) + 1
		// );
		return Math.floor( number_of_stills / 3 ) + 1;
	}
);





/* 	E X P L A N A T I O N  -->
		- 131. Collection Routing and Selector
		- 2 min mark

	export const selectStill = stillUrlParam =>
		createSelector([select_stillsDocuments], function (stills_documents) {
			// console.log('stills_documents ', stills_documents)
		  return stills_documents ? stills_documents[stillUrlParam] : null;
		});
	// createSelector(
	// 	[ select_stillsDocuments ],
	// 	stills_documents => (stills_documents ? stills_documents[ stillUrlParam ] : null)
	// );
*/




export const selectIsStillsFetching = createSelector(
  [selectStills],
  stills => stills.isFetching
);
/*  E X P L A N A T I O N  -->
		- 170. Debugging Our Code :
		- returns a boolean value of whether our 'stills_documents'
			from 'stills.reducer' is null or not. 	*/
export const selectIsStillsLoaded = createSelector(
  [selectStills],
  stills => !!stills.stills_documents
);





/* --------------------------------------------- N O T E S
	'114. Reselect Library' :

		'reselect' :
		- reselect allows us to write these 'selectors' in such a
			way so that it knows that iff the properties that its 
			pulling from the state and using are the same in the 
			sense that their value hasnt changed and the output of
			the selector is not different, then it wont actually 
			pass them into our component itll just pass the old 
			value and our React coponent will know not to rerender.


		2 TYPES OF SELECTORS :
		1. input selector
			- doesnt use 'createSelector'.
			- its a func that gets the whole state and just 
				returns	a slice of it, one layer DB usually.

		2. output selector 
			- that does use input selectors and 'createSelector'
				to build themselves


		'createSelector' :
			- determines if the value returned by an input-selector has changed 
				between calls using reference equality (===). Inputs to selectors 
				created with createSelector should be immutable.

		'createStructuredSelector' :
			- takes an object whose properties are input-selectors and 
				returns a structured selector. 
			- The structured selector returns an object with the same keys 
				as the inputSelectors argument, but with the selectors replaced 
				with their values.

*/
