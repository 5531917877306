// import { UserActionTypes } from './user.types';
import UserActionTypes from './user.types'

const INITIAL_STATE = {
  currentUser: null,
  error: null
};

const userReducer = ( state = INITIAL_STATE, action ) => {
  switch (action.type) {
    // case UserActionTypes.SET_CURRENT_USER:

    /*  E X P L A N A T I O N  -->
      . . .
      case UserActionTypes.GOOGLE_SIGN_IN_SUCCESS:
      case UserActionTypes.EMAIL_SIGN_IN_SUCCESS:
      . . .

      - we are able to stack the 'case' code like above, which essentially
        represents that if either of these actions is the case, return :
        . . .
        return {
          ...state,
          currentUser: action.payload,
          error: null     //  if our signin succeeds, no error so null
        };
        . . .     */
    // case UserActionTypes.GOOGLE_SIGN_IN_SUCCESS:
    // case UserActionTypes.EMAIL_SIGN_IN_SUCCESS:
    case UserActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        error: null     //  if our signin succeeds, clear the error, so null
      };

    case UserActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        error: null
      };

    // case UserActionTypes.GOOGLE_SIGN_IN_FAILURE:
    // case UserActionTypes.EMAIL_SIGN_IN_FAILURE:
    case UserActionTypes.SIGN_IN_FAILURE:
    case UserActionTypes.SIGN_OUT_FAILURE:
    case UserActionTypes.SIGN_UP_FAILURE:
      return {
        ...state,
        errot: action.payload
      };

    default:
      return state;
  }
};

export default userReducer;





/*--------------------------------------- N O T E S

//  R E D U C E R  :
{
  type:
  payload:
}



---------------------------------------*/