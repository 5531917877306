import React, { Component } from 'react';
import { MenuComponent_style } from './menu-component.styles';


const MenuComponent = ({ menuOpen, children }) => {
  // console.log('MenuComponent -----> menuOpen ', menuOpen)
  // console.log('MenuComponent -----> children ', children)


  // const styles = {
  //   HamburgerMenu__container: {
  //     height: menuOpen ? '100%' : 0,
  //     opacity: menuOpen ? 0.95 : 0,
  //     // transition: '0.3s'
  //   }
  // }

  return (
    <MenuComponent_style
      className="menuComponent" 
      menuOpen={ menuOpen }
      // style={ styles.HamburgerMenu__container }
    >
      {
        // menuOpen ?
        // <div className="menuList">
        //   { children }
        // </div> :
        // null

        <div className="menuList">
          { children }
        </div>
      }
    </MenuComponent_style>
  )
}



/*    CLASS-BASED COMPONENT
  class MenuComponent extends Component {
    constructor( props ) {
      super( props );
      this.state = {
        // menuOpen: this.props.menuOpen ? this.props.menuOpen : false,
      }
    }

    static getDerivedStateFromProps(nextProps, prevState){
      if ( nextProps.menuOpen !== prevState.menuOpen ) {
        return { menuOpen: nextProps.menuOpen};
      } else return null;
      // return null;
    }
    componentDidUpdate(prevProps, prevState) {
      if ( prevProps.menuOpen !== this.props.menuOpen ) {
        this.setState({ menuOpen: this.props.menuOpen });
      }
    }

    render() {
      const styles = {
        HamburgerMenu__container: {
          height: this.state.menuOpen ? '100%' : 0
        }
      }
      return(
        <div className="HamburgerMenu__container" style={ styles.HamburgerMenu__container }>
          {
            this.state.menuOpen ?
            <div className="menuList">
              { this.props.children }
            </div> :
            null
          }
        </div>
      )
    }
  }
*/
export default MenuComponent;


