import React, { Component } from "react";
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';


function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      setTimeout(() => {
        window.scroll(0, 0);
      }, 1300)
    });

    return () => {
      unlisten();
    }
  }, []);

  return (null);
}

export default withRouter(ScrollToTop);