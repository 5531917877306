// HOME PAGE DATA

const ABOUT_DATA = [
  {
    id: 1,
    title: 'About Intro',
    routeName: 'intro',
    items: [
      {
        id: 1,
        title: 'Alex Y. Hong',
        subtitle: 'Web Developer - ReactJS / GatsbyJS / NextJS / Wordpress',
        imageUrl: '',
        mobile: '(818) 509-4137',
        email: 'alexhwebdev@gmail.com',
        linkedin: '',
        introImgs: [
          './images/about/aboutintro_1.jpg',
          './images/about/aboutintro_2.jpg',
          './images/about/aboutintro_3.jpg',
          './images/about/aboutintro_4.jpg',
          './images/about/aboutintro_5.jpg',
        ],
        firstPara: 'Content in progress. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vitae justo nec mi vestibulum volutpat nec tempus orci. Nunc sagittis accumsan orci, sed ornare felis accumsan hendrerit. Aliquam dapibus dolor ac mauris venenatis, id tempus nisi lacinia. Sed dapibus, elit nec laoreet ullamcorper, metus quam auctor libero, nec aliquam enim nibh nec turpis. Aenean eget egestas turpis.',
        secondPara: 'Vivamus a imperdiet ipsum, et placerat mi. Cras convallis sagittis sapien mollis cursus. Cras sit amet magna laoreet mauris interdum convallis sed vitae enim. Donec sit amet augue vitae eros imperdiet venenatis ac eu turpis. Donec rhoncus metus ut varius euismod. Quisque bibendum posuere eros eget porttitor. Ut laoreet laoreet hendrerit. Proin vitae erat vitae elit auctor interdum ac ac ex. Aenean nec diam pharetra, sodales sem lacinia, eleifend nibh. Interdum et malesuada fames ac ante ipsum primis in faucibus. Ut risus justo, euismod vitae rutrum in, pulvinar eget eros. Quisque sit amet tortor lacinia, dapibus orci id, facilisis lectus. In scelerisque risus tellus, ac consectetur eros placerat ac. Suspendisse turpis ante, blandit ut viverra tempor, cursus sed purus. Mauris cursus luctus fermentum.',
      },
    ]
  },
  // NOT USING THIS SECTION
  {
    id: 2,
    title: 'About Skills',
    routeName: 'skills',
    items: [
      {
        id: 2,
        title: 'Skills',
        subtitle: 'Technologies and Languages I\'ve worked with :',
        imageUrl: '',

        languages: 'JavaScript / HTML5 / CSS3 / PHP / SQL',
        frameworks: 'React / GatsbyJS / NextJS / AngularJS / Ember / Express / Bootstrap',
        libraries: 'Redux / Redux-Saga / React Router / jQuery / GreenSock / SailsJS / React-Spring / React Animated',
        buildTools: 'Sass / Less / Styled-Components / Flexbox / Gulp / Grunt / git / npm / Webpack / Babel / Bower / Yarn / MySQL Workbench',
        platforms: 'Node / JIRA - Bitbucket / Github / Firebase / MongoDB / Heroku',
        cms: 'Wordpress',
        tools: 'Jenkins / GenghisApp / Photoshop / Illustrator / Figma / Salesforce',
        otherskills: 'Ruby / Ruby on Rails / Python / Visual Basic',
        todo: 'Gatsby / GraphQL / Apollo / Jest'
      }
    ]
  },
  {
    id: 3,
    title: 'About Education',
    routeName: 'education',
    schools: [
      {
        id: 3,
        school: 'University of California Los Angeles',
        concentration: 'Bachelor of Letters and Science: Anthropology (Concentration in Economics)',
        imageUrl: './images/school/ucla_1.png',
        startYr: '2009',
        location: 'Los Angeles, CA',
        link: 'http://www.ucla.edu/',
        schoolIntro: 'The University of California, Los Angeles is a public research university in Los Angeles. It became the Southern Branch of the University of California in 1919, making it the fourth-oldest of the 10-campus University of California system and oldest of the campuses in Southern California',
        schoolDesc: '',
      },
      {
        id: 4,
        school: 'University of Southern California',
        concentration: 'Viterbi School of Engineering: Enterprise Information Systems Specialization ( SAP Specialization )',
        imageUrl: './images/school/usc.png',
        startYr: '2011',
        location: 'Los Angeles, CA',
        link: 'https://www.usc.edu/',
        schoolIntro: 'The University of Southern California is a private research university in Los Angeles, California. Founded in 1880, it is the oldest private research university in California. For the 2018–19 academic year, there were 20,000 students enrolled in four-year undergraduate programs.',
        schoolDesc: '',
      },
      {
        id: 5,
        school: 'General Assembly',
        concentration: 'Front-End, Back-End Development Training',
        imageUrl: './images/school/ga.png',
        startYr: '2014',
        location: 'Los Angeles, CA / Santa Monica, CA',
        link: 'https://generalassemb.ly/',
        schoolIntro: 'General Assembly is a private, for-profit education organization founded by CEO Jake Schwartz, Adam Pritzker, Matthew Brimer, and Brad Hargreaves in early 2011. It maintains campuses in numerous countries throughout the world to teach entrepreneurs and business professionals practical technology skills.',
        schoolDesc: '',
      }
    ]
  },
  {
    id: 4,
    title: 'About Works',
    routeName: 'works',
    companies: [
      {
        id: 1,
        companyName: "Dow Jones - Investor's Business Daily",
        companyIntro: "IBD provides financial news, analysis, and research data for investors.",
        companyDesc: "Investor's Business Daily is an American newspaper and website covering the stock market, international business, finance and economics. Founded in 1984 by William O'Neil as a print news publication, it is owned by News Corp, headquartered in Los Angeles, California. IBD provides news and analysis on stocks, mutual funds, exchange-traded funds, commodities, and other financial instruments aimed at individual investors and financial professionals.",
        date: 'Jun 2020 - current',
        startYr: '2020',
        imageUrl: './images/works/ibd3.png',
        location: 'Los Angeles, California',
        link: 'https://investors.com/',
        role: 'Web Developer',
        worksPageUrl: '',
        projects: [
          {
            id: 1,
            brand: "Investor's Business Daily",
            brandUrl: 'https://investors.com/',
            brandImg: '../images/ibd/ibd.png',
          },
          {
            id: 2,
            brand: 'How to invest in AI',
            brandUrl: 'https://get.investors.com/infographics/ai/',
            brandImg: '../images/ibd/howtoai.png',
          },
          {
            id: 3,
            brand: "Founder's Club",
            brandUrl: 'https://get.investors.com/founders-club/',
            brandImg: '../images/ibd/founders.png',
          },

          {
            id: 4,
            brand: 'IBD Live',
            brandUrl: 'https://get.investors.com/ibd-live/',
            brandImg: '../images/ibd/ibdl.png',
          },
          {
            id: 5,
            brand: 'Elon Musk EV Vehicles',
            brandUrl: 'https://get.investors.com/infographics/elon-musk/',
            brandImg: '../images/ibd/elonev.png',
          },

          {
            id: 6,
            brand: 'IBD Marketing',
            brandUrl: 'https://get.investors.com/',
            brandImg: '../images/ibd/ibdmarketing.png',
          },
          {
            id: 7,
            brand: 'How to invest in EV',
            brandUrl: 'https://get.investors.com/infographics/how-to-invest-in-electric-vehicles/',
            brandImg: '../images/ibd/investev.png',
          },
          {
            id: 8,
            brand: 'MarketSurge',
            brandUrl: 'https://get.investors.com/marketsurge/',
            brandImg: '../images/ibd/ms.png',
          },

          {
            id: 9,
            brand: 'IBD Digital & Print',
            brandUrl: 'https://get.investors.com/ibd/ibd-digital-and-print/',
            brandImg: '../images/ibd/ibddprint.png',
          },
          {
            id: 10,
            brand: 'Leaderboard',
            brandUrl: 'https://get.investors.com/leaderboard/',
            brandImg: '../images/ibd/lb.png',
          },
          {
            id: 11,
            brand: 'SwingTrader',
            brandUrl: 'https://get.investors.com/swingtrader/',
            brandImg: '../images/ibd/st.png',
          }

        ]
      },
      {
        id: 2,
        companyName: 'MGA Entertainment',
        companyIntro: 'MGA Entertainment, the world’s largest private toy company.',
        companyDesc: 'MGA Entertainment Inc. is a manufacturer of childrens toys and entertainment products founded in 1979. Its products include the Bratz fashion doll line, BABY born, L.O.L. Surprise, Num Noms and Poopsie Slime Surprise. MGA also owns Little Tikes. MGA is headquartered in a mixed use corporate campus in the Chatsworth area of Los Angeles. The Companys product line includes childrens toys, games, dolls, electronics, home decorations, stationary, and sporting goods. MGA Entertainment operates worldwide.',
        date: 'Dec 2017 - Jul 2019',
        startYr: '2017',
        imageUrl: './images/works/mgae.png',
        location: 'Van Nuys / Chatsworth, California',
        link: 'https://www.mgae.com/',
        role: 'Web Developer',
        worksPageUrl: '',
        projects: [
          {
            id: 1,
            brand: 'LOL Surprise',
            brandUrl: 'https://lolsurprise.mgae.com/',
            brandImg: '../images/mgae/lol.png',
          },
          {
            id: 2,
            brand: 'Little Tikes',
            brandUrl: 'https://www.littletikes.com/',
            brandImg: '../images/mgae/littletikes.png',
          },
          {
            id: 3,
            brand: 'Poopsie Slime',
            brandUrl: 'https://poopsie.mgae.com/',
            brandImg: '../images/mgae/poopsie.png',
          },
          {
            id: 4,
            brand: 'Baby Born Surprise',
            brandUrl: 'https://babyborn.mgae.com/',
            brandImg: '../images/mgae/babyborn.png',
          },
          {
            id: 5,
            brand: 'Viro Rides',
            brandUrl: 'https://virorides.mgae.com/',
            brandImg: '../images/mgae/viro.png',
          },
          {
            id: 6,
            brand: 'NumNoms',
            brandUrl: 'https://numnoms.mgae.com/',
            brandImg: '../images/mgae/numnoms.png',
          },
          {
            id: 7,
            brand: 'Project MC2',
            brandUrl: 'https://projectmc2.mgae.com/',
            brandImg: '../images/mgae/projectmc2.png',
          },
          {
            id: 8,
            brand: 'The Original Moj Moj',
            brandUrl: 'https://mojmoj.mgae.com/',
            brandImg: '../images/mgae/mojmoj.png',
          },
          {
            id: 9,
            brand: 'Bratz Collector',
            brandUrl: 'https://bratz.mgae.com/',
            brandImg: '../images/mgae/bratz.png',
          },
          {
            id: 10,
            brand: 'Kingdom Builders',
            brandUrl: 'https://kingdombuilders.mgae.com/',
            brandImg: '../images/mgae/kingdom.png',
          }
        ]
      },
      {
        id: 3,
        companyName: 'Webpromo',
        companyIntro: 'Webpromo™ is a membership based web-solution provider.',
        companyDesc: 'Webpromo™ is a membership-based web-solution provider offering quality websites and marketing solutions exclusively for lawyers, doctors, and CPAs. Our mission is to provide web design and marketing solutions that are affordable by all law, doctor, and CPA offices of all sizes.  Simply choose a web design and you will automatically receive all of the perks of being a Webpromo member - without any of the hassle.',
        date: 'Feb 2016 - Dev 2017',
        startYr: '2016',
        imageUrl: './images/works/webpromo.png',
        location: 'Los Angeles, California',
        link: 'https://www.webpromo.com/',
        role: 'Front End Developer / Wordpress Developer',
        worksPageUrl: '',
        projects: [
          {
            id: 4,
            brand: 'Polasek Law Firm',
            brandUrl: 'https://polaszeklaw.com/',
            brandImg: '../images/webpromo/polaszek.png',
          },
          {
            id: 5,
            brand: 'Webpromo',
            brandUrl: 'https://www.webpromo.com/',
            brandImg: '../images/webpromo/webpromo.png',
          },
          {
            id: 6,
            brand: 'Lawpromo',
            brandUrl: 'https://www.lawpromo.com/',
            brandImg: '../images/webpromo/lawpromo.png',
          },
          {
            id: 7,
            brand: 'Template Example',
            brandUrl: 'http://template.webpromo.com/C171057-IC/',
            brandImg: '../images/webpromo/template_1.png',
          },
          {
            id: 8,
            brand: 'Template Example',
            brandUrl: 'http://www.webpromo.com/preview/l171071-ep.html',
            brandImg: '../images/webpromo/template_2.png',
          }
        ]
      },
      {
        id: 4,
        companyName: 'Thunderbolt Studios',
        companyIntro: 'Thunderbolt Studios is a full service digital agency.',
        companyDesc: 'Thunderbolt is a design, product development and marketing company that specializes in the creation of disruptive digital brands and software applications. The projects of Thunderbolt have attracted $8.5M Combined Seed Investments in the past years. We are creative strategists, product designers and developers. Having built projects for companies like Verizon, Ford, Beats by Dre, Thunderbolt utilizes its talent to bridge design and technology into a cohesive whole',
        date: 'Mar 2015 - Dev 2015',
        startYr: '2015',
        imageUrl: './images/works/thunderboltstudios.png',
        location: 'Beverly, California',
        link: 'http://thunderbolt-studios.com/',
        role: 'Front End Developer',
        worksPageUrl: '',
        projects: [
          {
            id: 1,
            brand: 'Thunderbolt Studios',
            brandUrl: 'http://thunderbolt-studios.com/',
            brandImg: '../images/thunderbolt/thunderboltstudios.png',
          },
          {
            id: 2,
            brand: 'Thunderbolt Engine',
            brandUrl: 'http://thunderbolt-studios.com/',
            brandImg: '../images/thunderbolt/engine.png',
          },
          {
            id: 3,
            brand: 'MPT Technologies',
            brandUrl: 'http://mptmustardproducts.com/',
            brandImg: '../images/thunderbolt/mpt.png',
          },
          {
            id: 4,
            brand: 'Curbstand',
            brandUrl: 'https://www.curbstand.com/',
            brandImg: '../images/thunderbolt/curbstand.png',
          },
          {
            id: 5,
            brand: 'Grassp',
            brandUrl: 'https://www.grasspit.com/',
            brandImg: '../images/thunderbolt/grassp.png',
          }
        ]
      },
      {
        id: 5,
        companyName: 'Global Paper Solutions',
        companyIntro: 'Global Paper Solutions provides Asia Pulp & Paper Group (APP).',
        companyDesc: 'Global Paper Solutions / Charta Global is a one-stop solution for world-class office and graphical papers as well as packaging boards. We are the exclusive sales channel in the U.S. & Latin America for Asia Pulp & Paper. We leverage a robust manufacturing network & leading sustainability practice of one of the world’s largest, fully integrated pulp & paper manufacturers with more than 20 million metric tons of paper, board & tissue global capacity.',
        date: 'Sep 2013 - Jan 2015',
        startYr: '2013',
        imageUrl: './images/works/gps.png',
        location: 'Anaheim, California',
        link: 'http://chartaglobal.com/en/home/',
        role: 'SAP WM Consultant',
        worksPageUrl: '',
        projects: []
      },
      {
        id: 6,
        companyName: 'SAP - Korea',
        companyIntro: 'SAP is a European multinational software corporation.',
        companyDesc: 'SAP is the market leader in enterprise application software, helping companies of all sizes and in all industries run at their best: 77% of the world’s transaction revenue touches an SAP system. Our end-to-end suite of applications and services enables our customers to operate profitably, adapt continuously, and make a difference. With a global network of customers, partners, employees, and thought leaders, SAP helps the world run better and improves people’s lives. ',
        date: 'June - Sep 2013',
        startYr: '2012',
        imageUrl: './images/works/sapkorea.png',
        location: 'Seoul, Korea',
        link: 'https://www.sap.com/index.html',
        role: 'SAP Engineer Intern',
        worksPageUrl: '',
        projects: []
      }
    ]
  }
];

export default ABOUT_DATA;
