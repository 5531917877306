import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
// import { fetchCollectionsStart } from './shop/shop.sagas';
// import thunk from 'redux-thunk';
import rootReducer from './root-reducer';
import rootSaga from './root-saga';

// import { fetchStillsStart } from './stills/stills.sagas';
// import { fetchAboutStart } from './about/about.sagas';

import { getFirestore } from 'react-redux-firebase';
import { getFirebase } from 'react-redux-firebase';

// console.log('process.env.NODE_ENV ', process.env.NODE_ENV)

// 	R E D U X - L O G G E R
// const middlewares = [logger];

// 	R E D U X - T H U N K
// const middlewares = [ thunk ];

// 	R E D U X - S A G A S
const sagaMiddleware = createSagaMiddleware();
const middlewares = [ sagaMiddleware ];

// 	L O G G E R S
if ( process.env.NODE_ENV === 'development' ) {
	middlewares.push( logger );
}

// 	C R E A T E   S T O R E
export const store = createStore(
	rootReducer, 
	applyMiddleware(...middlewares)
);
// console.log('store', store)
// console.log('store.getState()', store.getState())


/*  R U N   S A G A S  -->  
		- we no longer need async action like our fetch code in
			'stills.actions.js' :
			...
				export const fetchStillsStartAsync = () => {
				  return dispatch => {
				    const stillsRef = firestore.collection('stillsCollection');
				    console.log('stillsRef ', stillsRef)
				    //  makes 'isFetching: true'
				    dispatch(fetchStillsStart());

				    * * * * *  :  A S Y N C   R E Q U E S T  -->
				        - get our stills data from the backend, comes back,
				        - we build our 'stillsMap', 
				        - then make a 'fetchStillsSuccess' call.       
				    // stillsRef
				    //   .get()
				    //   .then(snapshot => {
				    //     const stillsMap = convertStillsSnapshotToMap(snapshot);
				    //     dispatch( fetchStillsSuccess(stillsMap));

				    //     // this.setState({ loading: false });
				    //   })
				    //   .catch(error => dispatch(fetchStillsFailure(error.message)));


				    // stillsRef.onSnapshot(async snapshot => {
				    //   console.log('snapshot BEFORE conversion : ', snapshot)

				    //   const stillsMap = convertStillsSnapshotToMap( snapshot )
				    //   console.log('stillsMap = snapshot AFTER conversion : ', stillsMap)
				    //   //  Now store this in the 'stills.reducer'
				      
				    //     E X P L A N A T I O N  -->  Adding it to reducer :
				    //       - dispatch :  updateStills action with the converted 
				    //                         stillsMap in it.
				    //       - updateStills in 'stills.actions' gets this.   
				    //   updateStills( stillsMap );

				    //   this.setState({ loading: false });
				    // })
				  }
				}
			...

		- we now moved all of this into a saga and then have the 
			saga listen for the start of that API Redux chain of events. 	*/
// console.log('R U N   S A G A S  -->  store.js')
sagaMiddleware.run(
	// fetchStillsStart,
	// fetchAboutStart,
	rootSaga 			// 	<-- uncomment once data in db
);


// 	P E R S I S T E D   S T O R E
export const persistor = persistStore(store);
export default { store, persistStore };




