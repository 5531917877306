import styled, { css } from 'styled-components';


// -------------------------------- ImagesContainerStyle
const defaultStyle = css`
  // border: 2px solid red;
  transform: translate( -200%, 0 );
`;

const activeStyle = css`
  // border: 2px solid blue;
  transform: translate( 25px, 0 );
`;

const conditionalStyles = props => {
  // console.log('conditionalStyles props ', props)

  return props.active ? activeStyle : defaultStyle;
};

export const PageDetailWrapper = styled.div`
  width: 400px;
  height: 450px;
  background: gray;
  // background: rgba(47, 79, 79, 0.9);
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  margin: auto;
  padding: 50px 25px 25px;
  position: fixed;
  transition: 0.3s;
  z-index: 10004;

  bottom: 25px;
  // left: 25px;

  @media screen and ( max-width: 550px ) {
    width: calc(100% - 50px);
  }

  ${ conditionalStyles }
`;










