import React from 'react';


const Projects = ({ brand, brandUrl, brandImg }) => (
  <div className="project">
    <a 
    	href={ brandUrl } 
    	target="_blank"
    	className="brand brandUrl hvr-sweep-to-right"
    >{ brand }</a>

    <img src={ brandImg } className="brandImg" />
  </div>
)

export default Projects;
