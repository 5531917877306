import ContactActionTypes from './contact.types';

const initState = {
	// contact_documents: [
	// 	{}
	// 	// { id: '1', email: 'alexhong1@gmail.com', message: 'message 1' },
	// 	// { id: '2', email: 'alexhong2@gmail.com', message: 'message 2' },
	// 	// { id: '3', email: 'alexhong3@gmail.com', message: 'message 3' },
	// ]
	contact_documents: null
};

const contactReducer = ( state = initState, action ) => {
  switch (action.type) {
  	// case ContactActionTypes.CREATE_PROJECT:
  	// 	console.log('action.project ', action.project)

	  //   return {
	  //     ...state,
	  //   }

	  // case ContactActionTypes.CREATE_PROJECT_START:
	  // 	console.log('action.payload ', action.payload)

	  //   return {
	  //     ...state,
	  //   }
	  // case ContactActionTypes.CREATE_PROJECT_SUCCESS:
	  //   return {
	  //     ...state,
	  //     contact_documents: action.payload,
	  //    }
	  case ContactActionTypes.CREATE_PROJECT_FAILURE:
	    return {
	      ...state,
	      errorMessage: action.payload
	     }

    default:
      return state;
  }
};

export default contactReducer;



/*

  // case ContactActionTypes.CREATE_PROJECT_START:
  // 	console.log('action.payload ', action.payload)

  //   return {
  //     ...state,
  //   }
  // case ContactActionTypes.CREATE_PROJECT_SUCCESS:
  //   return {
  //     ...state,
  //     contact_documents: action.payload,
  //    }
  // case ContactActionTypes.CREATE_PROJECT_FAILURE:
  //   return {
  //     ...state,
  //     errorMessage: action.payload
  //    }

*/