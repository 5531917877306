// import * as firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';


//  Firebase Database  :  alexhwebdev-9f914 (LIVE)
const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID
  // appId: process.env.FIREBASE_APP_ID,
};



firebase.initializeApp(config);


export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;

  // QueryReference / QuerySnapshot :
  // const userRef = firestore.doc(`users/testingalex1234`);
  const userRef = firestore.doc(`users/${userAuth.uid}`);
  const snapShot = await userRef.get();

  const collectionRef = firestore.collection(`users`);
  // console.log( 'collectionRef ', collectionRef );

  const collectionShapshot = await collectionRef.get();
  // console.log( 'collectionShapshot ', collectionShapshot );
  // console.log( 'collectionShapshot2 ', 
  //   { 
  //     collection: collectionShapshot.docs.map(
  //       doc => doc.data() 
  //     )
  //   }
  // );


  if (!snapShot.exists) {
    const { displayName, email } = userAuth;

    // 	find out when we made that document
    const createdAt = new Date();

    // 	do an asynchronous request to our DB to store this data.
    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        ...additionalData
      });
    } catch (error) {
      // console.log('error creating user', error.message);
    }
  }

  return userRef;
};



//----------------------------------------   A B O U T   D A T A
// 
// 
/*  A B O U T    D A T A    A D D E D   T O   F I R E B A S E   
  export const addAboutCollection = async ( 
    aboutCollectionKey, 
    objectsToAdd 
  ) => {
    const aboutRef = firestore.collection( aboutCollectionKey );
    console.log('addAboutCollection aboutRef --> firebase.utils', aboutRef)

    const batch = firestore.batch();

    objectsToAdd.forEach(obj => {
      //  Auto generated id in Firebase
      const newDocRef = aboutRef.doc();
      // console.log('newDocRef', newDocRef)

      batch.set(newDocRef, obj);
    });
    return await batch.commit();
  }

  export const convertAboutSnapshotToMap = aboutCollection => {
    console.log('S T E P - 5 : convertAboutSnapshotToMap -->  firebase.utils', aboutCollection)

    const transformedAbout = aboutCollection.docs.map( eachDocument => {
      // console.log('convertAboutSnapshotToMap eachDocument ', eachDocument.data());

      const { id, items, schools, companies, routeName, title } = eachDocument.data();
      // console.log('convertAboutSnapshotToMap location ', location);

      return {
        // routeName: encodeURI(location.toLowerCase()),
        id: id,
        items,
        schools,
        companies,
        routeName,
        title,
      };
    });

    // console.log('transformedAbout BEFORE reduce ', transformedAbout)
    return transformedAbout
  };
*/








//----------------------------------------   S T I L L S   D A T A
// 
// 
/*  S T I L L S    A D D E D   T O   F I R E B A S E   
    - Used in App.js
    - 157 & 158. Moving Our Shop Data To Firebase 2  
    - remove after loading the 'data.js' we want in Firebase

    - 'async / await' added to make it an asynchronous function.  */
export const addStillsCollection = async ( 
  stillsCollectionKey, 
  objectsToAdd 
) => {
  /*  E X P L A N A T I O N   -->
      - get back 'stillsRef' by passing in 'stillsCollectionKey' to 
        'firestore.collection'.   */
  const stillsRef = firestore.collection( stillsCollectionKey );
  console.log('addStillsCollection -----> stillsRef ', stillsRef)

  const batch = firestore.batch();

  /*  E X P L A N A T I O N   -->
      - loop over objectsToAdd array
      - 'forEach' is similar to map method except, forEach does not
        return new array, which is great bc we only want to call the
        function on each element. 

        1.  we'll get the 'obj',
        2.  create newDocRef, which tells Firebase to give me a new 
            documentReference in this Collection and randomly generate 
            an ID for me :
            
            ...
            const newDocRef = stillsRef.doc();
            ...   */
  objectsToAdd.forEach(obj => {
    /*  E X P L A N A T I O N   -->
        - The example code below makes the id, our title. 
        - We use empty .doc() method bc we want the id to be unique.
        
        // const newDocRef = stillsRef.doc( obj.title );   */
    //  Auto generated id in Firebase
    const newDocRef = stillsRef.doc();
    // console.log('newDocRef', newDocRef)


    //  * * * * *  -  S T O R E  D A T A
    //  - do an asynchronous request to our DB to store this data.
    batch.set(newDocRef, obj);
  });
  /*  E X P L A N A T I O N   -->
      - What returns back from calling commit is a promise.
      - When commit succeeds, it will come back and resolve
        a null value. This is useful bc if we call this function 
        somewhere else, we can chain off this function and then 
        call .then and do something.  */
  return await batch.commit();
}


/*  E X P L A N A T I O N   -->
    - once we are able to get snapshot in 'stills.component' :
      ...
      componentDidMount() {
        const stillsRef = firestore.collection('stillsCollection');
        stillsRef.onSnapshot(async snapshot => {
          console.log('snapshot ', snapshot)
        })
      }
      ...

    - we then transform the data to the way we want to use it.
      ...
      const transformedStills = stillsCollection.docs.map ...
      ...

      'docs' -  gives us our query snapshot array of the 
                'stillsCollection'
    
    - pull each data we need :
      ...
      const { id, title, items } = eachDocument.data();
      ...

    - then return the actual object we need :
      ...
      return {
        routeName: encodeURI(title.toLowerCase()),
        id: eachDocument.id,
        // id,
        title,
        items
      };
      ...   */
export const convertStillsSnapshotToMap = stillsCollection => {
  console.log('S T E P - 5 : convertStillsSnapshotToMap -->  firebase.utils', stillsCollection)

  const transformedStills = stillsCollection.docs.map( eachDocument => {
    // console.log('convertStillsSnapshotToMap eachDocument ', eachDocument.data());

    const { id, css, date, height, location } = eachDocument.data();
    // console.log('convertStillsSnapshotToMap location ', location);

    return {
      // routeName: encodeURI(location.toLowerCase()),
      id: id,
      css,
      // date,
      height,
      location,
    };
  });

  // console.log('transformedStills BEFORE reduce ', transformedStills)
  return transformedStills

  /*  E X P L A N A T I O N   -->
    - 161. Adding Shop Data To Redux :
    - 1:30 mark, detailed explanation of return code below.
    - In Short, it takes 'transformedStills' where the key is numbers :
      ...
      0: {routeName: "buckskin%20gulch", id: "1rWwJp9KybdoZalbV0Ke", title: "Buckskin Gulch", items: Array(5)}
      1: {routeName: "palm%20springs", id: "TJrdD7xFR7a6rBp6ody4", title: "Palm Springs", items: Array(5)}
      2: {routeName: "cottonwood", id: "UhSE8ZmlByYCJWhYYzxR", title: "Cottonwood", items: Array(5)}
      ...

    - and gives us the title as the key :
      ...
      big sur: {routeName: "big%20sur", id: "b2QNtfDh1LrNpfSL1aFO", title: "Big Sur", items: Array(5)}
      boulder utah: {routeName: "boulder%20utah", id: "aSdTzxwIatCq7fqiyvyk", title: "Boulder Utah", items: Array(5)}
      buckskin gulch: {routeName: "buckskin%20gulch", id: "1rWwJp9KybdoZalbV0Ke", title: "Buckskin Gulch", items: Array(5)}
      ...


    - 'return' added here as last thing to .reduce the final 
      object that comes out of our whole reduced statement, 
      out of the main 'convertStillsSnapshotToMap()' function

    '.reduce( accumulator, currentValue )'
  */


  // return transformedStills.reduce((accumulator, stillsDocument) => {
  //   console.log('transformedStills AFTER reduce ', 
  //     stillsDocument
  //     // stillsDocument.items.map( eachStill => {
  //     //   return eachStill.css
  //     //   // return eachStill.id
  //     //   // return Object.values( eachStill )
  //     // })
  //   )

  //   // accumulator[stillsDocument.location.toLowerCase()] = stillsDocument;
  //   accumulator[stillsDocument.id] = stillsDocument;

  //   //  return the accumulator so that it goes into the next iteration on our reduce()
  //   // console.log('accumulator ', accumulator)
  //   return accumulator;
  // }, {});   // {} : passing in the initial object.
};





//-----------------------------------------  C O N T A C T   F O R M  
//  -  Add submitted info from Contact Form to Firebase
// 
export const addContactFormInput = async (
  contactKey, 
  objectsToAdd
) => {
  const contactRef = firestore.collection( contactKey );
  // console.log( 'contactKey ', contactKey );
  // console.log( 'contactRef ', contactRef );
  console.log( 'objectsToAdd ', objectsToAdd );

  const batch = firestore.batch();
  objectsToAdd && objectsToAdd.forEach(obj => {
    // console.log('obj ', obj)

    const newDocRef = contactRef.doc();
    // console.log('newDocRef ', newDocRef)

    batch.set(newDocRef, obj);
  });

  return await batch.commit();
};
/*  NOT NEED UNTIL DATA IS PULLED TO FRONT END
  export const convertContactSnapshotToMap = contactCollection => {
    console.log('S T E P - 5 : convertContactSnapshotToMap -->  firebase.utils', contactCollection)

    const transformedContact = contactCollection.docs.map( eachDocument => {
      console.log('convertContactSnapshotToMap eachDocument ', eachDocument.data());

      const { id, name, email, message } = eachDocument.data();
      // console.log('convertContactSnapshotToMap location ', location);

      return {
        // routeName: encodeURI(location.toLowerCase()),
        id: id,
        name: name,
        email: email,
        message: message,
      };
    });

    // console.log('transformedContact BEFORE reduce ', transformedContact)
    return transformedContact
  };

*/






//---------------------------   C O L L E C T I O N S   D A T A
// 
// 
export const addCollectionAndDocuments = async (
  collectionKey, 
  objectsToAdd
) => {

  /*  E X P L A N A T I O N   -->
    - 159. Moving Our Shop Data To Firebase
    - Get back 'collectionRef' from 'firestore.collection', 
      and then pass in our 'collectionKey', and Firebase will
      give back a ref object no matter what.

    - Firebase creates this Ref Object for us and then if we 
      start adding elements to it as documents, then Firebase 
      will start creating both the collection and the documents 
      inside a Firestore.   */
  const collectionRef = firestore.collection( collectionKey );
  // console.log( 'collectionRef ', collectionRef );

  const batch = firestore.batch();
  objectsToAdd.forEach(obj => {
    // const newDocRef = collectionRef.doc(obj.title);

    // Tells Firebase to give me a new documentReference in this 
    // Collection and randomly generate an ID for me.
    const newDocRef = collectionRef.doc();
    // console.log('newDocRef ', newDocRef)

    batch.set(newDocRef, obj);
  });

  return await batch.commit();
};


/*  E X P L A N A T I O N   -->
    - 160. Bringing Shop Data To Our App  */
export const convertCollectionsSnapshotToMap = collections => {
  const transformedCollection = collections.docs.map(doc => {
    const { title, items } = doc.data();

    return {
      routeName: encodeURI(title.toLowerCase()),
      id: doc.id,
      title,
      items
    };
  });

  // console.log('transformedCollection ', transformedCollection)

  //  'return' added here as last thing to .reduce the final object that comes out of our whole reduced statement, out of the main 'convertCollectionsSnapshotToMap()' function
  return transformedCollection.reduce((accumulator, collection) => {
    accumulator[collection.title.toLowerCase()] = collection;
    
    //  return the accumulator so that it goes into the next iteration on our reduce()
    return accumulator;
  }, {});   // {} : passing in the initial object.
};

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(userAuth => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
};

// 	Export these out to anywhere that we need to use anything related 
// 	to authentication.
export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const googleProvider = new firebase.auth.GoogleAuthProvider();
// 	We want to always trigger the Google popup, whenever we use the GoogleAuthProvider for authentication and sign-in.
googleProvider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);

export default firebase;






/*------------------------------------------------------ N O T E S


'.reduce( accumulator, currentValue )' :
  - The array reduce() method in JavaScript is used to reduce the 
    array to a single value and executes a provided function for 
    each value of the array (from left-to-right) and the return 
    value of the function is stored in an accumulator.

  - The reduce() method executes a reducer function (that you provide) 
    on each element of the array, resulting in a single output value.


'queryReference and querySnapshot' :
  - https://www.udemy.com/course/complete-react-developer-zero-to-mastery/learn/lecture/15083436#overview
  - 5:36 mark

  CHECK NOTES.js : 
    - Section 16: Master Project: Advanced Redux + Firebase










**************************************  S E C T I O N  16
Section 16: Master Project: Advanced Redux + Firebase



  S E C T I O N   16   ------------------------------------
  157. Firebase Refresher

    - this lesson learn about :
      + 'queryReferences objects'
      + 'snapshot objects'
      + 'security'

    - 'query' : a request we make to FireStore to give us 
      something from the DB.

      - FireStore returns us 2 types of objects :
        + queryReferences and
        + querySnapshots

        - of these objects, they can be either Document or 
          Collection versions.

        - Firestore will always return us these objects, 
          even if nothing exists at from that query.


    'queryReferences object' :
      - is an object that represents the "current" place in
        the DB that we are querying.

      - we get them by calling either :
        + firestore.doc('/users/:usersId');
        + firestore.collections('/users');

      - The 'queryReferences object' does not have the actual 
        data of the 'Collection' or 'Document'. It instead has 
        properties that tell us details about it, or the method
        to get the 'Snapshot object' which gives us the data we
        are looking forr.


        'DocumentReference vs CollectionReference' :
          'documentRef objects' :
            - used to perform our CRUD methods : 
              + create, 
              + retrieve, 
              + update, 
              + delete

            - The documentRef methods are :
              + .set(),           // create
              + .get(),           // retrieve
              + .update(), and    // update
              + .delete()         // delete

          
          'collectionRef Objects' :
            - to add Documents to Collections using .add()
            //  collectionRef.get() or collectionRef.get()


          - 'documentRef' returns a 'documentSnapshot object'.
          - 'collectionRef' returns a 'querySnapshot object'.

        
        * * * * * - EXPLANATION
          - 6 min mark : 
          - explanation of the whole authentication process


        'documentSnapshot object' :
          - get from 'DocumentReference object'
          - allows us to check if a document exists at this query
            using the .exists() property which returns boolean.
          - also get actual properties on the object by calling
            .data() method, which returns a JSON object of document.

        'querySnapshot object' :
          - Next Lesson



  S E C T I O N   16   ------------------------------------
  158. Firebase Refresher 2

    * * * * * - EXPLANATION

    'querySnapshot object' :
      - get from 'CollectionReference object'
      - we can check if there are any documents in the collection by 
        calling the .empty() property which returns a boolean.
      - we can get all the documents in the collection by calling the 
        .docs() property, which returns an array of our documents as
        'documentSnapshot objects'.


    - R E W A T C H  forr explanation on how authentication process 
      is stored inside Firebase



  S E C T I O N   16   ------------------------------------
  159. Moving Our Shop Data To Firebase

    * * * * * - EXPLANATION
    - R E W A T C H  forr explanation on moving the Shop Data



  S E C T I O N   16   ------------------------------------
  160. Moving Our Shop Data To Firebase 2

    * * * * * - EXPLANATION
    - R E W A T C H  forr explanation on batch process and how we 
      got the 'shop.data.js' into 'Collections' in our Firebase.

      - after initially adding the 'shop.data.js' into 'Collections',
        we need to remove the code in our 'App.js' :


    * * * * * - EXPLANATION
    - To Add data to Firebase w/ files like 'shop.data.js' :
      1. create a ref doc obj
      2. set it using '.set()'

    '.set()' :
      - Firestore can only make 1 '.set()' call at a time.
      - So iff we lose wifi whilee many '.set()' calls are being 
        called, only some will get into the Firebase.
      - So 'batch' it. We put all out sets into this and fire it
        off all at once.


    'batch' :
      - is a way to batch or group all our calls together into one 
        big request so that all the Data is moved all together or
        none at all.

        E X A M P L E :
        - 'firebase.utils.js'
        ...
        // const batch = firestore.batch();
        // objectsToAdd.forEach(obj => {
        //   const newDocRef = collectionRef.doc();
        //   batch.set(newDocRef, obj);
        // });
        ...


        '.forEach()' :
          - does not return us a new array like '.map()' does.
          - only calls function on each element



  S E C T I O N   16   ------------------------------------
  161. Reviewing What We Have Done

    - visual representation of what we are trying to do when 
      adding new data.



  S E C T I O N   16   ------------------------------------
  162. Bringing Shop Data To Our App

    * * * * * - EXPLANATION
    - R E W A T C H  forr explanation on transforming data after 
      retrieving it from Firebase.



  S E C T I O N   16   ------------------------------------
  163. Adding Shop Data To Redux

    * * * * * - EXPLANATION
    - R E W A T C H

    * * * * * - 
    FILE : 'firebase.utils.js'
    - User Data Object shaped and received from the Backend, need
      to convert to actual object map to store to Reducer by using
      .reduce()

      '.reduce( accumulator, currentValue )' :
        - The array reduce() method in JavaScript is used to reduce the array to 
          a single value and executes a provided function for each value of the 
          array (from left-to-right) and the return value of the function is 
          stored in an accumulator.

        - The reduce() method executes a reducer function (that you provide) on 
          each element of the array, resulting in a single output value.


    LESSON STARTS W/ :
    - now we have actual objects that we need from BackEnd in the 
      shape that we need them to be forr the FrontEnd


    * * * * * - EXPLANATION
    F I L E : 'firebase.utils.js'

    - 1:45 min mark
    - explanation of the whole step of converting data

    E X A M P L E :
      export const convertCollectionsSnapshotToMap = collections => {
        const transformedCollection = collections.docs.map(doc => {
          const { title, items } = doc.data();

          return {
            routeName: encodeURI(title.toLowerCase()),
            id: doc.id,
            title,
            items
          };
        });

        console.log('transformedCollection ', transformedCollection)

        //  'return' added here as last thing to .reduce the final object that comes out of our whole reduced statement, out of the main 'convertCollectionsSnapshotToMap()' function
        return transformedCollection.reduce((accumulator, collection) => {
          accumulator[collection.title.toLowerCase()] = collection;
          
          //  return the accumulator so that it goes into the next iteration on our reduce()
          return accumulator;
        }, {});   // {} : passing in the initial object.
      };



    * * * * * - EXPLANATION
    F I L E : 'firebase.utils.js'

    - 4 min mark
    - explanation of the whole converted data into FrontEnd :

      'shop.types.jsx'  >  'shop.actions.jsx'  >  
      'shop.reducer.jsx'  >  'shop.component.jsx'





*/





