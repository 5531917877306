import React, { Component } from 'react';
import * as Animated from 'animated/lib/targets/react-dom';


const ProgressBar = ({ amountToScroll }) => {
  const scrollProgressBar_style = { 
    transform: [ 
      { translateY: amountToScroll }
    ]
  }
  return (
    <div className="progressBar__wrapper">
      <Animated.div 
        className="progressBar"
        style={ scrollProgressBar_style }
      >
        {/*<div className="highlightBar"></div>*/}
      </Animated.div>
    </div>
  );
}

export default ProgressBar;