import STILLS_DATA from './stills.data';
import StillsActionTypes from './stills.types';
// import visibilityFilter from './visibilityFilter'


const INITIAL_STATE = {
	// ADD_DATA_TO_DB  -  UNCOMMENT to load data to firebase :
	// stills_documents: STILLS_DATA,
  stills_documents: null,
  isFetching: false,
  errorMessage: undefined,
  visibleState: 'FETCH_STILLS_SUCCESS',
};

const stillsReducer = (state = INITIAL_STATE, action) => {
  // console.log('action ', action)
  // console.log('action.payload ', action.payload)

  switch (action.type) {

    case StillsActionTypes.SET_VISIBILITY_FILTER:
      return {
        ...state,
        visibleState: action.payload,
      }

    case StillsActionTypes.FETCH_STILLS_START:
      return {
        ...state,
        isFetching: true
       }
    case StillsActionTypes.FETCH_STILLS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        stills_documents: action.payload,
      }
    case StillsActionTypes.FETCH_STILLS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      }

    case StillsActionTypes.UPDATE_STILLS:
      /*  E X P L A N A T I O N  -->
          - returns us a new object with the 'state...' and the 
            'stills_documents' will go to our new payload like so :
              ...
              stillsRootReducer:
                errorMessage: undefined
                isFetching: false
                stills_documents: {buckskin gulch: {…}, …}
                stillsDocument: null
              ...   */
      return {
        ...state,
        stills_data: action.payload
      }

    default:
      return state;
  }
};

export default stillsReducer;




