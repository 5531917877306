import React from 'react';
import { Link } from 'react-router-dom';
import './global-home-btn.styles.scss';


const GlobalHomeBtn = () => {



  return (
    <div className="globalHomeBtn">
      <Link
        to={{
          pathname: '/',
          state: { isLoading: true },
        }}
        href="/"
      >

        <div className="texts__container">
          <span className="text">a</span>
          <span className="text">l</span>
          <span className="text">e</span>
          <span className="text">x</span>
          <span className="text">h</span>
          <span className="text">o</span>
          <span className="text">n</span>
          <span className="text">g</span>
        </div>
      </Link>
  	</div>
  )
}


export default GlobalHomeBtn;
