import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';


// ------------------------- MenuComponent_style
const menuClosed = css`  
  width: 0px;
  opacity: 0;
`;
const menuOpen = css`
  width: 500px;
  opacity: 1;

  @media screen and ( max-width: 750px ) {
    width: 100vw;
  }
`;

const imagesContainerStyle = props => {
  // console.log('imagesContainerStyle props ', props)
  return props.menuOpen ? menuOpen : menuClosed;
};

export const MenuComponent_style = styled.div`
  height: 25px;
  // border: 2px solid red;
  // background: rgba( 0, 0, 0, 1);

  overflow: hidden;
  position: fixed;
  transition: all .3s ease-in-out;
  z-index: 10000;

  top: 19px;
  right: 0;


  .menuList {
    height: 100%;
    display: flex;
    position: relative;

    a.navBtn {
      align-items: center;
      display: flex;
      font-family: 'Montserrat',sans-serif !important;
      font-size: 15px;
      letter-spacing: 1px;
      margin: 0 15px;
      text-transform: uppercase;

      &::after {
        color: gray;
        content: '/';
        display: block;
        position: relative;
        left: 15px;
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }

      span {
        color: gray;

        &:hover {
          color: #a3734c;
        }
      }
    }
  }


  @media screen and ( max-width: 750px ) {
    height: 100vh;
    background: rgba( 0, 0, 0, 0.9);
    top: 0;

    .menuList {
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 65px 30px 150px;

      a.navBtn {
        line-height: 55px;
        position: relative;

        span {
          color: white;
          font-size: 15px;
          letter-spacing: 3px;
          transition: 0.3s;
        }

        &::after {
          width: 10px;
          height: 1px;
          background: silver;
          color: gray;
          content: '';
          display: block;
          margin: auto;
          position: absolute;
          top: 50px;
          bottom: 0;
          left: 0;
        }        
      }
    }
  }


  ${ imagesContainerStyle }
`;

















// -------------------- nav-menu-btns.component.jsx
export const HeaderContainer = styled.div`
  // border: 2px solid red;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 0;
  z-index: 9999;
`;

export const LogoContainer = styled(Link)`
  height: 100%;
  width: 70px;
  padding: 25px;
`;

export const OptionsContainer = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  // justify-content: flex-end;
`;

export const OptionLink = styled(Link)`
  padding: 10px 15px;
  cursor: pointer;
  color: darkslategray;
  font-family: 'Montserrat', sans-serif !important;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
`;



