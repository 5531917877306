import ContactActionTypes from './contact.types';
import { firestore } from '../../firebase/firebase.utils';

export const createProjectStart = userCredentials => ({
  type: ContactActionTypes.CREATE_PROJECT_START,
  payload: userCredentials
});
export const createProjectSuccess = ({ name, email, message }) => ({
  type: ContactActionTypes.CREATE_PROJECT_SUCCESS,
  payload: { name, email, message }
});
export const createProjectFailure = errorMessage => ({
  type: ContactActionTypes.CREATE_PROJECT_FAILURE,
  payload: errorMessage
});


export const createProject = ( project ) => ({
  type: ContactActionTypes.CREATE_PROJECT,
  // payload: project
});





// 	EXAMPLE FROM - https://www.youtube.com/watch?v=apg98RIJfJo&list=PL4cUxeGkcC9iWstfXntcj8f-dFZ4UtlN3&index=14
// export const createProject = ( project ) => {
//   return ( dispatch, getState ) => {
//   	// make async call to database
//   	dispatch({ type: 'CREATE_PROJECT', project });
//   }
// };




