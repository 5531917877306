import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectDirectorySections } from '../../redux/directory/directory.selectors';
import MenuItem from '../menu-item/menu-item.component';

import './directory.styles.scss';


const Directory = ({ sections }) => (
  <div className='directory-menu'>
    {
	    sections.map(({ id, ...otherSectionProps }) => (
	      <MenuItem key={id} {...otherSectionProps} />
	    ))
  	}
  </div>
);

const mapStateToProps = createStructuredSelector({
  sections: selectDirectorySections
});

export default connect(mapStateToProps)(Directory);






/*------------------------------------------------------ N O T E S


	'createStructuredSelector' :
	- takes an object whose properties are input-selectors and 
		returns a structured selector. 
	- The structured selector returns an object with the same keys 
		as the inputSelectors argument, but with the selectors replaced 
		with their values.


*/







/*------------- B E F O R E  :  125. Directory State Into Redux

	import React from 'react';
	import MenuItem from '../menu-item/menu-item.component';
	import './directory.styles.scss';

	class Directory extends React.Component {
		constructor() {
			super();

			this.state = {
				sections: [{
			    title: 'hats',
			    imageUrl: 'https://i.ibb.co/cvpntL1/hats.png',
			    id: 1,
			    linkUrl: 'shop/hats'
			  },
			  {
			    title: 'jackets',
			    imageUrl: 'https://i.ibb.co/px2tCc3/jackets.png',
			    id: 2,
			    linkUrl: 'shop/jackets'
			  },
			  {
			    title: 'sneakers',
			    imageUrl: 'https://i.ibb.co/0jqHpnp/sneakers.png',
			    id: 3,
			    linkUrl: 'shop/sneakers'
			  },
			  {
			    title: 'womens',
			    imageUrl: 'https://i.ibb.co/GCCdy8t/womens.png',
			    size: 'large',
			    id: 4,
			    linkUrl: 'shop/womens'
			  },
			  {
			    title: 'mens',
			    imageUrl: 'https://i.ibb.co/R70vBrQ/men.png',
			    size: 'large',
			    id: 5,
			    linkUrl: 'shop/mens'
			  }]
			}
		}

		render() {

			return (
				<div className='directory-menu'>
					{
						// 	Non Destructured
							// this.state.sections.map( section => (
							// 	<MenuItem 
							// 		key={ section.id }
							// 		title={ section.title }
							// 	/>
							// ))

						// 	Destructured method to avoid above
							// this.state.sections.map( ({ title, imageUrl, id, size, linkUrl }) => (
							// 	<MenuItem 
							// 		key={ id }
							// 		title={ title }
							// 		imageUrl={ imageUrl }
							// 		size={ size }
							// 		linkUrl={ linkUrl }
							// 	/>
							// ))

						// 	Equivalent to above
		        this.state.sections.map(({ id, ...otherSectionProps }) => (
		          <MenuItem key={id} {...otherSectionProps} />
		        ))
					}
				</div>
			)
		}
	}

	export default Directory
*/
















