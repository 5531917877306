const UserActionTypes = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',

	GOOGLE_SIGN_IN_START: 'GOOGLE_SIGN_IN_START',
	EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',

	/*  E X P L A N A T I O N  -->
		...
		GOOGLE_SIGN_IN_SUCCESS: 'GOOGLE_SIGN_IN_SUCCESS',
		GOOGLE_SIGN_IN_FAILURE: 'GOOGLE_SIGN_IN_FAILURE',
		EMAIL_SIGN_IN_SUCCESS: 'EMAIL_SIGN_IN_SUCCESS',
		EMAIL_SIGN_IN_FAILURE: 'EMAIL_SIGN_IN_FAILURE',
		...

		- instead of th code above, we can compile it to the code below
			bc the 2 functions is very similar in 'user.sagas.js': 

				signInWithGoogle()
				signInWithEmail() 			*/
	SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
	SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',

  CHECK_USER_SESSION: 'CHECK_USER_SESSION',

  SIGN_OUT_START: 'SIGN_OUT_START',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',

  SIGN_UP_START: 'SIGN_UP_START',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE'
};

export default UserActionTypes;