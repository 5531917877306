import React from 'react';
import FilterLink from './filter-link.container';


const LinksWrapper = () => (
  <p className="FilterLink__wrapper" >
    <FilterLink className="FilterLink"> 
      FILTER
    </FilterLink>

    <FilterLink 
      className="FilterLink"
      filter="SHOW_ALL"
    > All </FilterLink>

    <FilterLink 
      className="FilterLink"
      filter="SHOW_BIGSUR"
    > Big Sur </FilterLink>

    <FilterLink 
      className="FilterLink"
      filter="SHOW_COTTONWOOD"
    > Cottonwood </FilterLink>

    <FilterLink 
      className="FilterLink"
      filter="SHOW_SEQUOIA"
    > Sequoia </FilterLink>

    <FilterLink 
      className="FilterLink"
      filter="SHOW_UTAH"
    > Utah </FilterLink>

    <FilterLink 
      className="FilterLink"
      filter="SHOW_YOSEMITE"
    > Yosemite </FilterLink>
  </p>
);

export default LinksWrapper;
