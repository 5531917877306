import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectIsCollectionFetching } from '../../redux/shop/shop.selectors';
import WithSpinner from '../with-spinner/with-spinner.component';
import CollectionsOverview from './collections-overview.component';

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsCollectionFetching
});

/*  E X P L A N A T I O N  -->
		- 171. Container Pattern :
		- Same as code below :
		...
		const CollectionsOverviewContainer =
		connect(mapDispatchToProps)(WithSpinner(CollectionsOverview))
		... 	

		'compose' :
		- look at notes at bottom,
		- in summary, it passes 'CollectionsOverview' inside
			'WithSpinner' and passes that to connect(mapStateToProps) 	*/
const CollectionsOverviewContainer = compose(
  connect(mapStateToProps),
  WithSpinner
)(CollectionsOverview);

export default CollectionsOverviewContainer;





/* -------------------------------------------- N O T E S






'createStructuredSelector' :
	- 115. User Selectors :
	- 3 min mark
	- takes an object whose properties are input-selectors and 
		returns a ''structured selector''. 
	- The 'structured selector' returns an object w/ the same keys 
		as the inputSelectors argument, but w/ the selectors replaced 
		w/ their values.



'connect()' : 
	- is going to allow us to 'connect' the store to all of the components that make up our application.
  - connects your component to the redux store.
  - what we get back from this is the function, and need to call that with the component
  - connect( *** what goes in here - information about what we want to connect *** );

	EXAMPLE :
		connect( what we want to connect, what information from the store we want to access )( the component )
		connect( [ mapStateToProps ], [mapDispatchToProps ], [ mergeProps ], [ options ])( the component )  



'compose()' : 
	- 'compose' lets us pass both connected(mapStateToProps), 
		and a Component into a Component of itself together.
		It essentially carries all of our functions together

		E X A M P L E  :  'collections-overview.container.jsx'
		...
		// const mapStateToProps = createStructuredSelector({
		//   isLoading: selectIsCollectionFetching
		// });

		// const CollectionsOverviewContainer = compose(
		//   connect(mapStateToProps),
		//   WithSpinner
		// )(CollectionsOverview);
		...

	- from Redux, lets us pass functions within functions, it 
		carries our functions together.
	- 'compose' evalutes from right to left, so it evalutes
		WithSpinner by passing : 
		CollectionsOverview into > WithSpinner, then 
		CollectionsOverview into > connect(mapStateToProps) 

		E X A M P L E  :  'collections-overview.container.jsx'
		...
		// const mapStateToProps = createStructuredSelector({
		//   isLoading: selectIsCollectionFetching
		// });

		// const CollectionsOverviewContainer = compose(
		//   connect(mapStateToProps),
		//   WithSpinner
		// )(CollectionsOverview);
		...



*/


