import React from 'react';
import './works-desc.styles.scss';


const WorksInfo = ({ role, jobDescs }) => {
	const description =  Object.keys( jobDescs ).map( (key) => {
		return (
			<li key={ jobDescs[key] } className="jobdesc">
				{ jobDescs[key] }
			</li>
		)
	})

	return (
	  <div className="jobDescs__wrapper">
	    <h4 className="role">
	    	{ role }
	    </h4>
	    <ul className="jobDescs">
	    	{ description }
	    </ul>
	  </div>
	)
}

export default WorksInfo;
