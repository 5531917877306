import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import IntroComponent from '../intropage/intropage.component';
import ImgFadeSliderComponent from './ImageFadeSlider.component';
import WorksEduPreview from './works-education/works-edu-preview.component';
import SkillsSection from './skills/skills.component';
import AboutFooter from './footer/about-footer.component';

import BkgdPageTitle from '../../components/bkgd-page-title/bkgd-page-title.component';
import BackToTop from '../../components/back-to-top/back-to-top.component';
import FixedEmailMeBtn from '../../components/fixed-emailme-btn/fixed-emailme-btn.component';
import AnimateUp from '../page-enter-leave/onmount-animate-up.component';
import AnimateRight from '../page-enter-leave/onmount-animate-right.component';
import AnimateDelay from '../page-enter-leave/onmount-animate-delay.component';

import ABOUT_DATA from './about.data.js';
import { ReactComponent as Linkedin } from '../../assets/linkedin.svg';
import './aboutpage.styles.scss';
import './aboutpage-mount-unmount.styles.scss';
import '../../styles/btn-hover-effects.scss';
// import { fetchLoaderStart } from '../../redux/stills/stills.actions';
// import { select_loaderFetching } from '../../redux/stills/loader.selectors';


class AboutPage extends Component {
  constructor( props ) {
    super( props )
    this.state = {
    	pageTitle: 'About',
    	numberOfImgs: [],
      getSchoolAndCompany: [],
      arrayOfImgs: [],
      slidePosition: 0,

      introData: [],
      skillsItemsArray: [],
    }
    this.ref_imgFadeSlider = React.createRef();
    
    this.updateState = this.updateState.bind(this);
    this.aboutIntro__prevBtn = this.aboutIntro__prevBtn.bind(this);
    this.aboutIntro__nextBtn = this.aboutIntro__nextBtn.bind(this);
  };

  updateState = ( getSlidePosition ) => {
    let slidePosition = getSlidePosition;

    this.setState({
      slidePosition: slidePosition
    });
  };

  aboutIntro__prevBtn = () => {
		this.ref_imgFadeSlider.current.prevSlide( -1 );

    let slidePosition = this.state.slidePosition - 1;
    let numberOfImgs = this.state.numberOfImgs - 1;

    if ( slidePosition === -1 ) {
      this.updateState( numberOfImgs );
    } else {
      this.updateState( slidePosition );
    }
  };

  aboutIntro__nextBtn = () => {
		this.ref_imgFadeSlider.current.nextSlide( 1 );

    let slidePosition = this.state.slidePosition + 1;
    let numberOfImgs = this.state.numberOfImgs;

    if ( slidePosition >= numberOfImgs ) {
      this.updateState(0);
    } else {
      //  If you want a function to be executed after the state change occurs, pass it in as a callback.
      // this.setState({ slidePosition: slidePosition }, function () {
        // this.trigger_AmountToScroll( slidePosition );
      // });
      this.updateState( slidePosition );
    }
  };

  componentDidMount = () => {
  	// 	INTRO SECTION > SCROLL AFTER 6s
		setTimeout(() => {
	    document.querySelector('.AboutIntro__Wrapper').scrollIntoView({
	    	behavior: 'smooth',
	    	block: 'start'
	    });
		}, 6000)


  	// 	INTRO DATA
		let introData = ABOUT_DATA.filter( obj => {
		  return obj.routeName === 'intro';
		})
		let itemsArray = introData[0].items[0]
		let introImgs = itemsArray.introImgs;


		// 	SKILLS DATA
		let skillsData = ABOUT_DATA.filter( obj => {
		  return obj.routeName === 'skills';
		})
		let skillsItemsArray = skillsData[0].items[0]

		this.setState({
			numberOfImgs: introImgs.length,
			skillsItemsArray: skillsItemsArray
		})
  }


	render() {
		let introData = ABOUT_DATA.filter( obj => {
		  return obj.routeName === 'intro';
		})
		let itemsArray = introData[0].items[0]
		let introTitle = itemsArray.title;
		let introSubTitle = itemsArray.subtitle;
		let introFirstPara = itemsArray.firstPara;
		let introSecondPara = itemsArray.secondPara;
		let introImgs = itemsArray.introImgs;

		return (
	    <div className="About__Container">
				<FixedEmailMeBtn></FixedEmailMeBtn>

	    	<AnimateDelay
    			durationOnMount={ 600 } 
    			// delayOnMount={ 3800 }
    			delayOnMount={ 3500 }
	    	>
					<IntroComponent></IntroComponent>

		    	{/*  A B O U T __ I N T R O  */}
		    	<div className="AboutIntro__Wrapper">

		    		{/*  ABOUT ABOUT ABOUT  */}
	    			<BkgdPageTitle
	    				title={ this.state.pageTitle }
	    				delay={ 9000 }
	    			></BkgdPageTitle>

		    		<div className="silverLine"></div>

				    <div className="AboutIntroContent__Wrapper">
				    	<div className="fadeSliderAndBtns__wrapper">
				    		<AnimateUp 
				    			durationOnMount={ 600 } 
				    			delayOnMount={ 6800 }
				    		>
				    			{/*  <h6>...</h6>  */}
						    	<ImgFadeSliderComponent
						    		ref={ this.ref_imgFadeSlider }
						    		arrayOfImgs={ introImgs }
						    		slidePosition={ this.state.slidePosition }
						    	/>
				    		</AnimateUp>


			          {/*  PREV / NEXT BTNS  */}
			          <div className="AboutIntroBtn__Wrapper">
			          	<AnimateUp 
					    			durationOnMount={ 600 } 
					    			delayOnMount={ 8100 }
			          	>
				            <div className="aboutIntro__prevBtn"
				              onClick={ this.aboutIntro__prevBtn }
				            ></div>

				            <div className="imgCount"> 
				            	{ this.state.slidePosition + 1 } / { this.state.numberOfImgs }
				            </div>

				            <div className="aboutIntro__nextBtn"
				              onClick={ this.aboutIntro__nextBtn }
				            ></div>
									</AnimateUp>
			          </div>
				    	</div>


				    	<div className="AboutMe__Title"> 
				    		<AnimateRight 
				    			durationOnMount={ 600 } 
				    			delayOnMount={ 7600 }
				    		>
					    		<h6>{ introSubTitle }</h6>
				    		</AnimateRight>
				    		<AnimateRight 
				    			durationOnMount={ 600 } 
				    			delayOnMount={ 7900 }
				    		>
					    		<h1>{ introTitle }</h1>
				    		</AnimateRight>
				    	</div>


							{/*  ABOUT INTRO CONTENT  */}
				    	<div className="aboutIntroContent">
		          	<AnimateUp 
				    			durationOnMount={ 600 } 
				    			delayOnMount={ 8500 }
		          	>
							    <div className="SectionTitle">
							    	<h6>About Me</h6>
							    </div>
							  </AnimateUp>

		          	<AnimateUp 
				    			durationOnMount={ 600 } 
				    			delayOnMount={ 8300 }
		          	>
						    	<p>{ introFirstPara }</p>
						    	<p>{ introSecondPara }</p>
							  </AnimateUp>
				    	</div>
				    </div>
		    	</div>


					{/*  W O R K S  /  E D U  */}    		
		    	<div className="AboutWorks__Wrapper">
						<WorksEduPreview
							// ref={ this.ref_imgFadeSlider } 
							// select_AboutDocuments={ select_AboutDocuments }
						></WorksEduPreview>
					</div>


					{/*  S K I L L S  */}
					<div className="AboutSkills__Wrapper">
						<div className="silverLine_skillsSection"></div>
						
						<SkillsSection
							skillsItemsArray={ this.state.skillsItemsArray }
						></SkillsSection>
					</div>


					{/*  W O R K S  /  S T I L L S   P R E V I E W  */}
					<div className="AboutWorksStills__Wrapper">
						<div className="worksPreview__container">
		          <Link className="link link--kukuri" 
		            to={{
		              pathname: '/works',
		              state: { isLoading: true },
		            }}
		            href="/works"
		            data-letters="Works"
		          >Works</Link>

							<img className="worksPreview__img" src="./images/about/about_works.jpg" />
						</div>

						<div className="stillsPreview__container">
		          <Link className="link link--kukuri" 
		            to={{
		              pathname: '/stills',
		              state: { isLoading: true },
		            }}
		            href="/stills"
		            data-letters="Stills"
		          >Stills</Link>
							<img className="worksStills__img" src="./images/about/about_stills.jpg" />
						</div>
					</div>


					{/*  SOCIAL / EMAIL  */}
					<div className="AboutSocial__Wrapper">
						<AnimateUp durationOnMount={ 500 }>
							<a href="https://www.linkedin.com/in/young-alex-hong/" target="_blank">
								<Linkedin />
							</a>
							<a href="mailto:alexhwebdev@gmail.com?subject = Feedback&body = Message">
								alexhwebdev@gmail.com
							</a>							
						</AnimateUp>
					</div>

				{/*  ABOUT FOOTER  */}
					<AboutFooter></AboutFooter>
	    	</AnimateDelay>
	    </div>
		)
	}
};

const mapStateToProps = createStructuredSelector({
  // select_loaderFetching: select_loaderFetching
});
const mapDispatchToProps = dispatch => ({
  // fetchLoaderStart: () => dispatch( fetchLoaderStart() ),
});

export default connect( 
  mapStateToProps,
  mapDispatchToProps
)( AboutPage );




