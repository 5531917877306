import React, { useState } from 'react';
import { connect } from 'react-redux';

import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';

import { signUpStart } from '../../redux/user/user.actions';

import { SignUpContainer, SignUpTitle } from './sign-up.styles';
// import { auth, createUserProfileDocument } from '../../firebase/firebase.utils';


/*  E X P L A N A T I O N  -->  before 193. Converting Class Components With useState :
  class SignUp extends React.Component {
    constructor() {
      super();

      this.state = {
        displayName: '',
        email: '',
        password: '',
        confirmPassword: ''
      };
    }

    handleSubmit = async event => {
      event.preventDefault();
      const { signUpStart } = this.props;
      const { displayName, email, password, confirmPassword } = this.state;

      if (password !== confirmPassword) {
        alert("passwords don't match");
        return;
      }

      E X P L A N A T I O N  -->
          // try {
          //   const { user } = await auth.createUserWithEmailAndPassword(
          //     email,
          //     password
          //   );

          //   await createUserProfileDocument(user, { displayName });

          //   this.setState({
          //     displayName: '',
          //     email: '',
          //     password: '',
          //     confirmPassword: ''
          //   });
          // } catch (error) {
          //   console.error(error);
          // }
      signUpStart({ displayName, email, password });
    };

    handleChange = event => {
      const { name, value } = event.target;

      this.setState({ [name]: value });
    };

    render() {
      const { displayName, email, password, confirmPassword } = this.state;
      return (
        <SignUpContainer>
          <SignUpTitle>I do not have a account</SignUpTitle>
          <span>Sign up with your email and password</span>
          <form className='sign-up-form' onSubmit={this.handleSubmit}>
            <FormInput
              type='text'
              name='displayName'
              value={ displayName }
              onChange={ this.handleChange }
              label='Display Name'
              required
            />
            <FormInput
              type='email'
              name='email'
              value={ email }
              onChange={ this.handleChange }
              label='Email'
              required
            />
            <FormInput
              type='password'
              name='password'
              value={ password }
              onChange={ this.handleChange }
              label='Password'
              required
            />
            <FormInput
              type='password'
              name='confirmPassword'
              value={ confirmPassword }
              onChange={ this.handleChange }
              label='Confirm Password'
              required
            />
            <CustomButton type='submit'>SIGN UP</CustomButton>
          </form>
        </SignUpContainer>
      );
    }
  }
*/
const SignUp = ({ signUpStart }) => {
  const [userCredentials, setUserCredentials] = useState({
    displayName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const { displayName, email, password, confirmPassword } = userCredentials;

  const handleSubmit = async event => {
    event.preventDefault();

    if (password !== confirmPassword) {
      alert("passwords don't match");
      return;
    }

    signUpStart({ displayName, email, password });
  };

  const handleChange = event => {
    const { name, value } = event.target;

    setUserCredentials({ ...userCredentials, [name]: value });
  };

  return (
    <SignUpContainer>
      <SignUpTitle>I do not have a account</SignUpTitle>
      <span>Sign up with your email and password</span>
      <form className='sign-up-form' onSubmit={ handleSubmit }>
        <FormInput
          type='text'
          name='displayName'
          value={ displayName }
          onChange={ handleChange }
          label='Display Name'
          required
        />
        <FormInput
          type='email'
          name='email'
          value={ email }
          onChange={ handleChange }
          label='Email'
          required
        />
        <FormInput
          type='password'
          name='password'
          value={ password }
          onChange={ handleChange }
          label='Password'
          required
        />
        <FormInput
          type='password'
          name='confirmPassword'
          value={ confirmPassword }
          onChange={ handleChange }
          label='Confirm Password'
          required
        />
        <CustomButton type='submit'>SIGN UP</CustomButton>
      </form>
    </SignUpContainer>
  );
};

const mapDispatchToProps = dispatch => ({
  signUpStart: userCredentials => dispatch(signUpStart(userCredentials))
});

export default connect(
  null,
  mapDispatchToProps
)(SignUp);



