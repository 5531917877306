import { takeEvery, takeLatest, call, put, all } from 'redux-saga/effects';
import { firestore, convertStillsSnapshotToMap } from '../../firebase/firebase.utils';
import StillsActionTypes from './stills.types';
import { fetchStillsSuccess, fetchStillsFailure } from './stills.actions';
import firebase from 'firebase/app';


//------------------------------------------  S T I L L S - A L L
/*  * * * * *  :  A S Y N C   R E Q U E S T   -->
    - was in 'stills.actions',
    - the generator function that does the Async call we want
    - ORIGINAL CODE that was in 'stills.actions' :
      ...
        return dispatch => {
          const stillsRef = firestore.collection('stillsCollection');
          console.log('stillsRef ', stillsRef)
          //  makes 'isFetching: true'
          dispatch(fetchStillsStart());

          * * * * *  :  A S Y N C   R E Q U E S T  -->
              - get our stills data from the backend, comes back,
              - we build our 'stillsMap', 
              - then make a 'fetchStillsSuccess' call.  
          stillsRef
            .get()
            .then(snapshot => {
              const stillsMap = convertStillsSnapshotToMap(snapshot);
              dispatch( fetchStillsSuccess(stillsMap));

              // this.setState({ loading: false });
            })
            .catch(error => dispatch(fetchStillsFailure(error.message)));


          // stillsRef.onSnapshot(async snapshot => {
          //   console.log('snapshot BEFORE conversion : ', snapshot)

          //   const stillsMap = convertStillsSnapshotToMap( snapshot )
          //   console.log('stillsMap = snapshot AFTER conversion : ', stillsMap)
          //   //  Now store this in the 'stills.reducer'
            
          //     E X P L A N A T I O N  -->  Adding it to reducer :
          //       - dispatch :  updateStills action with the converted 
          //                         stillsMap in it.
          //       - updateStills in 'stills.actions' gets this.   
          //   updateStills( stillsMap );

          //   this.setState({ loading: false });
          // })
        }
      ...


    - Generator function that does the asynchronous call of 
      getting the stillsCollection inside of our FirebaseDB.
    */
export function* fetchStillsAsync() {
  // yield console.log('S T E P - 2 : fetchStillsAsync -->  stills.sagas')

  try {
    // console.log('S T E P - 3 : fetchStillsAsync try -->  stills.sagas')

    // allow read, write: if request.auth != null;
    var storageRef = firebase.storage().ref().child('stills');
    // var storageRef = firebase.storage().child("stills").listAll();

    const stillsRef = firestore.collection('stillsCollection');

    /*  E X P L A N A T I O N  -->  
      'const snapshot = yield stillsRef.get();'

      - Instead of the Promise Pattern Way of making Asynchronous calls,
      we are using the Generator function, similar to the Async / Await,
      in a sense that when we set this new 'snapshot' value equal to the 
      yielded value of running stillsRef.get(), when this comes back,
      it comes back in a Promise form that gets resolved with the value
      of our collection reference, which is our 'snapshot'.   

      So instead of doing this below from 'shop.actions.js' :
      ...
      stillsRef
        .get()
        .then( snapshot => {
          const collectionsMap = convertStillsSnapshotToMap( snapshot );
          dispatch(fetchStillsSuccess(collectionsMap));
          // updateCollections( collectionsMap );
          // this.setState({ loading: false });

          // console.log('QuerySnapshot ', snapshot)
          // console.log('collectionsMap ', collectionsMap)
        })
        .catch(error => dispatch(fetchStillsFailure(error.message)));
      ...     */
    const snapshot = yield stillsRef.get();

    // console.log('S T E P - 4 : stillsMap yield call -->  stills.sagas')
    /*  E X P L A N A T I O N  -->  
      const stillsMap = yield call(
        convertStillsSnapshotToMap,
        snapshot
      );
      
      - 'call()' : 4:45 min mark - 178. Redux Thunk Into Saga
      - yielding this call, just incase convertStillsSnapshotToMap() 
      takes longer than expected.     */
    const stillsMap = yield call(
      convertStillsSnapshotToMap,
      snapshot
    );

    yield put(fetchStillsSuccess(stillsMap));

  } catch (error) {
    yield put(fetchStillsFailure(error.message));
  }
}


/*  E X P L A N A T I O N  -->
    - this saga will listen for a specific action type,
      then pause, then run another generator function 
      'fetchStillsAsync()' above.    */
export function* fetchStillsStart() {
  // yield console.log('S T E P - 1 : fetchStillsStart -->  stills.sagas')

  yield takeLatest( 
    StillsActionTypes.FETCH_STILLS_START, 
    fetchStillsAsync
  )
}

export function* stillsSagas() {
  // console.log('S T E P - 0 : stillsSagas() -->  stills.sagas')
  yield all([ 
    call( fetchStillsStart ),
    // call( fetchCottonwoodStart )
  ]);
}



/*    ORIGINAL
  export function* fetchStillsAsync() {
    yield console.log('F I R E D  -->  fetchStillsAsync')

    try {
      //  Firestore stillsCollection :
      const stillsRef = firestore.collection('stillsCollection');
      // console.log('stillsRef ', stillsRef)

        E X P L A N A T I O N  -->  
        'const snapshot = yield stillsRef.get();'

        - Instead of the Promise Pattern Way of making Asynchronous calls,
        we are using the Generator function, similar to the Async / Await,
        in a sense that when we set this new 'snapshot' value equal to the 
        yielded value of running stillsRef.get(), when this comes back,
        it comes back in a Promise form that gets resolved with the value
        of our collection reference, which is our 'snapshot'.   

        So instead of doing this below from 'shop.actions.js' :
        ...
        stillsRef
          .get()
          .then( snapshot => {
            const stillsCollection = convertStillsSnapshotToMap( snapshot );
            dispatch(fetchStillsSuccess(stillsCollection));
            // updateStills( stillsCollection );
            // this.setState({ loading: false });

            // console.log('QuerySnapshot ', snapshot)
            // console.log('stillsCollection ', stillsCollection)
          })
          .catch(error => dispatch(fetchStillsFailure(error.message)));
        ...     
      const snapshot = yield stillsRef.get();
      // console.log('stillsRef snapshot', snapshot)

        E X P L A N A T I O N  -->  
        const stillsCollection = yield call(
          convertStillsSnapshotToMap,
          snapshot
        );

        - yielding this call, just incase convertStillsSnapshotToMap() 
        takes longer than expected.

      const stillsCollection = yield call(
        convertStillsSnapshotToMap,
        snapshot
      );
      yield put(fetchStillsSuccess(stillsCollection));
    } catch (error) {
      // console.log('FAILED  -->  fetchStillsAsync')
      yield put(fetchStillsFailure(error.message));
    }
  }


  export function* fetchStillsStart() {
    yield console.log('STEP 1  -->  fetchStillsStart')

    yield takeEvery( 
      StillsActionTypes.FETCH_STILLS_START, 
      fetchStillsAsync
    )
  }

  export function* stillsSagas() {
    yield all([ call( fetchStillsStart ) ]);
  }
*/


