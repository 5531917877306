import React, { Component } from 'react';
import * as Animated from 'animated/lib/targets/react-dom';
import Easing from 'animated/lib/Easing';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';


// export default class ScaleUp extends Component {
class ScaleUp extends Component {
  constructor(props) {
    super(props)

    this.state = {
      opacity: new Animated.Value( 0 ),
      // transition_up:  new Animated.Value( 0 ),
      delay_onmount: this.props.delayOnMount,
      duration_onmount: this.props.durationOnMount,
      scale_up: new Animated.Value( 0 ),
    };
  };

  componentDidMount() {
    Animated.parallel([
      Animated.timing(this.state.opacity, {
        toValue: 1, 
        duration: this.state.duration_onmount,
        delay: this.state.delay_onmount
      }),
      // Animated.timing(this.state.transition_up, {
      //   toValue: 1, 
      //   duration: 1000,
      //   delay: this.state.delay_onmount
      // }),
      Animated.timing(this.state.scale_up, {
        toValue: 1, 
        duration: this.state.duration_onmount,
        delay: this.state.delay_onmount
      }),
    ]).start();
  };

  render() {
    const { children } = this.props;

    const opacity__interpolate = 
      this.state.opacity.interpolate({
        inputRange: [ 0, 1],
        outputRange: [ "0", "1" ],
      })
    // const transition_up__interpolate = 
    //   this.state.transition_up.interpolate({
    //     inputRange: [ 0, 1 ],
    //     outputRange: [ "0px, 100px", "0px, 0px"],
    //   })
    const scale_up__interpolate = 
      this.state.scale_up.interpolate({
        inputRange: [ 0, 1],
        outputRange: [ 0.9, 1 ],
      })
    const transition_up__style = { 
      opacity: opacity__interpolate,
      transform: [{
        // translate: transition_up__interpolate ,
        scale: scale_up__interpolate
      }],
      /*  E X P L A N A T I O N  -->
          - transition here  makes 'page.component.jsx' component 
            delay of <ScaleUp delay_onmount={ 1000 }> more 
            apparent.  */
      // transition: '0.3s'
    }

    return (
      <Animated.div style={ transition_up__style }>
        {children}
      </Animated.div>
    );
  }
}
ScaleUp.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  // background: PropTypes.string,
};

export default withRouter(ScaleUp);