import { createSelector } from 'reselect';

/*
'selectDirectory' selector is a function that gets the state
and just returns us state.directory

	P U R E   J S  :
		var selectDirectory = function selectDirectory(state) {
			console.log('state ', state)
		  return state.directory;
		};

	R E T U R N S  :  state
		cart: {hidden: true, cartItems: Array(1)}
		directory: {sections: Array(5)}
		shop: {collections: {…}}
		user: {currentUser: {…}}
		_persist: {version: -1, rehydrated: true}
		__proto__: Object

	R E T U R N S  :  state.directory
		sections: (5) [{…}, {…}, {…}, {…}, {…}]
*/

// 	DESTRUCTURED BELOW
var selectDirectory = function selectDirectory(state) {
	console.log('state.directory ', state.directory)
  return state.directory;
};
// const selectDirectory = state => state.directory;


// 	S E L E C T O R  :  'selectDirectorySections'
export const selectDirectorySections = createSelector(
  [selectDirectory],								//	input-selectors
  directory => directory.sections 	//	returns a structured selector
	// (function (directory) {
	//   return directory.sections;
	// });
);








/*------------------------------------------------------ N O T E S
'114. Reselect Library' :

	'reselect' :
	- reselect allows us to write these 'selectors' in such a
		way so that it knows that iff the properties that its 
		pulling from the state and using are the same in the 
		sense that their value hasnt changed and the output of
		the selector is not different, then it wont actually 
		pass them into our component itll just pass the old 
		value and our React coponent will know not to rerender.


	2 TYPES OF SELECTORS :
	1. input selector
		- doesnt use 'createSelector'.
		- its a func that gets the whole state and just 
			returns	a slice of it, one layer DB usually.

	2. output selector 
		- that does use input selectors and 'createSelector'
			to build themselves

	'createSelector' :
	- determines if the value returned by an input-selector has changed 
		between calls using reference equality (===). Inputs to selectors 
		created with createSelector should be immutable.


*/














