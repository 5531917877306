import React from 'react';
import AnimateUp from '../../pages/page-enter-leave/onmount-animate-up.component';
import './bkgd-page-title.styles.scss'

const BkgdPageTitle = ({ title, delay }) => {
	return (
		<div className="bkgdPageTitle">
  		<AnimateUp
  			durationOnMount={ 600 } 
  			delayOnMount={ delay }
			>
				<span>{ title }</span>
				<span>{ title }</span>
				<span>{ title }</span>
  		</AnimateUp>
		</div>
	)

};

export default BkgdPageTitle;











