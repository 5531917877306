import React, { Component } from 'react';
import { ReactComponent as ArrowUp } from '../../assets/back_to_top.svg';
import AnimateUp from '../../pages/page-enter-leave/onmount-animate-up.component';
import AnimateLeft from '../../pages/page-enter-leave/onmount-animate-left.component';

import './back-to-top.styles.scss';


const BackToTop = () => {

  const goToTop = () => {
    // window.scroll({
    //   top: 0, 
    //   left: 0, 
    //   behavior: 'smooth'
    // })

    window.scrollTo(0, 0)
  }


  return (
    <div className="backToTop__wrapper" onClick={ () => goToTop() }>
      <AnimateLeft
        durationOnMount={ 1000 } 
        delayOnMount={ 3000 }
      >
        <AnimateUp
          durationOnMount={ 1000 } 
          delayOnMount={ 3800 }
        >
          <ArrowUp className="ArrowUp" />
        </AnimateUp>
      </AnimateLeft>    
    </div>
  );
}

export default BackToTop;
