import { createSelector } from 'reselect';

const selectUser = state => state.user;

export const selectCurrentUser = createSelector(
	// 	1st arg can be array of our input selectors, 
  [selectUser],

  // 	2nd arg a function, that gets the return of the input selector, 
  // 	so our ( user reducer ) and we will return 'user' to 'currentUser'
  user => user.currentUser
);