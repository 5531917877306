import React from 'react';
import ReactDOM from 'react-dom';
import './hover-card-parallax.styles.scss';
import { useSpring, animated } from 'react-spring';


const calc = (x, y) => [
  - (y - window.innerHeight / 2) / 20, 
  (x - window.innerWidth / 2) / 20, 
  1
]
//  Change the 'perspective' # for tilting effect. Higher the #, less the tilt angle.
const trans = (x, y, s) => `perspective(1000px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`


function Card({ children }) {
  const [props, set] = useSpring(
    () => ({ 
      xys: [0, 0, 1], 
      config: { mass: 5, tension: 350, friction: 40 } 
    })
  )

  return (
    <animated.div
      className="card"
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{ transform: props.xys.interpolate(trans) }}
    >
      {
        // <div className="img"></div>

        children
      }
    </animated.div>
  )
}

export default Card;
