import React, { Component } from 'react';
import * as Animated from 'animated/lib/targets/react-dom';
import Easing from 'animated/lib/Easing';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import './animate.styles.scss';


// export default class AnimateDown extends Component {
class AnimateDown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      opacity: new Animated.Value( 0 ),
      transition_down:  new Animated.Value( 0 ),
      duration_onmount: this.props.durationOnMount,
      delay_onmount: this.props.delayOnMount
    };
  };

  componentDidMount() {
    Animated.parallel([
      Animated.timing(this.state.opacity, {
        toValue: 1, 
        duration: 1500,
        delay: this.state.delay_onmount
      }),
      Animated.timing(this.state.transition_down, {
        toValue: 1, 
        // duration: 1500,
        duration: this.state.duration_onmount,
        delay: this.state.delay_onmount
      })
    ]).start();
  };

  render() {
    const { children } = this.props;

    const opacity__interpolate = 
      this.state.opacity.interpolate({
        inputRange: [ 0, 1],
        outputRange: [ "0", "1" ],
      })
    const transition_down__interpolate = 
      this.state.transition_down.interpolate({
        inputRange: [ 0, 1 ],
        outputRange: [ "0px, -100px", "0px, 0px"],
      })
    const transition_down__style = { 
      opacity: opacity__interpolate,
      transform: [{
        translate: transition_down__interpolate ,
      }],
      /*  E X P L A N A T I O N  -->
          - transition here  makes 'page.component.jsx' component 
            delay of <AnimateDown delay_onmount={ 1000 }> more 
            apparent.  */
      // transition: '0.3s'
    }

    return (
      <Animated.div 
        className="animate_down"
        style={ transition_down__style }
      >
        {children}
      </Animated.div>
    );
  }
}
AnimateDown.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  // background: PropTypes.string,
};

export default withRouter(AnimateDown);