import React, { Component } from 'react';
import * as Animated from 'animated/lib/targets/react-dom';
import Easing from 'animated/lib/Easing';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import './animate.styles.scss';


// export default class AnimateLeft extends Component {
class AnimateLeft extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // opacity: new Animated.Value( 0 ),
      transition_left:  new Animated.Value( 0 ),
      duration_onmount: this.props.durationOnMount,
      delay_onmount: this.props.delayOnMount,
      add_Id: this.props.add_Id
    };
  };

  componentDidMount() {
    Animated.parallel([
      // Animated.timing(this.state.opacity, {
      //   toValue: 1, 
      //   // duration: this.state.duration_onmount,
      //   duration: 1000,
      //   delay: this.state.delay_onmount,
      //   easing: Easing.bezier(0.355, 0.270, 0.000, 1.005),
      // }),
      Animated.timing(this.state.transition_left, {
        toValue: 1, 
        // duration: 1500,
        duration: this.state.duration_onmount,
        delay: this.state.delay_onmount,
        easing: Easing.bezier(0.355, 0.270, 0.000, 1.005),
      })
    ]).start();
  };

  render() {
    const { children } = this.props;

    // const opacity__interpolate = 
    //   this.state.opacity.interpolate({
    //     inputRange: [ 0, 1],
    //     outputRange: [ "0", "1" ],
    //   })
    const transition_left__interpolate = 
      this.state.transition_left.interpolate({
        inputRange: [ 0, 1 ],
        outputRange: [ "150%, 0%", "0%, 0%"],
      })
    const transition_left__style = { 
      // opacity: opacity__interpolate,
      transform: [{
        translate: transition_left__interpolate ,
      }],
      /*  E X P L A N A T I O N  -->
          - transition here  makes 'page.component.jsx' component 
            delay of <AnimateLeft delay_onmount={ 1000 }> more 
            apparent.  */
      // transition: '0.3s'
    }

    return (
      <Animated.div 
        id={ this.state.add_Id }
        className="animate_left"
        style={ transition_left__style }
      >
        {children}
      </Animated.div>
    );
  }
}
AnimateLeft.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  // background: PropTypes.string,
};

export default withRouter(AnimateLeft);