import React from 'react';
import StripeCheckout from 'react-stripe-checkout';

const StripeCheckoutButton = ({ price }) => {
  const priceForStripe = price * 100;       // Stripe wants values in cents
  const publishableKey = 'pk_test_BUZ2ibVsC3AwoHy83rjnFuqp00XNih9QkY';

  const onToken = token => {
    console.log('tokentokentoken ', token);
    alert('Payment Succesful!');
  };

  return (
    //  For more props : https://github.com/azmenak/react-stripe-checkout
    <StripeCheckout
      label='Pay Now'
      name='CRWN Clothing Ltd.'
      billingAddress
      shippingAddress
      image='https://svgshare.com/i/CUz.svg'
      description={`Your total is $${price}`}
      amount={ priceForStripe }
      panelLabel='Pay Now'
      token={ onToken }
      stripeKey={ publishableKey }
    />
  );
};

export default StripeCheckoutButton;




/*------------------------------------------------------ N O T E S

GitHub :
  azmenak/react-stripe-checkout :
  - https://github.com/azmenak/react-stripe-checkout


'Token' :
  - token is the on success callback that triggers when we submit.
    The submission is handled by this component.
  - So, with the token, you would pass this to your backend, which 
    then creates the charge

   EXAMPLE :
   ...
   token={onToken}
   ...







*/