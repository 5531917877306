import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from './redux/store';
// import ScrollToTop from '../components/scroll-to-top/scroll-to-top.component';

// import './index.css';
import App from './app';


ReactDOM.render(
  <Provider store={store}>
		<BrowserRouter>
      <PersistGate persistor={persistor}>
        <App/>
      </PersistGate>
		</BrowserRouter>
  </Provider>,
  document.getElementById('root')
);


/*----------------------------- B E F O R E  :  124. Redux Persist
	import React from 'react';
	import ReactDOM from 'react-dom';
	import { BrowserRouter } from 'react-router-dom';
	import { Provider } from 'react-redux';

	import store from './redux/store';

	import './index.css';
	import App from './App';


	ReactDOM.render(
		<Provider store={ store }>
		  <BrowserRouter>
		    <App />
		  </BrowserRouter>		
		</Provider>,

	  document.getElementById('root')
	);

	// ReactDOM.render(<App />, document.getElementById('root'));
*/





/*------------------------------------------------------ N O T E S

	import { PersistGate } from 'redux-persist/integration/react';
	- need to get it from 'integration/react' bc there are many other
		platforms 'redux-persist' supports.


	import { store, persistor } from './redux/store';
	- 'persistor' being our persisted version of our store.


	<PersistGate persistor={persistor}>
	  <App />
	</PersistGate>
	- what this does is allow the PersistGate to actually receive the 
		store but also fire off actions that will rehydrate the state whenever
		our application refreshes.





*/













