import React, { useState } from 'react';
import { BrowserRouter, Router, Route, Switch, Link, NavLink } from 'react-router-dom';
import { Transition, CSSTransition, TransitionGroup } from 'react-transition-group';

// import ExpenseDashboardPage from '../components/ExpenseDashboardPage';
// import AddExpensePage from '../components/AddExpensePage';
// import EditExpensePage from '../components/EditExpensePage';
// import HelpPage from '../components/HelpPage';
// import NotFoundPage from '../components/NotFoundPage';
// import Header__ORI from '../components/Header';

//  internal stuff
import history from "../history";

//  BODY COMPONENTS
// import Header from '../components/header/header.component';
import AboutPage from '../pages/aboutpage/aboutpage.component';
import WorksPage from '../pages/workspage/workspage.component';
import StillsPage from '../pages/stills/hover-scroll/hover-scroll.component';
import IntroPage from '../pages/intropage/intropage.component';
import ContactPage from '../pages/contactpage/contactpage.component';

//  COMPONENTS
import MenuComponent from '../components/hamburger/menu.component';
import Hamburger from '../components/hamburger/hamburger.component';
// import NewMenuComponent from '../components/hamburger/new-menu.component';

import TestSlider from '../components/draggable-slider/test-slider.component';
import TriangleLoading from '../components/00_SANDBOX/triangle-loading/triangle-loading.component';

//  ANIMATION COMPONENTS
import LoaderFillUp from '../components/page-loading-fillup/loader-fillup.component';
import AnimateLeft from '../pages/page-enter-leave/onmount-animate-left.component';

import ScrollToTop from '../components/scroll-to-top/scroll-to-top.component';


const AppRouter = ({ ...props }) => {
  // 1st Element : current state snapshot
  // 2nd Element : function that allows you to set your state to a new value.
  // const [ 1st Element, 2nd Element ] = useState( 'Default Value' );

  const [ menuOpen, funcToUpdate ] = useState(null);
  // const [ currentPath, currentPathUpdate ] = useState(null);
  // var currentPath = window.location.pathname;

  return (
    <Router history={ history }>
      <div>
        <main>

          <div>
            <AnimateLeft add_Id={ 'HamburgerFixed' } durationOnMount={ 1000 }  delayOnMount={ 3800 }>
              <Hamburger 
                color='#333333'
                hamburgerOpen={ menuOpen } 
                onClick={ () => funcToUpdate( !menuOpen ) } 
              />           
            </AnimateLeft>
            <MenuComponent menuOpen={ menuOpen }>
              <Link className="navBtn"
                to={{
                  pathname: '/',
                  // state: { isLoading: true },
                  state: { 
                    menuOpen: menuOpen,
                    // testingprop: menuOpen
                    // menuIsOpen: menuOpen
                  },
                  // state: { menuOpen: nextPage },
                }}
                onClick={ () => funcToUpdate( 
                  // console.log('HEEELLOOOOO'),
                  !menuOpen,
                ) } 
              ><span href="/"> About </span></Link>


              <Link className="navBtn"
                to={{
                  pathname: '/works',
                  // state: { isLoading: true },
                  state: { menuOpen: menuOpen },
                }}
                onClick={ () => funcToUpdate( !menuOpen ) } 
              ><span href="/works"> Works </span></Link>


              <Link className="navBtn"
                to={{
                  pathname: '/stills',
                  // state: { isLoading: true },
                  state: { menuOpen: menuOpen },
                }}
                onClick={ () => funcToUpdate( !menuOpen ) } 
              ><span href="/stills"> Stills </span></Link>


              <Link className="navBtn"
                to={{
                  pathname: '/contact',
                  // state: { isLoading: true },
                  state: { menuOpen: menuOpen },
                }}
                onClick={ () => funcToUpdate( !menuOpen ) } 
              ><span href="/contact"> Contact </span></Link>
            </MenuComponent>
          </div>

          <Route
            render={({ location }) => {
              const { pathname } = location;
              // console.log('location ', location)
              // console.log('history ', history)
              // console.log('pathname ', pathname)

              return (
                <TransitionGroup>
                  <ScrollToTop/>
                  <CSSTransition
                    key={ pathname }
                    classNames="page"
                    timeout={{ enter: 3000, exit: 3000 }}
                    // timeout={{ enter: 102500, exit: 101500 }}
                  >
                    <Route 
                      location={location}
                      render={() => (
                        <Switch>
                          <LoaderFillUp {...props} 
                            menuOpen = { menuOpen }
                          >
                            <Route exact path='/' component={ AboutPage } />
                            <Route path='/works' component={ WorksPage } />
                            <Route path='/stills' component={ StillsPage } />
                            <Route path='/contact' component={ContactPage} />
                            <Route path='/testslider' component={TestSlider} />
                            <Route path='/triangleloading' component={ TriangleLoading } />
                          </LoaderFillUp>
                        </Switch>
                      )}
                    />
                  </CSSTransition>
                </TransitionGroup>
              );
            }}
          />         
        </main>
      </div>
    </Router>
  )
};

export default AppRouter;








/*  --------------------------------------  BEFORE MAJOR UPDATE
import React, { useState } from 'react';
import { BrowserRouter, Router, Route, Switch, Link, NavLink } from 'react-router-dom';
import { Transition, CSSTransition, TransitionGroup } from 'react-transition-group';

// import ExpenseDashboardPage from '../components/ExpenseDashboardPage';
// import AddExpensePage from '../components/AddExpensePage';
// import EditExpensePage from '../components/EditExpensePage';
// import HelpPage from '../components/HelpPage';
// import NotFoundPage from '../components/NotFoundPage';
// import Header__ORI from '../components/Header';

//  internal stuff
import history from "../history";

//  body components
// import Header from '../components/header/header.component';
import AboutPage from '../pages/aboutpage/aboutpage.component';
import WorksPage from '../pages/workspage/workspage.component';
import StillsPage from '../pages/stills/hover-scroll/hover-scroll.component';
import IntroPage from '../pages/intropage/intropage.component';
import ContactPage from '../pages/contactpage/contactpage.component';

//  fixed components
import Hamburger from '../components/hamburger/hamburger.component';
// import MenuComponent from '../components/hamburger/menu.component';
import NewMenuComponent from '../components/hamburger/new-menu.component';

import TestSlider from '../components/draggable-slider/test-slider.component';
import TriangleLoading from '../components/00_SANDBOX/triangle-loading/triangle-loading.component';

//  animation components
import LoaderFillUp from '../components/page-loading-fillup/loader-fillup.component';
import AnimateLeft from '../pages/page-enter-leave/onmount-animate-left.component';



const AppRouter = ({ ...props }) => {
  const [ menuOpen, funcToUpdate ] = useState(null);

  return (
    <Router history={ history }>
      <div>
        <main>
          {
            // <Header />

            <div>
              <AnimateLeft
                add_Id={ 'HamburgerFixed' }
                durationOnMount={ 1000 } 
                delayOnMount={ 3800 }
              >
                <Hamburger 
                  color='#333333'
                  open={ menuOpen } 
                  onClick={ () => funcToUpdate( !menuOpen ) } 
                />
                <NewMenuComponent open={ menuOpen }>

                  <Link
                    className="navBtn"
                    to={{
                      pathname: '/',
                      state: { isLoading: true },
                    }}
                    onClick={ () => funcToUpdate( !menuOpen ) } 
                  ><span href="/"> About </span>
                  </Link>

                  <Link
                    className="navBtn"
                    to={{
                      pathname: '/works',
                      state: { isLoading: true },
                    }}
                    onClick={ () => funcToUpdate( !menuOpen ) } 
                  ><span href="/works"> Works </span>
                  </Link>

                  <Link
                    className="navBtn"
                    to={{
                      pathname: '/stills',
                      state: { isLoading: true },
                    }}
                    onClick={ () => funcToUpdate( !menuOpen ) } 
                  ><span href="/stills"> Stills </span>
                  </Link>

                  <Link
                    className="navBtn"
                    to={{
                      pathname: '/contact',
                      state: { isLoading: true },
                    }}
                    onClick={ () => funcToUpdate( !menuOpen ) } 
                  ><span href="/contact"> Contact </span>
                  </Link>

                </NewMenuComponent>                
              </AnimateLeft>

            </div>
          }
          <Route
            render={({ location }) => {
              const { pathname } = location;
              // console.log('location ', location)
              // console.log('history ', history)

              return (
                <TransitionGroup>
                  <CSSTransition
                    key={ pathname }
                    classNames="page"
                    timeout={{ enter: 3000, exit: 3000 }}
                    // timeout={{ enter: 102500, exit: 101500 }}
                  >
                    <Route 
                      location={location}
                      render={() => (

                        <Switch>
                          <LoaderFillUp {...props}>
                            <Route exact path='/' component={ AboutPage } />
                            <Route path='/works' component={ WorksPage } />
                            <Route path='/stills' component={ StillsPage } />
                            <Route path='/contact' component={ContactPage} />
                            <Route path='/testslider' component={TestSlider} />
                            <Route path='/triangleloading' component={ TriangleLoading } />
                          </LoaderFillUp>
                        </Switch>
                      )}
                    />
                  </CSSTransition>
                </TransitionGroup>
              );
            }}
          />         
        </main>
      </div>
    </Router>
  )
};

export default AppRouter;

*/