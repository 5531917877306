import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import './about-footer.styles.scss';
import { ReactComponent as Instagram } from '../../../assets/instagram.svg';
import { ReactComponent as Facebook } from '../../../assets/facebook.svg';
import { ReactComponent as Linkedin } from '../../../assets/linkedin_footer.svg';
import AnimateUp from '../../page-enter-leave/onmount-animate-up.component';
import AnimateDown from '../../page-enter-leave/onmount-animate-down.component';

const AboutFooter = () => {

  return (
  	<div className="AboutFooter__Wrapper">

      <div className="contactBar">
        <AnimateUp durationOnMount={ 500 }>
          <h4>CONTACT</h4>
          <span></span>
        </AnimateUp>
      </div>

      <div className="social">
        <AnimateUp durationOnMount={ 500 }>
          <a href="https://www.instagram.com/" target="_blank">
            <Instagram />
          </a>
          <a href="https://www.facebook.com/" target="_blank">
            <Facebook />
          </a>
        </AnimateUp>
      </div>

      <div className="footerNavLinks">
        <AnimateUp durationOnMount={ 500 }>
          <Link
            to={{
              pathname: '/',
              state: { isLoading: true },
            }}
          ><span
            className="link link--kukuri" 
            href="/"
            data-letters="About"
          >About</span>
          </Link>

          <Link
            to={{
              pathname: '/works',
              state: { isLoading: true },
            }}
          ><span
            className="link link--kukuri" 
            href="/works"
            data-letters="Works"
          >Works</span>
          </Link>

          <Link
            to={{
              pathname: '/stills',
              state: { isLoading: true },
            }}
          ><span
            className="link link--kukuri" 
            href="/stills"
            data-letters="Stills"
          >Stills</span>
          </Link>

          <Link
            to={{
              pathname: '/contact',
              state: { isLoading: true },
            }}
          ><span
            className="link link--kukuri" 
            href="/contact"
            data-letters="Contact"
          >Contact</span>
          </Link>   
        </AnimateUp>
      </div>

      <AnimateUp durationOnMount={ 500 }>
        <h2>GET IN TOUCH</h2>
      </AnimateUp>

      <div className="linkedin__container">
        <AnimateDown durationOnMount={ 500 }>
          <div className="linkedin__container__mountExit">
            <div><Linkedin /></div>
            <span></span>
            <div><a href="https://www.linkedin.com/in/young-alex-hong/" target="_blank">
              alex-y-hong/
            </a></div>
          </div>          
        </AnimateDown>
      </div>
  	</div>
  );
}

export default AboutFooter;



