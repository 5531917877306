import styled, { css } from 'styled-components';

const loaderDefault = css`
  // z-index: 9001;
`;
const loaderClosed = css`
  // z-index: 9002;
`;
const loaderOpen = css`
  // z-index: 20000;
`;

const getConditionalStyles = props => {
  // console.log('getConditionalStyles props ', props)
  // console.log('window.location.pathname ', window.location.pathname)

  // var currentPathname = window.location.pathname;

  // if ( currentPathname ) {

  // }


  if ( props.menuOpen === false ) {
    setTimeout(() => {
      return loaderClosed;
    }, 3000)
  } 
  else if ( props.menuOpen === true ) {
    return loaderOpen;
  }
  else return loaderDefault;
};


export const LoaderFillUpWrapper = styled.div`
	width: 100%;
	// height: 100%;
	position: absolute;

  .loader {
    width: 100%;
    height: 200vh;
    background: #141414;
    position: fixed;
    z-index: 10000;

    // E X A M P L E  -  Run animation twice
    // animation: loading 1.5s 2 forwards ease-in-out;

    animation: loading 2s 1 forwards ease-in-out;
    animation-timing-function: cubic-bezier(0.63, 0, 0.42, 1.01);
  }

  @-moz-keyframes loading {
    0% { transform: translate(0, 200vh); }
    15% { transform: translate(0, 100vh); }
    50% { transform: translate(0, 0); }
    75% { transform: translate(0, -100vh); }
    100% { transform: translate(0, -200vh); }
  }
  @-webkit-keyframes loading {
    0% { transform: translate(0, 200vh); }
    15% { transform: translate(0, 100vh); }
    50% { transform: translate(0, 0); }
    75% { transform: translate(0, -100vh); }
    100% { transform: translate(0, -200vh); }
  }
  @-o-keyframes loading {
    0% { transform: translate(0, 200vh); }
    15% { transform: translate(0, 100vh); }
    50% { transform: translate(0, 0); }
    75% { transform: translate(0, -100vh); }
    100% { transform: translate(0, -200vh); }
  }
  @keyframes loading {
    0% { transform: translate(0, 200vh); }
    15% { transform: translate(0, 100vh); }
    50% { transform: translate(0, 0); }
    75% { transform: translate(0, -100vh); }
    100% { transform: translate(0, -200vh); }
  }

  ${ getConditionalStyles }
`;
