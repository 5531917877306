import { takeLatest, put, all, call } from 'redux-saga/effects';
import UserActionTypes from './user.types';

import { 
  signInSuccess,
  signInFailure,
  signOutSuccess,
  signOutFailure,
  signUpSuccess,
  signUpFailure
} from './user.actions';

import { 
  auth, 
  googleProvider, 
  createUserProfileDocument, 
  getCurrentUser 
} from '../../firebase/firebase.utils';

export function* getSnapshotFromUserAuth(userAuth, additionalData) {
  try {
    const userRef = yield call(
      createUserProfileDocument,
      userAuth,
      additionalData
    );
    const userSnapshot = yield userRef.get();
    yield put( 
      signInSuccess({ id: userSnapshot.id, ...userSnapshot.data() })
    );
  } catch (error) {
    yield put(
      signInFailure(error)
    );
  }
}

/*  E X P L A N A T I O N  -->
  ...
  signInWithGoogle()
  ...

  - code inside here is what was inside :
    'sign-in.component.jsx'

  'try{}' :
  - using 'try{} block' here bc any code we write with an API
    has a chance to fail. So we need to be sure we can catch 
    the error.


  //  RE-WRITTEN BELOW
  export function* signInWithGoogle() {
    try {
      const { user } = yield auth.signInWithPopup( googleProvider );
      const userRef = yield call( createUserProfileDocument, user );
      const userSnapshot = yield userRef.get();
      yield put(
        googleSignInSuccess({ id: userSnapshot.id, ...userSnapshot.data() })
      );
    } catch (error) {
      yield put( googleSignInFailure(error) );
    }
  }

  export function* signInWithEmail({ payload: { email, password } }) {
    try {
      const { user } = yield auth.signInWithEmailAndPassword( email, password );
      const userRef = yield call( createUserProfileDocument, user );
      const userSnapshot = yield userRef.get();
      yield put(
        emailSignInSuccess({ id: userSnapshot.id, ...userSnapshot.data() })
      );
    } catch (error) {
      yield put( emailSignInFailure(error) );
    }
  }
*/
export function* signInWithGoogle() {
  try {
    const { user } = yield auth.signInWithPopup( googleProvider );
    yield getSnapshotFromUserAuth(user);

    /*
      const userRef = yield call( 
        createUserProfileDocument, 
        user 
      );
      const userSnapshot = yield userRef.get();
      yield put(
        signInSuccess({ id: userSnapshot.id, ...userSnapshot.data() })
      );
    */

  } catch (error) {
    yield put( signInFailure(error) );
  }
}

export function* signInWithEmail({ payload: { email, password } }) {
  try {
    const { user } = yield auth.signInWithEmailAndPassword( email, password );
    yield getSnapshotFromUserAuth(user);

    /*
      const userRef = yield call( 
        createUserProfileDocument, 
        user 
      );
      const userSnapshot = yield userRef.get();
      yield put(
        signInSuccess({ id: userSnapshot.id, ...userSnapshot.data() })
      );
    */

  } catch ( error ) {
    yield put( signInFailure( error ) );
  }
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield getCurrentUser();
    if ( !userAuth ) return;
    yield getSnapshotFromUserAuth( userAuth );
  } catch ( error ) {
    yield put(signInFailure( error ));
  }
}

export function* signOut() {
  try {
    yield auth.signOut();
    yield put( signOutSuccess() );
  } catch ( error ) {
    yield put( signOutFailure( error ) );
  }
}

export function* signUp({ payload: { email, password, displayName } }) {
  try {
    const { user } = yield auth.createUserWithEmailAndPassword(email, password);
    yield put(signUpSuccess({ user, additionalData: { displayName } }));
  } catch (error) {
    yield put(signUpFailure(error));
  }
}

export function* signInAfterSignUp({ payload: { user, additionalData } }) {
  yield getSnapshotFromUserAuth(user, additionalData);
}

export function* onGoogleSignInStart() {
  yield takeLatest( UserActionTypes.GOOGLE_SIGN_IN_START, signInWithGoogle );
}

/*  E X P L A N A T I O N  -->
  '181. Email Sign In Into Sagas' - '190. Solution: Sign Up Saga'
  * * * * * -
    Take notes and schematics of the data flow in this tutorial     */
export function* onEmailSignInStart() {
  yield takeLatest( UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail );
}

export function* onCheckUserSession() {
  yield takeLatest( UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated );
}

export function* onSignOutStart() {
  yield takeLatest( UserActionTypes.SIGN_OUT_START, signOut );
}

export function* onSignUpStart() {
  yield takeLatest(UserActionTypes.SIGN_UP_START, signUp);
}

export function* onSignUpSuccess() {
  yield takeLatest(UserActionTypes.SIGN_UP_SUCCESS, signInAfterSignUp);
}

/*  E X P L A N A T I O N  -->
  ...
  userSagas
  ...

  - instantiates all of our other sagas that we need to call.   */
export function* userSagas() {
  yield all([
    call(onGoogleSignInStart),
    call(onEmailSignInStart),
    call(isUserAuthenticated),
    call(onSignOutStart),
    call(onSignUpStart),
    call(onSignUpSuccess)
  ]);
}


/*-------------------------------------------- N O T E S

'userSagas()' :
  - 13 min mark : 180. Google Sign In Into Sagas
  - instantiates all of our other sagas that we need to call.

*/



