import React, { Component } from 'react';
import * as Animated from 'animated/lib/targets/react-dom';
import ProgressBar from './progress-bar.component';
import ImgFadeSliderComponent from '../ImageFadeSlider.component';
import WorksEduContent from './works-edu-content.component';

import ABOUT_DATA from '../about.data.js';
import AnimateLeft from '../../page-enter-leave/onmount-animate-left.component';
import './works-edu.styles.scss';


class WorksEducationPreview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      getSchoolAndCompany: [],
      amountToScroll: 0,
      numberOfImgs: [],
      arrayOfImgs: [],
      slidePosition: 0,
      startingYear: [],
      state_itemSlideAmt: 0
    }
    this.ref_imgFadeSlider = React.createRef();
    
    this.trigger_prevSlide = this.trigger_prevSlide.bind(this);
    this.trigger_nextSlide = this.trigger_nextSlide.bind(this);
    this.trigger_AmountToScroll = this.trigger_AmountToScroll.bind(this);
  };


  trigger_AmountToScroll = ( getSlidePosition ) => {
    let slidePosition = getSlidePosition;
    let state_itemSlideAmt = this.state.state_itemSlideAmt;
    let amountToScroll = slidePosition * state_itemSlideAmt;

    this.setState({
      amountToScroll: amountToScroll,
      slidePosition: slidePosition
    });
  };


  trigger_prevSlide = () => {
    this.ref_imgFadeSlider.current.prevSlide( -1 );

    let slidePosition = this.state.slidePosition - 1;
    let numberOfImgs = this.state.numberOfImgs - 1;
    
    if ( slidePosition === -1 ) {
      this.setState({
        slidePosition: numberOfImgs
      });
      this.trigger_AmountToScroll( numberOfImgs );
    }
    else {
      //  If you want a function to be executed after the state change occurs, pass it in as a callback.
      this.setState({ slidePosition: slidePosition }, function () {
        this.trigger_AmountToScroll( slidePosition );
      });
    }
  };


  trigger_nextSlide = () => {
    this.ref_imgFadeSlider.current.nextSlide( 1 );

    let slidePosition = this.state.slidePosition + 1;
    let numberOfImgs = this.state.numberOfImgs;

    if ( slidePosition >= numberOfImgs ) {
      this.trigger_AmountToScroll(0);
    }
    else {
      //  If you want a function to be executed after the state change occurs, pass it in as a callback.
      // this.setState({ slidePosition: slidePosition }, function () {
        this.trigger_AmountToScroll( slidePosition );
      // });
    }
  };


  componentDidMount = () => {
    //  GET SCHOOL AND COMPANY : Starting Year, image URL
    let getSchoolAndCompany = this.state.arrayOfImgs;    
    let getCompanyName = this.state.companyName;
    let getCompanyIntro = this.state.companyIntro;
    let getStartYr = this.state.startingYear;
    let getSchool = this.state.school;


    ABOUT_DATA.map( ( item ) => {
      const itemRouteName = item.routeName;
      const itemSchools = item.schools;
      const itemCompanies = item.companies;

      //  GET BOTH 'EDUCATION' AND 'WORKS' DATA
      if ( itemRouteName === 'education' || itemRouteName === 'works' ) {
        //  COMPANY
        if ( itemCompanies ) {

          //  Sort company from latest to oldest
          const companyDesc = itemCompanies.sort(function(a, b) {
            const lastCompany = a.startYr;
            const nextCompany = b.startYr;
            return lastCompany > nextCompany ? -1 : 1;
          });
          companyDesc.map( company => {
            getSchoolAndCompany.push( company )
            getStartYr.push( company.startYr )
          })
        }

        //  SCHOOL
        if ( itemSchools ) {
          //  Sort school from latest to oldest
          const schoolDesc = itemSchools.sort(function(a, b) {
            const lastSchool = a.startYr;
            const nextSchool = b.startYr;
            return lastSchool > nextSchool ? -1 : 1;
          });
          schoolDesc.map( school => {
            getSchoolAndCompany.push( school )
            getStartYr.push( school.startYr )
          })
        }
      }
    })


    getSchoolAndCompany.sort(function(a, b) {
      const latestSchoolCompany = a.startYr;
      const nextSchoolCompany = b.startYr;

      return latestSchoolCompany > nextSchoolCompany ? -1 : 1;
    })

    let allImgUrls = [];
    getSchoolAndCompany.map( item => {
      allImgUrls.push(item.imageUrl)
    })

    //  AMOUNT TO SCROLL
    //  itemSlidePercent
    let numberOfImgs = getSchoolAndCompany.length;
    // const itemSlidePercent = 100 / numberOfImgs;

    //  targetElClientHeight
    const targetEl = document.querySelector('.progressBar__wrapper');
    let targetElClientHeight = targetEl.offsetHeight + 30;    // 30px height of progressBar

    //  percentToPixel
    // let percentToPixel = targetElClientHeight * ( itemSlidePercent / 100 );
    // let amountToScrollRoundedUp = Math.ceil( (targetElClientHeight / 8) / 10) * 10;
    let amountToScrollRoundedUp = Math.ceil( (targetElClientHeight / 8) / 10) * 8.9;


    this.setState({
      getSchoolAndCompany: getSchoolAndCompany,
      numberOfImgs: getSchoolAndCompany.length,
      arrayOfImgs: allImgUrls,
      state_itemSlideAmt: amountToScrollRoundedUp,
      startingYear: getStartYr.sort(function(a, b){return b-a})
    });
  };


  render() {
    const targetYearBtns = this.state.startingYear.map( ( value, index ) => {
      return (
        <div 
          className="targetYear"
          key={ index } 
          delay={ `${index * 0.1}s` }
          // open={ menuOpen }
          onClick={ () => { this.trigger_AmountToScroll( index ); }}
        > { value } </div>
      )
    });

    let schoolCompanyImgsArray = this.state.arrayOfImgs;

    return (

      <div className="worksEducationPreview__wrapper">
        {/*  IMG SLIDER  */}
        <div className="imgFadeSlider__wrapper">
          <ImgFadeSliderComponent
            ref={ this.ref_imgFadeSlider }
            arrayOfImgs={ schoolCompanyImgsArray }
            slidePosition={ this.state.slidePosition }
          ></ImgFadeSliderComponent>
        </div>

        <div className="worksEducationPreview">
          {/*  TRACK  */}
          <div className="track"></div>


          {/*  TRACK / PROGRESS BAR  */}
          <div className="trackProgressBar__wrapper">

            {/*  YEAR BTNS  */}
            <div className="pagBtns__wrapper">
              { targetYearBtns }
            </div>

            {/*  PROGRESS BAR  */}
            <ProgressBar
              // ref={ this.ref_ProgressBar }
              numberOfImgs={ this.state.numberOfImgs }
              slidePosition={ this.state.slidePosition }
              amountToScroll={ this.state.amountToScroll }
              state_itemSlideAmt={ this.state.state_itemSlideAmt }
              startingYear={ this.state.startingYear }
            ></ProgressBar>
          </div>


          {/*  IMG SLIDER  
          <div className="imgFadeSlider__wrapper">
            <ImgFadeSliderComponent
              ref={ this.ref_imgFadeSlider }
              arrayOfImgs={ schoolCompanyImgsArray }
              slidePosition={ this.state.slidePosition }
            ></ImgFadeSliderComponent>
          </div>
          */}


          {/*  WORKS / EDU  CONTENT  */}
          <div className="worksEduContent__wrapper">
            <WorksEduContent
              slidePosition={ this.state.slidePosition }
              getSchoolAndCompany={ this.state.getSchoolAndCompany }
            ></WorksEduContent>
          </div>


          {/*  PREV / NEXT BTNS  */}
          <div className="prevNextBtns__wrapper">
            <AnimateLeft
              durationOnMount={ 800 } 
              delayOnMount={ 2500 }
            >
              <div className="trigger_prevSlide"
                onClick={ this.trigger_prevSlide }
              > PREV </div>

              <div className="trigger_nextSlide"
                onClick={ this.trigger_nextSlide }
              > NEXT </div>            
            </AnimateLeft>
          </div>
        </div>        

      </div>

    );
  }
}

export default WorksEducationPreview;




