import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { fetchCollectionsStart } from '../../redux/shop/shop.actions';

import CollectionsOverviewContainer from '../../components/collections-overview/collections-overview.container';
import CollectionPageContainer from '../collection/collection.container';


const ShopPage = ({ fetchCollectionsStart, match }) => {
  /*  E X P L A N A T I O N  -->
    192. useEffect In Our App
    - 5:30 mark : explanation of how 'useEffect' get triggered as well as
      re-render cycles.

    - this shop page will only re-render if 'our props change' or 
    - if we called setState inside using 'useState hook' or
    - if the parent component, which is 'App.js' ends up calling its own 
      re-render, which is when 'currentUser' updates.                       */
  useEffect(() => {
    fetchCollectionsStart();
  }, [ fetchCollectionsStart ]);

  console.log('ShopPage ShopPage ShopPage')

  return (
    <div className='shop-page'>
      <Route
        exact
        path={`${match.path}`}
        component={CollectionsOverviewContainer}
      />
      <Route
        path={`${match.path}/:collectionId`}
        component={CollectionPageContainer}
      />
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  fetchCollectionsStart: () => dispatch(fetchCollectionsStart())
});

export default connect( 
  null, 
  mapDispatchToProps
)( ShopPage );



/*    Before tutorial 192. useEffect In Our App
  class ShopPage extends React.Component {
    componentDidMount() {
      const { fetchCollectionsStart } = this.props;
      fetchCollectionsStart();
    }

    render() {
      const { 
        match, 
        // isCollectionFetching, 
        isCollectionLoaded 
      } = this.props;
      // const { loading } = this.state;

      return (
        <div className='shop-page'>
          <Route
            exact
            path={`${match.path}`}
            component={CollectionsOverviewContainer}
              // render={
              //   (props) => 
              //     <CollectionsOverviewContainer 
              //       isLoading={ isCollectionFetching } 
              //       { ...props }
              //     /> 
              // }
          />
          <Route
            path={`${match.path}/:collectionId`}
            component={CollectionPageContainer}
              // render={
              //   (props) => 
              //     <CollectionPageContainer 
              //       isLoading={ !isCollectionLoaded } 
              //       { ...props }
              //     /> 
              // }
          />
        </div>
      );
    }
  }

  const mapStateToProps = createStructuredSelector({
    isCollectionFetching: selectIsCollectionFetching,
    isCollectionLoaded: selectIsCollectionsLoaded
  })

  const mapDispatchToProps = dispatch => ({
    fetchCollectionsStart: () => dispatch(fetchCollectionsStart())
  });

  export default connect( 
    mapStateToProps, 
    mapDispatchToProps
  )( ShopPage );
*/



//  'shop.component.jsx' before lesson '159. Bringing Shop Data To Our App'
/*
  //  T O   C H E C K  :  match
  const ShopPage = ({ match }) => {
    // console.log( 'match', match );    //  path: /shop

    return (
      <div className='shop-page'>
        <Route 
          exact 
          path={`${match.path}`} 
          component={CollectionsOverview} 
        />
        <Route 
          path={`${match.path}/:collectionId`} 
          component={CollectionPage} 
        />
      </div>
    );
  };

  export default ShopPage;
*/



//  'shop.component.jsx' before lesson '128. Nested Routing in Shop Page'
/*
  import React from 'react';
  import CollectionsOverview from '../../components/collections-overview/collections-overview.component';

  const ShopPage = ({ collections }) => (
    <div className='shop-page'>
      <CollectionsOverview></CollectionsOverview>
    </div>
  );

  export default ShopPage;
*/



//  'shop.component.jsx' before lesson '126. Collection State Into Redux'
/*
  import React from 'react';
  import SHOP_DATA from './shop.data.js';
  import CollectionPreview from '../../components/collection-preview/collection-preview';

  class ShopPage extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        collections: SHOP_DATA
      };
    }

    render() {
      const { collections } = this.state;
      console.log('collections ', collections)

      return (
        <div className='shop-page'>
          {
            collections.map( ({ id, ...otherCollectionProps }) => (
              <CollectionPreview key={id} {...otherCollectionProps} />
            ))
          }
        </div>
      );
    }
  }

  export default ShopPage;
*/




/*------------------------------------------------------ N O T E S
'114. Reselect Library' :

  'reselect' :
  - reselect allows us to write these 'selectors' in such a
    way so that it knows that iff the properties that its 
    pulling from the state and using are the same in the 
    sense that their value hasnt changed and the output of
    the selector is not different, then it wont actually 
    pass them into our component itll just pass the old 
    value and our React coponent will know not to rerender.


  2 TYPES OF SELECTORS :
  1. input selector
    - doesnt use 'createSelector'.
    - its a func that gets the whole state and just 
      returns a slice of it, one layer DB usually.

  2. output selector 
    - that does use input selectors and 'createSelector'
      to build themselves




  'createSelector' :
  - determines if the value returned by an input-selector has changed 
    between calls using reference equality (===). Inputs to selectors 
    created with createSelector should be immutable.

  'createStructuredSelector' :
  - takes an object whose properties are input-selectors and 
    returns a structured selector. 
  - The structured selector returns an object with the same keys 
    as the inputSelectors argument, but with the selectors replaced 
    with their values.


  
  'match' :
  - In App.js, the ShopPage component is nested in a 
  <Route />, and <Route /> automatically passes in the 3 
  objects : match, location, and history.



  'render={}' :
  - 'render={}' is a method that takes a function where the parameters and the 
    function are pretty much just the parameters that the componenet will
    receive which in our case is going to be the :
    
      'match, location, and history props' 

    that our Route passes into our component which is what gives our 
    'collection.component.jsx' page access to the 'match' object that we 
    use inside of our selector which we pull off of own props. :

      EXAMPLE :
      ...
      const mapStateToProps = (state, ownProps) => ({
        collection: selectCollection(ownProps.match.params.collectionId)(state)
      });
      ...



*/














