import React, { Component, useEffect } from 'react';
import './loader-fillup.styles.scss';
import { LoaderFillUpWrapper } from './loader-fillup.styles';
import BackToTop from '../back-to-top/back-to-top.component';

const LoaderFillUp = ({ menuOpen, children, ...props }) => {
  useEffect(() => {
    setTimeout(() => {
      // var xPosition = 0;
      // var yPosition = 0;
      window.scrollTo(0, {duration:0})
    }, 2500)
  }, [])

  return (
    <LoaderFillUpWrapper 
      className="LoaderFillUp__Wrapper"
      menuOpen={ menuOpen }
    >
      <p className="loading-text">
        LOADING
      </p>

      <div className="loader"></div>

      <div className="wrapper">
        {
          children
        }
      </div>

      <BackToTop></BackToTop>
    </LoaderFillUpWrapper>
  );
}

export default LoaderFillUp;


