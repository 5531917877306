import React, { Component } from 'react';
import ContactForm from './contact-form/contact-form.component';
import { ReactComponent as WorldMap } from '../../assets/world.svg';
import BackToTop from '../../components/back-to-top/back-to-top.component';
import GlobalHomeBtn from '../../components/global-home-btn/global-home-btn.component';
import AnimateUp from '../page-enter-leave/onmount-animate-up.component';
import BkgdPageTitle from '../../components/bkgd-page-title/bkgd-page-title.component';
import './contactpage.styles.scss';
import './contactpage-mount-unmount.styles.scss';



class ContactPage extends Component {
	constructor() {
		super();
		this.state = {
			pageTitle: 'Contact'
		}		
	}

	render() {
	  return (
	    <div className="ContactPage__Wrapper">

	  		{/*  CONTACT CONTACT CONTACT  */}
				<BkgdPageTitle
					title={ this.state.pageTitle }
					delay={ 4000 }
				></BkgdPageTitle>


	    	{/*  CONTACT HEADER  */}
	    	<div className="contact__header">
	    		<AnimateUp
	    			durationOnMount={ 600 } 
	    			// delayOnMount={ 2600 }
	    			delayOnMount={ 3000 }
	    		>
	    			<h5>Alex Y. Hong</h5>
	    		</AnimateUp>
	    		<AnimateUp
	    			durationOnMount={ 600 } 
	    			// delayOnMount={ 2800 }
	    			delayOnMount={ 3200 }
	    		>
	    			<h3>Web Developer <br/>From Los Angeles, CA</h3>
	    		</AnimateUp>
	    	</div>


				{/*  CONTACT FORM  */}
		    <div className="ContactImgForm__Wrapper">

		    	{/*  IMG  */}
		    	<div className="contactImg__container">
		    		<AnimateUp
		    			durationOnMount={ 600 } 
		    			// delayOnMount={ 3000 }
		    			delayOnMount={ 3400 }
		    		>
							<img src="../images/contact/contact_1.jpg" alt=""/>
		    		</AnimateUp>
		    	</div>

					{/*  CONTACT FORM  */}
					<div className="contactForm__container">
						<AnimateUp
							durationOnMount={ 600 } 
							// delayOnMount={ 3200 }
							delayOnMount={ 3800 }
						>
							<div className="contactContent__Wrapper">

								<p>Content in progress. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt aliquam augue vel vulputate. Vivamus varius, leo ac sollicitudin hendrerit.</p>

								<h6>Type of development work :</h6>
								<p>Content in progress. Nunc eu ultricies enim, in hendrerit sem. Donec sem risus, condimentum eget lobortis eu, rutrum non mauris. Mauris ac maximus eros. Integer sit amet interdum tortor. Sed eget faucibus erat. Mauris ut ex vel massa sagittis porttitor in vitae nisi. Sed condimentum felis fermentum ornare dapibus.</p>
							</div>

							<ContactForm></ContactForm>
						</AnimateUp>
					</div>
				</div>


				{/*  WORLD MAP / CONTACT PAGE FOOTER  
						<AnimateUp
							durationOnMount={ 600 } 
							delayOnMount={ 3200 }
						>
						</AnimateUp>
				*/}





	    	<div className="WorldMap__Wrapper">
	    		<AnimateUp
	    			durationOnMount={ 600 } 
	    			// delayOnMount={ 2600 }
	    			delayOnMount={ 4000 }
	    		>
		    		<div className="worldMap__container">
		    			<WorldMap></WorldMap>
		    		</div>

		    		<div className="info">
		    			<h4>
		    				Alex Y. Hong<br/>
								Los Angeles, CA<br/>
								<a href="mailto:Alexhwebdev@gmail.com" target="_blank">Alexhwebdev@gmail.com</a><br/>
								<a href="https://www.linkedin.com/in/young-alex-hong" target="_blank">linkedin.com/in/young-alex-hong/</a>
		    			</h4>
		    		</div>

			    	<div className="siteBuiltWith_credits">
			    		<div className="siteBuiltWith">
				    		<h4>Site built with :</h4>
				    		<h5>
				    			HTML, CSS, Javascript,<br/>
				    			ReactJS, Redux, Redux-Saga, React Springs, React Animated, Styled-Components,<br/>
				    			Node, Heroku, Firebase<br/>
				    		</h5>
			    		</div>


				    	<div className="credits">
				    		<h4>Credits :</h4>
				  			<h5>Icons made by 
				  				<a href="https://www.flaticon.com/authors/those-icons" title="Those Icons"> Those Icons</a> from
				  				<a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
				  			</h5>

				  			<h5>
				  				<a href="https://unsplash.com/">Awesome Images from Unsplash</a>
				  			</h5>

								<h5>
									<a href="https://fonts.google.com/">Fonts from Google Fonts</a>
				  			</h5>
				    	</div>
			    	</div>
	    		</AnimateUp>



	    	</div>








				{/*  BACK TO TOP  /  GLOBAL HOME BTN  */}
				<BackToTop />
				<GlobalHomeBtn />
	    </div>
	  )		
	}
}

export default ContactPage;





