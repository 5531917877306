import styled, { css } from 'styled-components';


// -------------------------------- HomeNavBtnsStyle
const default_homeNav = css`
  width: 25%;
`;
const active_homeNav = css`
  width: calc( 0% + 50px );
`;

const default_homeNav2 = css`
  opacity: 1;
  transform: translate( 0, 0 );
`;
const active_homeNav2 = css`
  opacity: 0;
  transform: translate( -100px, 0 );
`;

const homeNavBtnsStyle = props => {
  // console.log('homeNavBtnsStyle props ', props)
  return props.active ? active_homeNav : default_homeNav;
};
const active_homeNavBtnsStyle = props => {
  // console.log('active_homeNavBtnsStyle props ', props)
  return props.active ? active_homeNav2 : default_homeNav2;
};

export const HomeNavBtnsStyle = styled.div`

  .linksWrapper_container {
    ${ active_homeNavBtnsStyle }
  }

  ${ homeNavBtnsStyle }
`;





// -------------------------------- HomeBtnStyle
const defaultHomeBtn = css`
  opacity: 1;
`;
const activeHomeBtn = css`
  opacity: 1;
`;

const homeButtonStyle = props => {
  // console.log('homeButtonStyle props ', props)
  return props.active ? activeHomeBtn : defaultHomeBtn;
};

export const HomeBtnStyle = styled.a`

  ${ homeButtonStyle }
`;




// ----------------- ShowHideAndScrollBtnStyle : Show / Hide Menu
const showStyles = css`
  // color: green;
`;
const hideStyles = css`
  // color: red;
`;
const showHideStyles = props => {
  return props.active ? showStyles : hideStyles;
};

export const ShowHideAndScrollBtnStyle = styled.button`
  border: 2px solid purple;
  width: auto;
  height: 63px;
  background: none;
  border: none;
  color: darkslategray;
  font-family: 'Montserrat',sans-serif !important;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0 auto;
  outline: none;
  padding: 0;
  position: fixed;
  text-transform: uppercase;
  z-index: 9999;

  bottom: 0;

  &.showHideBtn { right: 175px; }
  &.scrollBtn { right: 50px; }

  &:hover {
    cursor: pointer;
  }

  // iPhone 8 Plus  -  1080 x 1920
  @media screen and ( max-width: 1080px ) {
    display: none;
  }

  ${showHideStyles}
`;










// -------------------------------- ImagesContainerStyle
const defaultImagesContainer = css`
  width: 75%;
  transform: translateX( 320px );

  // &:hover {
  //   border: 5px solid red;
  //   overflow-x: scroll;
  // }
`;
const horiScrollabe = css`
  width: calc( 100% - 43px );
  transform: translateX( 43px );

`;

const imagesContainerStyle = props => {
  // console.log('imagesContainerStyle props ', props)
  return props.active ? horiScrollabe : defaultImagesContainer;
};

export const ImagesContainerStyle = styled.div`
  height: 100vh;
  align-items: center;
  display: flex;
  overflow-x: scroll;
  position: relative;
  transition: all .5s ease-in-out;
  white-space: nowrap;
  z-index: 1;

  top: 0;
  right: 0;
  bottom: 0;

  // &:hover {
  //   overflow-x: scroll;
  // }


  // iPhone 8 Plus  -  1080 x 1920
  @media screen and ( max-width: 1080px ) {
    // width: calc(100% - 4px);
    width: calc(100% - 75px);
    height: 80vh;
    margin: auto;
    max-width: 1000px;
    overflow-y: scroll !important;
    transform: translateX( 0px );
  }
  @media screen and ( max-width: 800px ) {
    width: calc(100% - 45px);
  }




  ${ imagesContainerStyle }
`;










// -------------------------------- MobileFilterBtnStyle
const default_filterStyle = css`height: 0px;`;
const active_filterStyle = css`height: 200px;`;
const mobileFilterBtnStyle = props => {
  // console.log('mobileFilterBtnStyle props ', props)
  return props.active ? active_filterStyle : default_filterStyle;
};
export const MobileFilterBtnStyle = styled.div`
  ${ mobileFilterBtnStyle }
`;











