import { takeEvery, takeLatest, call, put, all } from 'redux-saga/effects';
import { 
	auth,
	firestore, 
	convertContactSnapshotToMap 
} from '../../firebase/firebase.utils';
import ContactActionTypes from './contact.types';
import { createProjectSuccess, createProjectFailure } from './contact.actions';

//  CONTACT DATA :
import { addContactFormInput } from '../../firebase/firebase.utils';



//------------------------------------------  S T I L L S - A L L
/*  * * * * *  :  A S Y N C   R E Q U E S T   -->
    - was in 'stills.actions',
    - the generator function that does the Async call we want
    - ORIGINAL CODE that was in 'stills.actions' :
      ...
        return dispatch => {
          const contactRef = firestore.collection('stillsCollection');
          console.log('contactRef ', contactRef)
          //  makes 'isFetching: true'
          dispatch(fetchStillsStart());

          * * * * *  :  A S Y N C   R E Q U E S T  -->
              - get our stills data from the backend, comes back,
              - we build our 'contactMap', 
              - then make a 'createProjectSuccess' call.  
          contactRef
            .get()
            .then(snapshot => {
              const contactMap = convertContactSnapshotToMap(snapshot);
              dispatch( createProjectSuccess(contactMap));

              // this.setState({ loading: false });
            })
            .catch(error => dispatch(createProjectFailure(error.message)));


          // contactRef.onSnapshot(async snapshot => {
          //   console.log('snapshot BEFORE conversion : ', snapshot)

          //   const contactMap = convertContactSnapshotToMap( snapshot )
          //   console.log('contactMap = snapshot AFTER conversion : ', contactMap)
          //   //  Now store this in the 'stills.reducer'
            
          //     E X P L A N A T I O N  -->  Adding it to reducer :
          //       - dispatch :  updateStills action with the converted 
          //                         contactMap in it.
          //       - updateStills in 'stills.actions' gets this.   
          //   updateStills( contactMap );

          //   this.setState({ loading: false });
          // })
        }
      ...


    - Generator function that does the asynchronous call of 
      getting the stillsCollection inside of our FirebaseDB.
    */
export function* createProjectStartAsyncORI() {
  // yield console.log('S T E P - 2 : createProjectStartAsync -->  contact.sagas')

  try {
    // console.log('S T E P - 3 : createProjectStartAsync try -->  contact.sagas --> firestore ')

    const contactRef = firestore.collection('contactCollection');

    /*  E X P L A N A T I O N  -->  
      'const snapshot = yield contactRef.get();'

      - Instead of the Promise Pattern Way of making Asynchronous calls,
      we are using the Generator function, similar to the Async / Await,
      in a sense that when we set this new 'snapshot' value equal to the 
      yielded value of running contactRef.get(), when this comes back,
      it comes back in a Promise form that gets resolved with the value
      of our collection reference, which is our 'snapshot'.   

      So instead of doing this below from 'contact.actions.js' :
      ...
      contactRef
        .get()
        .then( snapshot => {
          const collectionsMap = convertContactSnapshotToMap( snapshot );
          dispatch(createProjectSuccess(collectionsMap));
          // updateCollections( collectionsMap );
          // this.setState({ loading: false });

          // console.log('QuerySnapshot ', snapshot)
          // console.log('collectionsMap ', collectionsMap)
        })
        .catch(error => dispatch(createProjectFailure(error.message)));
      ...     */
    const snapshot = yield contactRef.get();

    // console.log('S T E P - 4 : contactMap yield call -->  contact.sagas')
    /*  E X P L A N A T I O N  -->  
      const contactMap = yield call(
        convertContactSnapshotToMap,
        snapshot
      );
      
      - 'call()' : 4:45 min mark - 178. Redux Thunk Into Saga
      - yielding this call, just incase convertContactSnapshotToMap() 
      takes longer than expected.     */
    // const contactMap = yield call(
    //   convertContactSnapshotToMap,
    //   snapshot
    // );

    // E X P L A N A T I O N  -->  
      // - 'put()' : 7:25 min mark - 178. Redux Thunk Into Saga
    // yield put(createProjectSuccess(contactMap));

  } catch (error) {
    // yield put(createProjectFailure(error.message));
  }
}


export function* createProjectStartAsync({ payload: { name, email, message } }) {
	// yield console.log('S T E P - 2 : createProjectStartAsync -->  contact.sagas')
	// yield console.log('payload: { name, email, message } ', name, email, message)

  try {
    // const { user } = yield auth.createUserWithEmailAndPassword(email, name);
    const contactRef = firestore.collection('contact');
    const snapshot = yield contactRef.get();
		// console.log( 'payload ', name, email, message );

    addContactFormInput(
      'contactCollection', 
      [{name, email, message}]
    )

    // const contactMap = yield call(
    //   convertContactSnapshotToMap,
    //   snapshot
    // );

    yield put(createProjectSuccess({ name, email, message }));
    // yield put(createProjectSuccess(  ));
  } catch (error) {
    yield put(createProjectFailure(error));
  }
}


/*  E X P L A N A T I O N  -->
    - this saga will listen for a specific action type,
      then pause, then run another generator function 
      'createProjectStartAsync()' above.    */
export function* createProjectStart( userCredentials ) {
	// yield console.log('S T E P - 1 : createProjectStart -->  contact.sagas')
	// yield console.log('userCredentials ', userCredentials)
  yield takeLatest( ContactActionTypes.CREATE_PROJECT_START, createProjectStartAsync)
}

export function* contactSagas() {
  // console.log('S T E P - 0 : contactSagas() -->  contact.sagas')
  yield all([ 
    call( createProjectStart ),
  ]);
}




