import { createSelector } from 'reselect';


// 	INITIAL INPUT SELECTOR
// 	- it gets the state and returns state.shop

// 	PURE JS
	// var selectShop = function selectShop(state) {
	//   return state.shop;
	// };
const selectShop = state => state.shop;

// 	S E L E C T O R  :  'selectCollections'
export const selectCollections = createSelector(
	[selectShop],								//	input-selectors
	shop => shop.collections 		//	returns a structured selector
);

// 	S E L E C T O R  :  'selectCollectionsForPreview'
export const selectCollectionsForPreview = createSelector(
  [selectCollections],

  // collections => Object.keys(collections).map(key => collections[key])
  collections =>
    collections ? Object.keys(collections).map(key => collections[key]) : []
);

// 	S E L E C T O R  :  'selectCollection'
	// 	COLLECTION_ID_MAP used with 'W I T H  using 'find()' function' below
	// const COLLECTION_ID_MAP = {
	// 	hats: 1,
	// 	sneakers: 2,
	// 	jackets: 3,
	// 	womens: 4,
	// 	mens: 5
	// }
export const selectCollection = collectionUrlParam =>
	// 	W I T H  using 'find()' function
	  // createSelector(
	  //   [selectCollections],
	  //   collections => 
	  //   	collections.find(
	  //   		collection => collection.id === COLLECTION_ID_MAP[collectionUrlParam]
	  //   	)
	  // );

  // 	W I T H O U T  using 'find()' function
  createSelector(
    [selectCollections],

    // collections => collections[collectionUrlParam]
    collections => (collections ? collections[collectionUrlParam] : null)
  );

export const selectIsCollectionFetching = createSelector(
  [selectShop],
  shop => shop.isFetching
);

export const selectIsCollectionsLoaded = createSelector(
  [selectShop],
  shop => !!shop.collections
);








/*------------------------------------------------------ N O T E S
'114. Reselect Library' :

	'reselect' :
	- reselect allows us to write these 'selectors' in such a
		way so that it knows that iff the properties that its 
		pulling from the state and using are the same in the 
		sense that their value hasnt changed and the output of
		the selector is not different, then it wont actually 
		pass them into our component itll just pass the old 
		value and our React coponent will know not to rerender.


	2 TYPES OF SELECTORS :
	1. input selector
		- doesnt use 'createSelector'.
		- its a func that gets the whole state and just 
			returns	a slice of it, one layer DB usually.

	2. output selector 
		- that does use input selectors and 'createSelector'
			to build themselves




	'createSelector' :
		- determines if the value returned by an input-selector has changed 
			between calls using reference equality (===). Inputs to selectors 
			created with createSelector should be immutable.

	'createStructuredSelector' :
		- takes an object whose properties are input-selectors and 
			returns a structured selector. 
		- The structured selector returns an object with the same keys 
			as the inputSelectors argument, but with the selectors replaced 
			with their values.




*/