import { takeLatest, call, put, all } from 'redux-saga/effects';
import { firestore, convertCollectionsSnapshotToMap } from '../../firebase/firebase.utils';
import { fetchCollectionsSuccess, fetchCollectionsFailure } from './shop.actions';
import ShopActionTypes from './shop.types';

//  Generator function that does the asynchronous call of getting
//  the collections inside of our FirebaseDB.
export function* fetchCollectionsAsync() {
  // yield console.log('F I R E D  -->  fetchCollectionsAsync')

  try {
    //  Firestore collections :
    const collectionRef = firestore.collection('collections');

    /*  E X P L A N A T I O N  -->  
      'const snapshot = yield collectionRef.get();'

      - Instead of the Promise Pattern Way of making Asynchronous calls,
      we are using the Generator function, similar to the Async / Await,
      in a sense that when we set this new 'snapshot' value equal to the 
      yielded value of running collectionRef.get(), when this comes back,
      it comes back in a Promise form that gets resolved with the value
      of our collection reference, which is our 'snapshot'.   

      So instead of doing this below from 'shop.actions.js' :
      ...
      collectionRef
        .get()
        .then( snapshot => {
          const collectionsMap = convertCollectionsSnapshotToMap( snapshot );
          dispatch(fetchCollectionsSuccess(collectionsMap));
          // updateCollections( collectionsMap );
          // this.setState({ loading: false });

          // console.log('QuerySnapshot ', snapshot)
          // console.log('collectionsMap ', collectionsMap)
        })
        .catch(error => dispatch(fetchCollectionsFailure(error.message)));
      ...     */
    const snapshot = yield collectionRef.get();

    /*  E X P L A N A T I O N  -->  
      const collectionsMap = yield call(
        convertCollectionsSnapshotToMap,
        snapshot
      );

      - yielding this call, just incase convertCollectionsSnapshotToMap() 
      takes longer than expected.     */
    const collectionsMap = yield call(
      convertCollectionsSnapshotToMap,
      snapshot
    );
    yield put(fetchCollectionsSuccess(collectionsMap));
  } catch (error) {
    yield put(fetchCollectionsFailure(error.message));
  }
}

export function* fetchCollectionsStart() {
  // yield console.log('F I R E D  -->  fetchCollectionsStart')

  /*  E X P L A N A T I O N  -->
    'yield' : pause whenever a specific action type that we want comes in.
    'takeEvery()' : 1st arg - Action Type, 2nd arg - another generator function that will run in response to the 1st arg Action.
    'takeLatest()' : why we use this here explained in ( 178. Root Saga )     */
  yield takeLatest(
    ShopActionTypes.FETCH_COLLECTIONS_START,
    fetchCollectionsAsync
  );
}

export function* shopSagas() {
  yield all([call(fetchCollectionsStart)]);
}

