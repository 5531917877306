import React, { Component } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import HorizontalScroll from '@oberon-amsterdam/horizontal';
import LinksWrapper from '../Links/links-wrapper.component';
import MasonryGridComponent from './masonry-grid.component';
import LightBox from '../fsLightbox/lightbox.component';
import Page from '../page.component';
import StillsPageDetails from '../page-details/page-details.component';

import BkgdPageTitle from '../../../components/bkgd-page-title/bkgd-page-title.component';
import FixedEmailMeBtn from '../../../components/fixed-emailme-btn/fixed-emailme-btn.component';
import AnimateUp from '../../page-enter-leave/onmount-animate-up.component';
import AnimateRight from '../../page-enter-leave/onmount-animate-right.component';
import AnimateDelay from '../../page-enter-leave/onmount-animate-delay.component';

import { 
  ShowHideAndScrollBtnStyle,
  ImagesContainerStyle,
  HomeNavBtnsStyle,
  HomeBtnStyle,
  MobileFilterBtnStyle
} from './stillspage.styles';
// import { ContactBtnStyles } from '../../pages.styles';
import './hover-scroll.styles.scss';
import './stillspage-mount-unmount.styles.scss';


class HoverScroll extends React.Component {
  constructor() {
    super();
    this.state = {
      pageTitle: 'Stills',
      active: false,
      count: 0,
      clientWidth: 0,
      sourceIndexFromGrid: 0,
      togglerValueFromGrid: false,
    }
    this.hover_horiScroll = this.hover_horiScroll.bind(this);
    this.updateCountState = this.updateCountState.bind(this);
    this.showHideMenu = this.showHideMenu.bind(this);
    this.callBack_fromChild = this.callBack_fromChild.bind(this);
    this.showFilterBtns = this.showFilterBtns.bind(this);
  }

  updateCountState = ( count ) => { 
    this.setState({
      count: count
    })
  }


  backToStart = () => {
    const imgsContainer = document.querySelector(".imgs_container");
    imgsContainer.scroll({
      top: 0,
      left: 0,  // Controls Speed
      behavior: 'smooth'
    })
  }


  hover_horiScroll = () => {
    let incrementing_G_scrollLeftCount = window.GlobalVariable.G_scrollLeftCount;
    let RAF;
    let count;
    let stopScroll = false;

    const imgsContainer = document.querySelector(".imgs_container");
    let imgsCtnrClientWidth = imgsContainer.clientWidth;
    let imgsCtnrScrollWidth = imgsContainer.scrollWidth;           //  scrollwidth : measurement of the width of an element's content, including content not visible on the screen due to overflow.
    let maxScrollAllowed;

    /*  'HIDE / SHOW MENU' CLICKED :
        - If Hide / Show Menu is clicked, get the state clientWidth,
          and re-declare 'imgsCtnrClientWidth', 'maxScrollAllowed' variables.   */
    let fullMenuClientWidth = this.state.clientWidth;
    if ( fullMenuClientWidth !== 0 ) {
      imgsCtnrClientWidth = fullMenuClientWidth;
      maxScrollAllowed = imgsCtnrScrollWidth - imgsCtnrClientWidth;
    } else {
      imgsCtnrClientWidth = imgsCtnrClientWidth;
      maxScrollAllowed = imgsCtnrScrollWidth - imgsCtnrClientWidth;
    }


    if ( incrementing_G_scrollLeftCount !== 0 ) {
      // count = incrementing_G_scrollLeftCount / 4.5;
      count = incrementing_G_scrollLeftCount;
    }


    //  1st hover -----> G_scrollLeftCount = 0
    else {
      count = this.state.count;
    }


    function animateRepeat() {
      function animateScroll() {
        if ( incrementing_G_scrollLeftCount <= maxScrollAllowed / 10 ) {
          if ( stopScroll === false ) {

            ++incrementing_G_scrollLeftCount;
            ++count;

            imgsContainer.scroll({
              top: 0,
              left: count * 10,  // Controls Speed
              // left: count,
              // behavior: 'smooth'
            })

            RAF = requestAnimationFrame( animateRepeat );
          }
        } 
        else if ( incrementing_G_scrollLeftCount >= maxScrollAllowed / 10 ) {
          cancelAnimationFrame( RAF );
        }
      }
      animateScroll();
    }

    RAF = requestAnimationFrame( animateRepeat );

    const callUpdateCountState = ( count ) => {
      this.updateCountState( count )
    }

    document.querySelector(".scrollBtn").addEventListener(
      'mouseenter', function( e ){
        RAF = requestAnimationFrame( animateRepeat );
      }
    )
    document.querySelector(".scrollBtn").addEventListener(
      'mouseleave', function( e ){
        stopScroll = true;
        count = count;
        callUpdateCountState( count );
      }
    )
  }


  componentDidMount() {
    //  BackToTop Btn : No need on this page.
    document.querySelector('.backToTop__wrapper').style.display = "none";

    new HorizontalScroll({ container: document.querySelector('.imgs_container') });
    window.GlobalVariable = { G_scrollLeftCount: this.state.count };
    const imgsContainer = document.querySelector(".imgs_container");

    imgsContainer.onscroll = function (e) {
      var auto_scrollLeftCount = null;

      /*  H O R I Z O N T A L   S C R O L L  :
        - as count increments, 'scrollLeft' gets or sets the number of 
          pixels that an element's content is scrolled from its left edge.
        - 'container.scrollLeft'  detects  'left: count * 5,' :
          ...
          container.scroll({
            left: count * 5,
          })
          ...   */
      auto_scrollLeftCount = (imgsContainer.scrollLeft / 10) + 1;

      /*  IF MANUALLY SCROLLED BACK TO 0 :
          - If manually scrolled back to 0, set it to 1 so code will run. If at 0, 
            hover_horiScroll will not work.   */
      auto_scrollLeftCount === 0 ? (auto_scrollLeftCount = 1) : (auto_scrollLeftCount = auto_scrollLeftCount);

      //  U P D A T E   G_scrollLeftCount
      window.GlobalVariable = { G_scrollLeftCount: auto_scrollLeftCount };

      //  T E X T   C H A N G E
      let imgsCtnrClientWidth = imgsContainer.clientWidth;
      let imgsContainerScrollWidth = imgsContainer.scrollWidth;           //  scrollwidth : measurement of the width of an element's content, including content not visible on the screen due to overflow.
      let imgsContainerMaxScrollAllowed = imgsContainerScrollWidth - imgsCtnrClientWidth;;

      const scrollText = document.querySelector(".scrollBtn");
      (auto_scrollLeftCount * 10) < imgsContainerMaxScrollAllowed ? 
        scrollText.innerHTML = 'SCROLL' : 
        scrollText.innerHTML = 'BACK';
    } 
  }


  showHideMenu = () => {
    const activeState = this.state.active;
    this.setState({ active: !activeState })

    setTimeout( () => {
      const imgsContainer = document.querySelector(".imgs_container");
      let imgsCtnrClientWidth = imgsContainer.clientWidth;  

      // let fullMenuClientWidth = this.state.clientWidth;
      this.setState({ clientWidth: imgsCtnrClientWidth })
    }, 600)
  }


  showFilterBtns = () => {
    console.log('sdfasdfds')
    const activeState = this.state.active;
    this.setState({ active: !activeState })

    // setTimeout( () => {
    //   const imgsContainer = document.querySelector(".imgs_container");
    //   let imgsCtnrClientWidth = imgsContainer.clientWidth;  

    //   // let fullMenuClientWidth = this.state.clientWidth;
    //   this.setState({ clientWidth: imgsCtnrClientWidth })
    // }, 600)
  }


  callBack_fromChild = ( 
    sourceIndex, 
    togglerValue,
  ) => {
    // [... use the dataFromChild here...]
    this.setState({ 
      sourceIndexFromGrid: sourceIndex,
      togglerValueFromGrid: togglerValue,
    });
  }


  render() {
    return (
      <div className="hover_scroll_component">
        <div className="pageInProgress">Page In Progress</div>

        <FixedEmailMeBtn></FixedEmailMeBtn>
        <StillsPageDetails></StillsPageDetails>

        {/*  STILLS STILLS STILLS  */}
        <BkgdPageTitle
          title={ this.state.pageTitle }
          delay={ 4000 }
        ></BkgdPageTitle>



        <AnimateDelay
          durationOnMount={ 600 } 
          delayOnMount={ 2800 }
        >

          <div className="mobileLinksWrapper">
            <h3 onClick={ this.showFilterBtns }>Filter :</h3>
            <MobileFilterBtnStyle
              className="mobileFilterBtnStyle"
              active={ this.state.active }
            >
              <LinksWrapper></LinksWrapper>
            </MobileFilterBtnStyle>            
          </div>



          {/*  LINKS WRAPPER STYLE  */}
          <HomeNavBtnsStyle 
            className="homeNavBtnsStyle"
            active={ this.state.active }
          >
            {/*  HOME BTN  */}
            <div className="homeBtnStyle_container">
              <AnimateRight delayOnMount={ 3000 }>
                <Page>
                  <HomeBtnStyle 
                    href="/"
                    className="homeBtnStyle"
                  > HOME </HomeBtnStyle>
                </Page>
              </AnimateRight>
            </div>


            {/*  LINKS  */}
            <div className="linksWrapper_container">
              <AnimateRight delayOnMount={ 3500 }>
                <Page>
                  <LinksWrapper></LinksWrapper>
                </Page>
              </AnimateRight>
            </div>
          </HomeNavBtnsStyle>


          {/*  MASONRY GRID  */}
          <ImagesContainerStyle 
            className="imgs_container" 
            active={ this.state.active }
          >
            <MasonryGridComponent 
              callBack_fromChild={ this.callBack_fromChild }
            ></MasonryGridComponent>
          </ImagesContainerStyle>


          {/*  LIGHTBOX  */}
          <LightBox
            toggler={ this.state.togglerValueFromGrid }
            sourceIndex={ this.state.sourceIndexFromGrid }
          ></LightBox>



          {/*  SHOW / HIDE  */}
          <ShowHideAndScrollBtnStyle 
            className="showHideBtn"
            active={ this.state.active }
            onClick={ this.showHideMenu }
            type='button'
          >
            <AnimateUp delayOnMount={ 3100 }>
              <Page>
                { this.state.active ? 'Show Menu' : 'Hide Menu' } 
              </Page>
            </AnimateUp>
          </ShowHideAndScrollBtnStyle>


          {/*  SCROLL  */}
          <ShowHideAndScrollBtnStyle 
            className="scrollBtn" 
            onClick={ () => this.backToStart() }
            onMouseEnter={ () => this.hover_horiScroll() }
          > 
            <AnimateUp delayOnMount={ 3200 }>
              <Page>
                SCROLL
              </Page>
            </AnimateUp>
          </ShowHideAndScrollBtnStyle>
        </AnimateDelay>

      </div>
    );
  }
}

export default HoverScroll;





