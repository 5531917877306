import StillsActionTypes from './stills.types';
import { firestore, convertStillsSnapshotToMap } from '../../firebase/firebase.utils';



/*  A C T I O N  -  O B J E C T S  -->  
    - Redux and Sagas DO NOT care about action objects, 
      only action functions. So fetchStillsStart 
      fetchStillsSuccess fetchStillsFailure it wont care about.  */
export const fetchStillsStart = () => ({
  type: StillsActionTypes.FETCH_STILLS_START,
  // payload: filter
});
export const fetchStillsSuccess = stillsMap => ({
  type: StillsActionTypes.FETCH_STILLS_SUCCESS,
  payload: stillsMap
});
export const fetchStillsFailure = errorMessage => ({
  type: StillsActionTypes.FETCH_STILLS_FAILURE,
  payload: errorMessage
});

/*
  export const setVisibilityFilter = (
    filter: VisibilityFilter
  ): VisibilityFilterAction => {
    console.log('filter ', filter)
    return {
      type: 'SET_VISIBILITY_FILTER',
      filter
    };
  };
*/
export const setVisibilityFilterStart = filter => ({
  type: StillsActionTypes.SET_VISIBILITY_FILTER,
  payload: filter  // "SHOW_COTTONWOOD"
});





/*  A C T I O N  -  F U N C T I O N S  -->  
    - 169. What Does Redux Thunk Do? :
    - Redux and Sagas only care about action functions.
    - It then dispatches to 'root-reducer' like so :
    ...
    return dispatch => {
    ...    */
export const fetchStillsStartAsync = () => {
  return dispatch => {
    // const stillsRef = firestore.collection('stillsCollection');
    // console.log('stillsRef ', stillsRef)
    // //  makes 'isFetching: true'
    // dispatch(fetchStillsStart());

    // /*  * * * * *  :  A S Y N C   R E Q U E S T  -->
    //     - get our stills data from the backend, comes back,
    //     - we build our 'stillsMap', 
    //     - then make a 'fetchStillsSuccess' call.     */    
    // stillsRef
    //   .get()
    //   .then(snapshot => {
    //     const stillsMap = convertStillsSnapshotToMap(snapshot);
    //     dispatch( fetchStillsSuccess(stillsMap));

    //     // this.setState({ loading: false });
    //   })
    //   .catch(error => dispatch(fetchStillsFailure(error.message)));


    // stillsRef.onSnapshot(async snapshot => {
    //   console.log('snapshot BEFORE conversion : ', snapshot)

    //   const stillsMap = convertStillsSnapshotToMap( snapshot )
    //   console.log('stillsMap = snapshot AFTER conversion : ', stillsMap)
    //   //  Now store this in the 'stills.reducer'
      
    //     E X P L A N A T I O N  -->  Adding it to reducer :
    //       - dispatch :  updateStills action with the converted 
    //                         stillsMap in it.
    //       - updateStills in 'stills.actions' gets this.   
    //   updateStills( stillsMap );

    //   this.setState({ loading: false });
    // })
  }
}





export const updateStills = stillsMap => ({
  /*  E X P L A N A T I O N  -->
      - what it returns is an object with the type 
        'StillsActionTypes.UPDATE_STILLS,',
        where the payload goes to the 'stillsMap'.
      - next the 'stills.reducer' anticipates this action.  */
  type: StillsActionTypes.UPDATE_STILLS,
  payload: stillsMap
});

