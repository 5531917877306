import React, { Component } from 'react';
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';

import * as Animated from 'animated/lib/targets/react-dom';
import Easing from 'animated/lib/Easing';

// import ABOUT_DATA from './about.data.js';

import HoverCardParallax from '../../../components/hover-card-parallax/hover-card-parallax.component';

import ScaleUp from '../onmount-scaleup.component';
import Projects from '../projects/projects.component';
import WorksInfo from '../works-desc/works-desc.component';
import AnimateUp from '../../page-enter-leave/onmount-animate-up.component';


class Company extends Component {
  constructor(props) {
    super(props)

    this.state = {
      title__animation:  new Animated.Value( 0 ),
      // aboutData: ABOUT_DATA
    }
  }

  componentDidMount() {
    Animated.parallel([
      Animated.timing(this.state.title__animation, {
        toValue: 1, 
        duration: 1100,
        delay: 1200
      }),
    ]).start();
  }


  render() {
    const {
      offset, onClick, companyName, companyIntro, imageUrl, 
      companyDesc, date, location, link, role, projects, worksInfo
    } = this.props;


    return (
      <React.Fragment>

        <ParallaxLayer offset={offset} speed={2} onClick={onClick}>
          <div className="slopeBegin" />
        </ParallaxLayer>

        <ParallaxLayer offset={offset} speed={1} onClick={onClick}>
          <div 
            className="slopeEnd"
          />
        </ParallaxLayer>


        {/*  01  
        <ParallaxLayer className="text number" offset={offset} speed={3}>
          <span>0{offset + 1}</span>
        </ParallaxLayer>
        */}

        <ParallaxLayer className="companyName" offset={offset} speed={ 1 }>
          <AnimateUp 
            durationOnMount={ 600 }
            // delayOnMount={ 2100 }
            delayOnMount={ 3000 }
          >
            <h3>{ companyName }</h3>
          </AnimateUp>
        </ParallaxLayer>


        <ParallaxLayer className="companyIntro" offset={offset} speed={ 2 }>
          <AnimateUp 
            durationOnMount={ 600 }
            // delayOnMount={ 2300 }
            delayOnMount={ 3200 }
          >
            <h3>{ companyIntro }</h3>
          </AnimateUp>
        </ParallaxLayer>


        <ParallaxLayer className="imageUrl" offset={offset} speed={ 3 }>
          <AnimateUp 
            durationOnMount={ 600 }
            // delayOnMount={ 2400 }
            delayOnMount={ 3400 }
          >
            <HoverCardParallax>
              <img src={ imageUrl } alt=""/>
            </HoverCardParallax>
          </AnimateUp>
        </ParallaxLayer>


        {
          // <ParallaxLayer className="companyLink" offset={offset} speed={ 0.9 }>
          //   <a href={ link } alt="">SITE</a>
          // </ParallaxLayer>          
        }


        <ParallaxLayer className="companyDesc" offset={offset} speed={ 4 }>
          <div className="para">
            <AnimateUp 
              durationOnMount={ 600 }
              // delayOnMount={ 2500 }
              delayOnMount={ 3600 }
            >
              <p>{ companyDesc }</p>
            </AnimateUp>
          </div>
        </ParallaxLayer>


        <ParallaxLayer className="generalInfo" offset={offset} speed={ 5 }>
          <AnimateUp 
            durationOnMount={ 600 }
            // delayOnMount={ 2600 }
            delayOnMount={ 3800 }
          >
            <div className="topic">
              <h4> FROM / TO </h4>
              <h4> LOCATION </h4>
              <h4> LINK </h4>
              <h4> ROLE </h4>
            </div>
          </AnimateUp>
        </ParallaxLayer>


        <ParallaxLayer className="generalInfo" offset={offset} speed={ 5.5 }>
          <AnimateUp 
            durationOnMount={ 600 }
            // delayOnMount={ 2600 }
            delayOnMount={ 4000 }
          >
            <div className="topicInfo">
              <h4> { date } </h4>
              <h4> { location } </h4>
              <a href={ link } target="_blank"> { companyName } </a>
              <h4> { role } </h4>
            </div>
          </AnimateUp>
        </ParallaxLayer>


        <ParallaxLayer className="projects__wrapper" offset={offset} speed={ 5 }>
          <ScaleUp 
            durationOnMount={ 600 }
            // delayOnMount={ 2700 }
            delayOnMount={ 4200 }
          >
            <h4>Example Projects</h4>
            <div className="projects__container">
              {
                projects && projects.map( ({ id, ...allProps }) => (
                  <Projects key={ id } { ...allProps } />
                )) 
                || 
                worksInfo && worksInfo.map( ({ id, ...allProps }) => (
                  <WorksInfo key={ id } { ...allProps } />
                ))
              }
            </div>
          </ScaleUp>
        </ParallaxLayer>

      </React.Fragment>
    )
  }
}

export default Company;




