import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import FsLightbox from "fslightbox-react";
import { visibleStillsDocuments } from '../../../redux/stills/stills.selectors';


const LightBoxComponent = ({ 
  visibleStillsDocuments,
  sourceIndex,
  sources,
  toggler
}) => {

  //  ---------------------------------------  PUSH IMG URLs INTO ARRAY
  var arrayOfImgPaths = [];
  for (let [key, value] of Object.entries( visibleStillsDocuments )) {
    var imgPath = value.css;
    var partOfImgPath = imgPath.substr(4, imgPath.indexOf(')')).slice(0, -1);
    arrayOfImgPaths.push( partOfImgPath );
  }

  return (
    <div>
      <FsLightbox
        sourceIndex={ sourceIndex }
        toggler={ toggler }
        type={ 'image' }
        key={ arrayOfImgPaths }
        sources={ arrayOfImgPaths }
      />
    </div>

  );
}

const mapStateToProps = createStructuredSelector({
  visibleStillsDocuments: visibleStillsDocuments,
});

export default connect(
  mapStateToProps,
  null
)( LightBoxComponent );




