import ShopActionTypes from './shop.types';


export const fetchCollectionsStart = () => ({
  type: ShopActionTypes.FETCH_COLLECTIONS_START
});

export const fetchCollectionsSuccess = collectionsMap => ({
  type: ShopActionTypes.FETCH_COLLECTIONS_SUCCESS,
  payload: collectionsMap
});

export const fetchCollectionsFailure = errorMessage => ({
  type: ShopActionTypes.FETCH_COLLECTIONS_FAILURE,
  payload: errorMessage
});


//  * * * * * -
/*  
  - Function thats passed into our components to begin the process of handling 
    multiple actions.
  - Has 2 dispath calls.
  - Once the 'collectionRef' resolves, we dispatch another action as in the 
    'Promise Pattern Way'.
*/
export const fetchCollectionsStartAsync = () => {
  return dispatch => {


    /*  Moved to 'shop.sagas.js' in lesson  :  176. Redux Thunk Into Saga 
      // const { updateCollections } = this.props;
      //  Firestore collections :
      const collectionRef = firestore.collection('collections');
      dispatch(fetchCollectionsStart());

      //  O B S E R V E R   P A T T E R N   W a y  :  165. Observables + Observer Pattern 
      //->
      this.unsubscribeFromSnapshot = collectionRef.onSnapshot( snapshot => {
        const collectionsMap = convertCollectionsSnapshotToMap( snapshot );
        updateCollections( collectionsMap );
        this.setState({ loading: false });

        // console.log('QuerySnapshot ', snapshot)
        // console.log('collectionsMap ', collectionsMap)
      });
      <-//


      //  P R O M I S E   P A T T E R N   W a y  :  166. Promise Pattern
      collectionRef
        .get()
        .then( snapshot => {
          const collectionsMap = convertCollectionsSnapshotToMap( snapshot );
          dispatch(fetchCollectionsSuccess(collectionsMap));
          // updateCollections( collectionsMap );
          // this.setState({ loading: false });

          // console.log('QuerySnapshot ', snapshot)
          // console.log('collectionsMap ', collectionsMap)
        })
        .catch(error => dispatch(fetchCollectionsFailure(error.message)));


      //  F E T C H   P A T T E R N   W a y :
      //->
      fetch('https://firestore.googleapis.com/v1/projects/future-mystery-248009/databases/(default)/documents/collections')
        .then( response => response.json() )
        .then( collections => console.log( 'FETCH PATTERN ', collections ) );
      <-//

    */

  };
}; 
