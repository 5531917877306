import React, { Component } from 'react';

import DraggableSlider from './draggable-slider.component';


class TestSlider extends React.Component {
  // constructor() {
  //   super();
  //   this.state = {

  //   }
  // }


  render() {
    return (
      <div id="roottwo">
        <DraggableSlider></DraggableSlider>
      </div>
    );
  }
}

export default TestSlider;





